import each from 'lodash-es/each';

export class VisitReasonCategory {
  constructor() {}

  static readonly fieldMapping = {
    categorizableId: 'categorizable_id',
    categorizableType: 'categorizable_type',
    weight: 'weight',
  };
  categorizableId: number;
  categorizableType: string;
  textToMatch: string;
  weight: number;

  static fromApiV2(response): VisitReasonCategory {
    const visitReasonCategory = new VisitReasonCategory();

    each(VisitReasonCategory.fieldMapping, (value, key) => {
      visitReasonCategory[key] = response[value];
    });

    visitReasonCategory.textToMatch = response['text_to_match'] || response['matched_text'];

    return visitReasonCategory;
  }

  toApiV2() {
    return {
      categorizable_id: this.categorizableId,
      categorizable_type: this.categorizableType,
      matched_text: this.textToMatch,
    };
  }
}
