/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./app-store-redirect.component";
import * as i2 from "../core/window.service";
import * as i3 from "../shared/app-store-redirect.service";
var styles_AppStoreRedirectComponent = [];
var RenderType_AppStoreRedirectComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppStoreRedirectComponent, data: {} });
export { RenderType_AppStoreRedirectComponent as RenderType_AppStoreRedirectComponent };
export function View_AppStoreRedirectComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_AppStoreRedirectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "om-app-store-redirect", [], null, null, null, View_AppStoreRedirectComponent_0, RenderType_AppStoreRedirectComponent)), i0.ɵdid(1, 114688, null, 0, i1.AppStoreRedirectComponent, [i2.WindowService, i3.AppStoreRedirectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppStoreRedirectComponentNgFactory = i0.ɵccf("om-app-store-redirect", i1.AppStoreRedirectComponent, View_AppStoreRedirectComponent_Host_0, {}, {}, []);
export { AppStoreRedirectComponentNgFactory as AppStoreRedirectComponentNgFactory };
