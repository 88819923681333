/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./svg-notification-settings.component";
var styles_SvgNotificationSettingsComponent = [];
var RenderType_SvgNotificationSettingsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SvgNotificationSettingsComponent, data: {} });
export { RenderType_SvgNotificationSettingsComponent as RenderType_SvgNotificationSettingsComponent };
export function View_SvgNotificationSettingsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(1, 0, null, null, 7, ":svg:svg", [["fill", "none"], ["height", "24"], ["viewBox", "0 0 24 24"], ["width", "24"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(3, 0, null, null, 0, ":svg:path", [["clip-rule", "evenodd"], ["d", "M13.1887 5.25C13.1887 5.871 12.6847 6.375 12.0637 6.375C11.4427 6.375 10.9387 5.871 10.9387 5.25C10.9387 4.629 11.4427 4.125 12.0637 4.125C12.6847 4.125 13.1887 4.629 13.1887 5.25Z"], ["fill-rule", "evenodd"]], [[1, "stroke", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(5, 0, null, null, 0, ":svg:path", [["d", "M13.9387 18C13.9387 19.0358 13.0995 19.875 12.0637 19.875C11.028 19.875 10.1887 19.0358 10.1887 18"]], [[1, "stroke", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(7, 0, null, null, 0, ":svg:path", [["clip-rule", "evenodd"], ["d", "M12.4387 6.375C16.167 6.375 16.0012 8.0625 16.9387 11.625C17.8612 15.1305 18.7515 15 18.4387 15.375C18.126 15.75 17.3137 16.875 12.4387 16.875H11.6887C6.81371 16.875 6.00146 15.75 5.68871 15.375C5.37596 15 6.26621 15.1305 7.18871 11.625C8.12621 8.0625 7.96046 6.375 11.6887 6.375H12.4387Z"], ["fill-rule", "evenodd"]], [[1, "stroke", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n  "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.color; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.color; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.color; _ck(_v, 7, 0, currVal_2); }); }
export function View_SvgNotificationSettingsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "om-svg-notification-settings", [], null, null, null, View_SvgNotificationSettingsComponent_0, RenderType_SvgNotificationSettingsComponent)), i0.ɵdid(1, 49152, null, 0, i1.SvgNotificationSettingsComponent, [], null, null)], null, null); }
var SvgNotificationSettingsComponentNgFactory = i0.ɵccf("om-svg-notification-settings", i1.SvgNotificationSettingsComponent, View_SvgNotificationSettingsComponent_Host_0, { color: "color" }, {}, []);
export { SvgNotificationSettingsComponentNgFactory as SvgNotificationSettingsComponentNgFactory };
