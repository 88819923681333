import { Membership } from 'app/core/membership';
import * as moment from 'moment';

export class MembershipExpirationHelper {
  static readonly soonExpirationInDays = 30;

  constructor(private membership: Membership) {}

  formattedExpiry(): string {
    return moment(this.membership.validUntil).calendar(null, {
      sameDay: function(now) {
        return this.isAfter(now) ? '[expires today]' : '[expired today]';
      },
      nextDay: '[expires tomorrow]',
      nextWeek: '[expires on] LL',
      lastDay: '[has expired]',
      lastWeek: '[has expired]',
      sameElse: function(now) {
        return this.isAfter(now) ? '[expires on] LL' : '[has expired]';
      },
    });
  }

  expiresSoon(): boolean {
    const threshold = moment().add(MembershipExpirationHelper.soonExpirationInDays, 'days');
    return moment.duration(threshold.diff(moment(this.membership.validUntil))).as('days') >= 0;
  }
}
