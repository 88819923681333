import each from 'lodash-es/each';

export class PharmacyResponse {
  display_name: string;
  display_address_street: string;
}

export class Pharmacy {
  static readonly fieldMapping = {
    displayName: 'display_name',
    displayAddressStreet: 'display_address_street',
  };
  displayName: string;
  displayAddressStreet: string;

  static fromApiV2(response: PharmacyResponse): Pharmacy {
    const pharmacy = new Pharmacy();

    each(Pharmacy.fieldMapping, (value, key) => {
      pharmacy[key] = response[value];
    });

    return pharmacy;
  }
}
