/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./svg-health-information-exchange.component";
var styles_SvgHealthInformationExchangeComponent = ["svg[_ngcontent-%COMP%] {\n        position: relative;\n        left: 2px;\n      }"];
var RenderType_SvgHealthInformationExchangeComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_SvgHealthInformationExchangeComponent, data: {} });
export { RenderType_SvgHealthInformationExchangeComponent as RenderType_SvgHealthInformationExchangeComponent };
export function View_SvgHealthInformationExchangeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(1, 0, null, null, 3, ":svg:svg", [["fill", "none"], ["height", "14"], ["viewBox", "0 0 18 14"], ["width", "18"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(3, 0, null, null, 0, ":svg:path", [["d", "M3.99 6L0 10L3.99 14V11H11V9H3.99V6ZM18 4L14.01 0V3H7V5H14.01V8L18 4Z"]], [[1, "fill", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n  "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.color; _ck(_v, 3, 0, currVal_0); }); }
export function View_SvgHealthInformationExchangeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "om-svg-health-information-exchange", [], null, null, null, View_SvgHealthInformationExchangeComponent_0, RenderType_SvgHealthInformationExchangeComponent)), i0.ɵdid(1, 49152, null, 0, i1.SvgHealthInformationExchangeComponent, [], null, null)], null, null); }
var SvgHealthInformationExchangeComponentNgFactory = i0.ɵccf("om-svg-health-information-exchange", i1.SvgHealthInformationExchangeComponent, View_SvgHealthInformationExchangeComponent_Host_0, { color: "color" }, {}, []);
export { SvgHealthInformationExchangeComponentNgFactory as SvgHealthInformationExchangeComponentNgFactory };
