import { catchError, filter, map, share, tap } from 'rxjs/operators';
import debounce from 'lodash-es/debounce';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { User } from '../shared/user';
import noop from 'lodash-es/noop';

@Injectable()
export class UserService {
  private _user$ = new BehaviorSubject<User>(null);
  readonly user$ = this._user$.asObservable().pipe(filter(user => user != null));

  _debouncedGetUser;
  _debouncedCreateUser;
  _debouncedCreatePediatricUserForNonMember: (user: User, discountCode: string) => Observable<User>;
  _debouncedCreatePediatricUserForPatient: (user: User, planId: number) => Observable<User>;

  constructor(private apiService: ApiService, private authService: AuthService) {
    this._debouncedGetUser = debounce(this._getUser.bind(this), 1000, { leading: true });
    this._debouncedCreateUser = debounce(this._createUser.bind(this), 1000, { leading: true });
    this._debouncedCreatePediatricUserForNonMember = debounce(this._createPediatricUserForNonMember.bind(this), 1000, {
      leading: true,
    });
    this._debouncedCreatePediatricUserForPatient = debounce(this._createPediatricUserForPatient.bind(this), 1000, {
      leading: true,
    });
  }

  getUser(force = false) {
    let req;

    if (force) {
      req = this._getUser();
    } else if (this._user$.getValue() == null) {
      req = this._debouncedGetUser();
    }

    return req;
  }

  createUser(user, recaptchaToken, force = false) {
    let req;

    if (force) {
      req = this._createUser(user, recaptchaToken);
    } else {
      req = this._debouncedCreateUser(user, recaptchaToken);
    }

    return req;
  }

  createPediatricUserForNonMember(user: User, planId: number, discountCode: string, force?: false): Observable<User> {
    if (force) {
      return this._createPediatricUserForNonMember(user, discountCode);
    } else {
      return this._debouncedCreatePediatricUserForNonMember(user, discountCode);
    }
  }

  createPediatricUserForPatient(user: User, planId: number, force?: false): Observable<User> {
    if (force) {
      return this._createPediatricUserForPatient(user, planId);
    } else {
      return this._debouncedCreatePediatricUserForPatient(user, planId);
    }
  }

  updateUserProfile(data) {
    return this.apiService.patch('/api/v2/patient/profile', data).pipe(catchError(this.handleUserUpdateError));
  }

  updateUser(params: { [key: string]: string }) {
    const request = this.apiService.patch('/api/v2/user', params).pipe(
      map(response => User.fromApiV2(response)),
      tap((user: User) => this._user$.next(user)),
    );

    request.subscribe({ error: noop });

    return request;
  }

  private _getUser() {
    const req = this.apiService.get('/api/v2/user.json');
    req.pipe(map(user => User.fromApiV2(user))).subscribe(user => {
      this._user$.next(user);
    });

    return req;
  }

  private _createUser(user, recaptchaToken) {
    const postCall = this.apiService.post('/api/v2/public/patients', User.forApiV2(user, recaptchaToken)).pipe(
      catchError(this.handleUserCreationError),
      share(),
    );

    this.setToken(postCall);
    return postCall;
  }

  private handleUserCreationError(response) {
    const message =
      "We've encountered an issue creating your account. Please try again. If this issue persists, please email us at admin@onemedical.com";

    return observableThrowError(message);
  }

  private handlePediatricCreationError(response) {
    const message =
      "We've encountered an issue creating your account. Please try again. If this issue persists, please email us at admin@onemedical.com";

    return observableThrowError({ message: message, error: response.error });
  }

  private handleUserUpdateError(response) {
    const message =
      'We seem to have run into an issue saving your information. Please try again. If this issue persists, please email us at admin@onemedical.com';

    return observableThrowError(message);
  }

  private _createPediatricUserForNonMember(patient: User, discountCode: string): Observable<User> {
    const params = {
      patient: User.forApiV2(patient, null),
      discount_code: discountCode,
    };

    const postCall = this.apiService.post('/api/v2/public/pediatric/patients', params);

    this.setToken(postCall);

    return this._postCreateUserForPatient(postCall);
  }

  private _createPediatricUserForPatient(patient: User, planId: number): Observable<User> {
    const params = {
      patient: User.forApiV2(patient, null),
      b2b_plan_id: planId,
    };

    const postCall = this.apiService.post('/api/v2/patient/pediatric/patients', params);
    return this._postCreateUserForPatient(postCall);
  }

  private _postCreateUserForPatient(observable) {
    return observable.pipe(
      catchError(this.handlePediatricCreationError),
      map((response: object) => User.fromApiV2(response)),
    );
  }

  private setToken(postCall) {
    postCall.subscribe(
      response => {
        this.authService.setToken(response['token']);
      },
      error => {},
    );
  }
}
