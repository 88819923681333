import { CookieStorage } from '../shared/storage';
import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
  storage: CookieStorage;

  constructor() {
    this.storage = new CookieStorage();
  }

  getItem(key) {
    return this.storage.getItem(key);
  }

  setItem(key, value, infinite?: boolean) {
    this.storage.setItem(key, value, infinite);
  }

  setTldItem(key, value) {
    this.storage.setTldItem(key, value);
  }

  removeTldItem(key) {
    this.storage.removeTldItem(key);
  }

  removeItem(key) {
    this.storage.removeItem(key);
  }
}
