<div *ngIf="!closed && membership">
  <div *ngIf="membership.isSelfPaid() && membership.isExpired()">
    <ngb-alert class="text-center" type="info" (close)="closed = true">
      <strong>Your membership has expired.</strong> Please
      <a routerLink="/membership/renew">update your credit card</a> to view your messages and tasks.
    </ngb-alert>
  </div>

  <div *ngIf="membership.isB2b() && (membership.isExpired() || membershipExpiresSoon)">
    <ngb-alert class="text-center" type="info" (close)="closed = true">
      Your employer-sponsored membership <strong>{{ formattedExpiryString }}</strong
      >. <a [href]="b2bLink">Re-activate now.</a>
    </ngb-alert>
  </div>
</div>
