/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./svg-hamburger.component";
var styles_SvgHamburgerComponent = [];
var RenderType_SvgHamburgerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SvgHamburgerComponent, data: {} });
export { RenderType_SvgHamburgerComponent as RenderType_SvgHamburgerComponent };
export function View_SvgHamburgerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, ":svg:svg", [["fill", "none"], ["height", "24"], ["viewBox", "0 0 24 24"], ["width", "24"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(2, 0, null, null, 0, ":svg:line", [["stroke-width", "2"], ["x1", "2"], ["x2", "22"], ["y1", "5"], ["y2", "5"]], [[1, "stroke", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(4, 0, null, null, 0, ":svg:line", [["stroke-width", "2"], ["x1", "2"], ["x2", "22"], ["y1", "12"], ["y2", "12"]], [[1, "stroke", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(6, 0, null, null, 0, ":svg:line", [["stroke-width", "2"], ["x1", "2"], ["x2", "22"], ["y1", "19"], ["y2", "19"]], [[1, "stroke", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stroke; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.stroke; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.stroke; _ck(_v, 6, 0, currVal_2); }); }
export function View_SvgHamburgerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "om-svg-hamburger", [], null, null, null, View_SvgHamburgerComponent_0, RenderType_SvgHamburgerComponent)), i0.ɵdid(1, 114688, null, 0, i1.SvgHamburgerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SvgHamburgerComponentNgFactory = i0.ɵccf("om-svg-hamburger", i1.SvgHamburgerComponent, View_SvgHamburgerComponent_Host_0, { stroke: "stroke" }, {}, []);
export { SvgHamburgerComponentNgFactory as SvgHamburgerComponentNgFactory };
