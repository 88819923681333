import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'om-login',
  providers: [],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
// Component controller
export class Login implements OnInit {
  constructor(private title: Title) {}

  ngOnInit() {
    this.title.setTitle('Login');
  }
}
