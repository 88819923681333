import * as tslib_1 from "tslib";
import { bindCallback } from 'rxjs';
import { UserService } from './user.service';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../environments/environment';
import { MembershipService } from '@app/core/membership.service';
import queryString from 'query-string';
mixpanel.init(environment.mixpanelToken);
mixpanel.set_config({ debug: environment.mixpanelDebug });
var AnalyticsService = /** @class */ (function () {
    function AnalyticsService(userService, membershipService) {
        var _this = this;
        this.userService = userService;
        this.membershipService = membershipService;
        this.mixpanel = mixpanel;
        this.trackCampaigns(document.location);
        this.userService.user$.subscribe(function (user) {
            _this.identifyAndUpdateUser(user);
        });
        this.membershipService.membership$.subscribe(function (membership) {
            _this.updateMembershipProperties(membership);
        });
    }
    /**
     * @deprecated use trackWithDefaultProperties instead
     */
    AnalyticsService.prototype.track = function (eventName, properties) {
        var track = bindCallback(this.mixpanel.track).call(this.mixpanel, eventName, properties);
        track.subscribe();
        return track;
    };
    AnalyticsService.prototype.trackWithDefaultProperties = function (eventName, properties) {
        var track = bindCallback(this.mixpanel.track).call(this.mixpanel, eventName, tslib_1.__assign({}, this.defaultProperties, properties));
        track.subscribe();
        return track;
    };
    AnalyticsService.prototype.setModule = function (module) {
        this.module = module;
    };
    AnalyticsService.prototype.patientTaskViewed = function (task) {
        return this.track('Patient Task Viewed', this.taskCommonProperties(task));
    };
    AnalyticsService.prototype.patientTaskOverviewViewed = function (taskCount) {
        return this.track('Patient Tasks Overview Viewed', {
            'Patient Task Count': taskCount,
            flow: 'Patient Tasks',
            module: this.module,
        });
    };
    AnalyticsService.prototype.patientTaskDeclineTapped = function (task) {
        return this.track('Patient Task Decline Tapped', this.taskCommonProperties(task));
    };
    AnalyticsService.prototype.patientTaskDeclined = function (task) {
        return this.track('Patient Task Declined', this.taskCommonProperties(task));
    };
    AnalyticsService.prototype.patientTaskAlreadyDoneTapped = function (task, property) {
        var taskProperties = this.taskCommonProperties(task);
        if (task.type === 'consult_order') {
            taskProperties['already_done_type'] = property.specialist;
        }
        return this.track('Patient Task Already Done Tapped', taskProperties);
    };
    AnalyticsService.prototype.patientTaskAlreadyDone = function (task, property) {
        var taskProperties = this.taskCommonProperties(task);
        if (task.type === 'consult_order') {
            taskProperties['already_done_type'] = property.specialist;
            this.consultOrderSpecialistEventSelector(task, property);
        }
        if (task.type === 'basic_follow_up_order') {
            this.patientTaskSubmitted(task);
        }
        else {
            return this.track('Patient Task Already Done Confirmed', taskProperties);
        }
    };
    AnalyticsService.prototype.consultOrderSpecialistEventSelector = function (task, property) {
        if (property.specialist === 'Elsewhere') {
            var specialistModule = property.specialistInfo
                ? 'Specialist Information Submitted'
                : 'Specialist Information Unknown Submitted';
            this.track(specialistModule, this.taskCommonProperties(task));
        }
    };
    AnalyticsService.prototype.patientTaskSubmitted = function (task) {
        var taskProperties = this.taskCommonProperties;
        taskProperties['Task Feedback'] = task.content.feedback;
        return this.track('Patient Task Submitted', taskProperties);
    };
    AnalyticsService.prototype.bloodPressureEntryViewed = function () {
        return this.track('Blood Pressure Entry Viewed');
    };
    AnalyticsService.prototype.bloodPressureSubmitted = function () {
        return this.track('Blood Pressure Submitted');
    };
    AnalyticsService.prototype.attachmentCreated = function (attachment) {
        return this.track('Timeline Attachment Created', {
            'Content Type': attachment.file.type,
            'Content Length': attachment.file.size,
        });
    };
    AnalyticsService.prototype.attachmentCreationFailed = function (attachment) {
        return this.track('Timeline Attachment Creation Failed', {
            'Content Type': attachment.file.type,
            'Content Length': attachment.file.size,
        });
    };
    AnalyticsService.prototype.attachmentDeleted = function (attachment) {
        return this.track('Timeline Attachment Deleted', {
            'Content Type': attachment.file.type,
            'Content Length': attachment.file.size,
        });
    };
    AnalyticsService.prototype.attachmentViewed = function (document) {
        return this.track('Timeline Attachment Deleted', {
            'Content Type': document.contentType,
            'Content Length': document.contentLength,
        });
    };
    AnalyticsService.prototype.attachmentDownloaded = function (document) {
        return this.track('Timeline Attachment Deleted', {
            'Content Type': document.contentType,
            'Content Length': document.contentLength,
        });
    };
    AnalyticsService.prototype.messagesPageLoad = function () {
        return this.track('Timeline Post Overview Viewed', {
            Referrer: document.referrer,
        });
    };
    AnalyticsService.prototype.postCreated = function (post) {
        this.mixpanel.people.increment('Timeline Post Created');
        this.mixpanel.people.set({ 'Last Timeline Post Created': new Date(post.timestamp) });
        return this.track('Timeline Post Created', {
            'Post Id': post.id,
            Recipient: post.recipient,
            'Text Length': post.content.length,
            'Attachment Count': post.documents.length,
        });
    };
    AnalyticsService.prototype.postCreationFailed = function (post, error) {
        return this.track('Timeline Post Creation Failed', {
            Recipient: post.recipient,
            'Text Length': post.content.length,
            'Attachment Count': post.documents.length,
            'Error Code': error.status,
            'Error Description': error.message,
            'Error Status Text': error.statusText,
            'Error Type': error.name,
        });
    };
    AnalyticsService.prototype.commentCreated = function (post, comment) {
        this.mixpanel.people.increment('Timeline Comment Created');
        this.mixpanel.people.set({ 'Last Timeline Comment Created': new Date(comment.timestamp) });
        return this.track('Timeline Comment Created', {
            'Post Id': post.id,
            'Comment Id': comment.id,
            'Text Length': comment.content.length,
            'Attachment Count': comment.documents.length,
        });
    };
    AnalyticsService.prototype.commentCreationFailed = function (post, comment, error) {
        return this.track('Timeline Comment Creation Failed', {
            'Post Id': post.id,
            'Text Length': comment.content.length,
            'Attachment Count': comment.documents.length,
            'Error Code': error.status,
            'Error Description': error.message,
            'Error Status Text': error.statusText,
            'Error Type': error.name,
        });
    };
    AnalyticsService.prototype.surveyLoaded = function (surveyId) {
        return this.track('Survey Loaded', {
            'Survey Id': surveyId,
        });
    };
    AnalyticsService.prototype.surveyNextQuestion = function (surveyId, questionId) {
        return this.track('Load Next Question', {
            'Survey Id': surveyId,
            'Question Id': questionId,
        });
    };
    AnalyticsService.prototype.surveySubmitted = function (surveyId) {
        return this.track('Submit Survey', {
            'Survey Id': surveyId,
        });
    };
    AnalyticsService.prototype.appInstallBannerDismissed = function () {
        return this.track('App Install Banner Dismissed', {});
    };
    AnalyticsService.prototype.appInstallBannerAccepted = function () {
        return this.track('App Install Banner Accepted', {});
    };
    AnalyticsService.prototype.appStoreLinkClicked = function (location) {
        return this.track('App Store Link Clicked', {
            Location: location,
        });
    };
    AnalyticsService.prototype.trackHealthRecordLink = function (properties) {
        if (properties === void 0) { properties = {}; }
        return this.trackWithDefaultProperties('Health Record Clicked', tslib_1.__assign({ flow: 'Accessing PHR' }, properties));
    };
    AnalyticsService.prototype.trackBookVisitLink = function (properties) {
        if (properties === void 0) { properties = {}; }
        return this.trackWithDefaultProperties('Book Visit Clicked', tslib_1.__assign({ flow: 'Appointment Booking' }, properties));
    };
    AnalyticsService.prototype.trackDirectSignupStarted = function () {
        return this.track('Direct Sign Up Started', tslib_1.__assign({}, this.defaultProperties, { flow: 'Direct Sign Up', module: this.module, submodule: 'Header Banner' }));
    };
    AnalyticsService.prototype.trackReferralModalOpen = function () {
        this.track('Friends and Family Referral Started', tslib_1.__assign({}, this.defaultProperties, { flow: 'Referral', module: 'MyOne Friends and Family Referral Modal', submodule: 'MyOne Header Banner' }));
    };
    AnalyticsService.prototype.trackReferralBannerShown = function (properties) {
        this.track('Banner Displayed', tslib_1.__assign({}, this.defaultProperties, properties, { flow: 'Referral', module: this.module, submodule: 'MyOne Header Banner' }));
    };
    AnalyticsService.prototype.trackReferralSubmissionError = function () {
        this.track('Submit Error Encountered', tslib_1.__assign({}, this.defaultProperties, { flow: 'Referral', module: 'MyOne Friends and Family Referral Modal' }));
    };
    AnalyticsService.prototype.trackReferralLinkCopied = function () {
        this.track('Friends and Family Referral Link Copied', tslib_1.__assign({}, this.defaultProperties, { flow: 'Referral', module: 'MyOne Friends and Family Referral Modal' }));
    };
    AnalyticsService.prototype.trackInviteMoreFriends = function () {
        this.track('Invite More Friends and Family Clicked', tslib_1.__assign({}, this.defaultProperties, { flow: 'Referral', module: 'MyOne Friends and Family Referral Modal' }));
    };
    AnalyticsService.prototype.trackReferralModalClosed = function () {
        this.track('Friends and Family Referral Modal Closed', tslib_1.__assign({}, this.defaultProperties, { flow: 'Referral', module: 'MyOne Friends and Family Referral Modal' }));
    };
    AnalyticsService.prototype.trackReferralSubmitted = function (email) {
        this.track('Friends and Family Referral Submitted', tslib_1.__assign({}, this.defaultProperties, { flow: 'Referral', module: 'MyOne Friends and Family Referral Modal', emails: [email], numEmails: 1 }));
    };
    AnalyticsService.prototype.pedsRegLinkClicked = function (properties) {
        return this.track('Pediatric Link Clicked', properties);
    };
    AnalyticsService.prototype.trackWithErrors = function (eventName, properties) {
        this.ensureRequiredPropertiesIncluded(properties);
        return this.track(eventName, properties);
    };
    AnalyticsService.prototype.identifyNewUser = function (user) {
        this.mixpanel.alias("" + user.id);
        this.mixpanel.identify("" + user.id);
    };
    AnalyticsService.prototype.ensureRequiredPropertiesIncluded = function (properties) {
        if (!properties.flow) {
            throw new Error('"flow" is a required property');
        }
        if (!properties.module) {
            throw new Error('"module" is a required property');
        }
    };
    AnalyticsService.prototype.identifyAndUpdateUser = function (user) {
        this.defaultProperties = tslib_1.__assign({}, this.defaultProperties, { service_area: user.serviceArea.name });
        this.mixpanel.identify("" + user.id);
        this.mixpanel.people.set({ Id: user.id });
    };
    AnalyticsService.prototype.updateMembershipProperties = function (membership) {
        if (!membership.omMembershipType) {
            throw new Error("Unset membership type. Expected om_membership_type from membership but " + membership.omMembershipType + " was found");
        }
        this.defaultProperties = tslib_1.__assign({}, this.defaultProperties, { membership_status: membership.status, om_membership_type: membership.omMembershipType });
    };
    AnalyticsService.prototype.taskCommonProperties = function (task) {
        return {
            patient_task_id: task.id,
            'Task Id': task.id,
            patient_task_type: task.type,
            'Task Type': task.type,
            'Task State': task.state,
            'Task Scheduled At': task.scheduledAt,
            'Task Provider Id': task.provider.id,
            module: this.module,
            flow: 'Patient Tasks',
        };
    };
    AnalyticsService.prototype.trackCampaigns = function (location) {
        var e_1, _a;
        var keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ');
        var lastValues = {}, firstValues = {};
        var params = queryString.parse(location.search, { decode: true });
        try {
            for (var _b = tslib_1.__values(Object.keys(keywords)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var keyword = _c.value;
                var value = params[keyword];
                if (value) {
                    lastValues[keyword + ' [last touch]'] = value;
                    firstValues[keyword + ' [first touch]'] = value;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.mixpanel.people.set_once(firstValues);
        this.mixpanel.people.set(lastValues);
        this.mixpanel.register(lastValues);
    };
    return AnalyticsService;
}());
export { AnalyticsService };
