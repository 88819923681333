/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./close-x.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./close-x.component";
var styles_CloseXComponent = [i0.styles];
var RenderType_CloseXComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CloseXComponent, data: {} });
export { RenderType_CloseXComponent as RenderType_CloseXComponent };
export function View_CloseXComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, ":svg:svg", [["fill", "none"], ["viewBox", "0 0 32 32"], ["xmlns", "http://www.w3.org/2000/svg"]], [[1, "width", 0], [1, "height", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:circle", [["cx", "16"], ["cy", "16"], ["r", "16"]], [[1, "fill", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:path", [["d", "M23 9L9 23"]], [[1, "stroke", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:path", [["d", "M23 23L9 9"]], [[1, "stroke", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; var currVal_1 = _co.height; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.fill; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.stroke; _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.stroke; _ck(_v, 6, 0, currVal_4); }); }
export function View_CloseXComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "om-close-x", [], null, null, null, View_CloseXComponent_0, RenderType_CloseXComponent)), i1.ɵdid(1, 114688, null, 0, i2.CloseXComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CloseXComponentNgFactory = i1.ɵccf("om-close-x", i2.CloseXComponent, View_CloseXComponent_Host_0, { stroke: "stroke", fill: "fill", width: "width", height: "height" }, {}, []);
export { CloseXComponentNgFactory as CloseXComponentNgFactory };
