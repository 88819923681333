import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { CoreModule } from './core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { MyOneApp } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { RecaptchaModule } from 'ng-recaptcha';
import { RouterModule } from '@angular/router';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedModule } from './shared/shared.module';
import { GraphQLModule } from './graphql.module';
import { AppStoreRedirectComponent } from '@app/app-store-redirect/app-store-redirect.component';

@NgModule({
  declarations: [MyOneApp, AppStoreRedirectComponent],
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: false }),
    HttpClientModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    NgbModule,
    ScrollToModule.forRoot(),
    RecaptchaModule.forRoot(),
    GraphQLModule,
  ],
  providers: [Title],
  bootstrap: [MyOneApp],
})
export class MyOneAppModule {}
