import { Component } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'om-tos',
  templateUrl: './tos-interstitial.component.html',
})
export class TosInterstitialComponent {
  constructor(private authService: AuthService) {}

  continue() {
    this.authService.navigateToAttemptedPath();
  }
}
