<svg fill="none" height="100%" viewBox="0 0 40 40" width="100%" xmlns="http://www.w3.org/2000/svg">
  <circle cx="20" cy="20" r="19.5" fill="#C3D5D2" stroke="#C3D5D2" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17.7015 12.4991C17.7015 13.3269 17.0302 13.9981 16.2024 13.9981C15.3746 13.9981 14.7034 13.3269 14.7034 12.4991C14.7034 11.6713 15.3746 11 16.2024 11C17.0302 11 17.7015 11.6713 17.7015 12.4991Z"
    stroke="#005450"
  />
  <path
    d="M18.2873 31.23V24.2656L19.5 22.3869M14.5951 31.23V24.3608L13 22.3869C13 22.3869 13.6225 16.7998 14.0885 16.2873C14.5544 15.7748 16.199 15.6655 16.199 15.6655L16.357 15.6677C16.357 15.6677 17.9425 15.7748 18.4092 16.2873C18.6155 16.5142 19.2631 17.2232 19.5 18.5"
    stroke="#005450"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M24.4069 10.7239C24.4069 11.6759 23.6349 12.4479 22.6829 12.4479C21.7309 12.4479 20.959 11.6759 20.959 10.7239C20.959 9.77195 21.7309 9 22.6829 9C23.6349 9 24.4069 9.77195 24.4069 10.7239Z"
    stroke="#005450"
  />
  <path
    d="M25.0805 31.3V24.2556L26.666 22.0195C26.666 22.0195 25.7565 15.6698 25.2206 15.0805C24.6839 14.4911 22.8606 14.368 22.8606 14.368L22.6789 14.3654C22.6789 14.3654 20.7876 14.4911 20.2518 15.0805C19.7159 15.6698 19 22.0951 19 22.0951L20.8343 24.3651V31.3"
    stroke="#005450"
  />
</svg>
