import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { TosService } from '../../../core/tos.service';
import { TermsOfService } from '../../../core/terms-of-service';

@Component({
  selector: 'om-legal-agreement',
  templateUrl: './legal-agreement.component.html',
  styleUrls: ['./legal-agreement.component.scss'],
})
export class LegalAgreementComponent implements OnInit {
  @Input() processing: boolean;
  @Input() agree: boolean;
  @Output() agreed = new EventEmitter<TermsOfService>();

  constructor(public tosService: TosService) {}

  ngOnInit() {}

  agreeSelected($event) {
    $event.preventDefault();
    this.agreed.emit();
  }
}
