import * as tslib_1 from "tslib";
import find from 'lodash-es/find';
import isEmpty from 'lodash-es/isEmpty';
import trim from 'lodash-es/trim';
import { environment } from '../../environments/environment';
var Storage = /** @class */ (function () {
    function Storage() {
    }
    return Storage;
}());
var SessionStorage = /** @class */ (function (_super) {
    tslib_1.__extends(SessionStorage, _super);
    function SessionStorage() {
        return _super.call(this) || this;
    }
    SessionStorage.prototype.getItem = function (key) {
        return sessionStorage[key];
    };
    SessionStorage.prototype.setItem = function (key, value) {
        sessionStorage.setItem(key, value);
    };
    SessionStorage.prototype.removeItem = function (key) {
        sessionStorage.removeItem(key);
    };
    return SessionStorage;
}(Storage));
export { SessionStorage };
var CookieStorage = /** @class */ (function (_super) {
    tslib_1.__extends(CookieStorage, _super);
    function CookieStorage() {
        var _this = _super.call(this) || this;
        _this.topLevelDomainKeyVal = isEmpty(environment.topLevelDomain) ? '' : "domain=" + environment.topLevelDomain;
        return _this;
    }
    CookieStorage.prototype.getItem = function (key) {
        var keyVals = document.cookie.split(';');
        var keyVal = find(keyVals, function (val) {
            var _a = tslib_1.__read(val.split('='), 2), currentKey = _a[0], currentVal = _a[1];
            if (trim(currentKey) === key) {
                return true;
            }
        });
        if (keyVal) {
            var _a = tslib_1.__read(keyVal.split('='), 2), currentKey = _a[0], currentVal = _a[1];
            return currentVal;
        }
        return null;
    };
    CookieStorage.prototype.setItem = function (key, value, infinite) {
        var cookie = key + "=" + value + ";path=/";
        if (infinite) {
            cookie = cookie + ";expires=Thu, 18 Dec 3000 12:00:00 UTC";
        }
        document.cookie = cookie;
    };
    CookieStorage.prototype.setTldItem = function (key, value) {
        document.cookie = key + "=" + value + ";path=/;" + this.topLevelDomainKeyVal;
    };
    CookieStorage.prototype.removeItem = function (key) {
        document.cookie = key + "=;path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC";
    };
    CookieStorage.prototype.removeTldItem = function (key) {
        document.cookie = key + "=;path=/;" + this.topLevelDomainKeyVal + ";expires=Thu, 01 Jan 1970 00:00:00 UTC";
    };
    return CookieStorage;
}(Storage));
export { CookieStorage };
