<div class="legal-document">
  <div class="doc-title" [class.signed]="signed">
    <a href="#" (keyup.space)="toggleDoc($event)" (keyup.spacebar)="toggleDoc($event)" (click)="toggleDoc($event)">
      <om-svg image="arrow_right" class="arrow-right" [class.rotated]="!collapsed"></om-svg>
      <span>{{ doc.detailHint }}</span>
    </a>
    <button
      (click)="signDoc($event)"
      [ngClass]="signed || processing ? 'icon' : 'text'"
      [disabled]="signed || !enabled"
    >
      <span *ngIf="!signed && !processing" class="button-hint">{{ doc.buttonHint }}</span>
      <om-logo-spinner class="spinner" *ngIf="processing" size="20"></om-logo-spinner>
      <om-svg *ngIf="signed" image="tos_checkmark" class="checkmark"></om-svg>
    </button>
  </div>
  <div class="doc-content" tabindex="0" [class.collapsed]="collapsed"> <div [innerHTML]="doc.text"></div> </div>
</div>
