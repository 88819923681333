import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from '../../core/config.service';
import { InviteFriendsModalComponent } from '@app/shared/ribbon-banner/referrals/invite-friends-modal.component';
import { LoginService } from '../../core/login.service';
import { Membership } from '@app/core/membership';
import { MembershipService } from '@app/core/membership.service';
import { NavbarAnalyticsService } from '@app/shared/navbar/navbar-analytics.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { User } from '@app/shared/user';
import { UserService } from '../../core/user.service';
import { WindowService } from '../../core/window.service';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'om-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('overlayVisible', [
      state(
        'false',
        style({
          opacity: '0',
          pointerEvents: 'none',
        }),
      ),
      state(
        'true',
        style({
          opacity: '0.5',
        }),
      ),
      transition('false => true', [
        animate(
          '200ms ease-in-out',
          keyframes([style({ zIndex: 1090, opacity: 0, offset: 0 }), style({ zIndex: 1090, opacity: 0.5, offset: 1 })]),
        ),
      ]),
      transition('true => false', [
        animate(
          '200ms ease-in-out',
          keyframes([style({ zIndex: 1090, opacity: 0.5, offset: 0 }), style({ zIndex: 1090, opacity: 0, offset: 1 })]),
        ),
      ]),
    ]),
  ],
})
export class NavbarComponent implements OnInit, OnDestroy {
  static NAVBAR_HEIGHT = 84;
  static ANALYTICS_APP_LINK_LOCATION = 'slide-menu';

  private destroy$ = new Subject();

  slideMenuOpen = false;
  mobileAppLink: string;
  membership: Membership;
  user: User;
  inviteCta = 'Invite Friends';

  trackHealthRecordLink = this.navbarAnalyticsService.trackHealthRecordLink.bind(this.navbarAnalyticsService);
  trackBookVisitLink = this.navbarAnalyticsService.trackBookVisitLink.bind(this.navbarAnalyticsService);
  trackTasksLink = this.navbarAnalyticsService.trackTasksLink.bind(this.navbarAnalyticsService);
  trackHomeLink = this.navbarAnalyticsService.trackHomeLink.bind(this.navbarAnalyticsService);
  trackMessagesLink = this.navbarAnalyticsService.trackMessagesLink.bind(this.navbarAnalyticsService);

  constructor(
    private loginService: LoginService,
    private modal: NgbModal,
    private router: Router,
    private windowService: WindowService,
    public config: ConfigService,
    public membershipService: MembershipService,
    public navbarAnalyticsService: NavbarAnalyticsService,
    public userService: UserService,
  ) {}

  ngOnInit() {
    this.userService.getUser();
    this.membershipService.getMembership();
    this.mobileAppLink = this.getMobileAppLink();

    this.membershipService.membership$.pipe(takeUntil(this.destroy$)).subscribe((membership: Membership) => {
      this.membership = membership;
    });

    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe((user: User) => {
      this.user = user;
      if (this.user.referralDiscount) {
        this.inviteCta = `Give ${this.user.referralDiscount.getDiscountString()} off`;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout(e: MouseEvent) {
    e.preventDefault();
    this.loginService.logout().subscribe(response => {});
  }

  toggleSlideMenu() {
    this.slideMenuOpen = !this.slideMenuOpen;
  }

  handleAppLinkClick() {
    this.navbarAnalyticsService.appStoreLinkClicked(NavbarComponent.ANALYTICS_APP_LINK_LOCATION);
  }

  openInviteModal() {
    this.modal.open(InviteFriendsModalComponent);
  }

  registerFamily() {
    this.navbarAnalyticsService.trackDirectSignupStarted().subscribe(() => {
      this.router.navigate(['/registration/referrals']);
    });
  }

  private getMobileAppLink(): string {
    if (this.windowService.isIos()) {
      return this.config.json.appStoreLink;
    } else if (this.windowService.isAndroid()) {
      return this.config.json.googlePlayLink;
    }
  }
}
