import find from 'lodash-es/find';
import isEmpty from 'lodash-es/isEmpty';
import trim from 'lodash-es/trim';
import { environment } from '../../environments/environment';

abstract class Storage {
  constructor() {}

  abstract getItem(key: string): string;

  abstract setItem(key: string, value: string, infinite?: boolean): void;

  abstract removeItem(key: string): void;
}

export class SessionStorage extends Storage {
  constructor() {
    super();
  }

  getItem(key) {
    return sessionStorage[key];
  }

  setItem(key, value) {
    sessionStorage.setItem(key, value);
  }

  removeItem(key) {
    sessionStorage.removeItem(key);
  }
}

export class CookieStorage extends Storage {
  private topLevelDomainKeyVal: string;

  constructor() {
    super();
    this.topLevelDomainKeyVal = isEmpty(environment.topLevelDomain) ? '' : `domain=${environment.topLevelDomain}`;
  }

  getItem(key) {
    const keyVals = document.cookie.split(';');
    const keyVal = find(keyVals, val => {
      const [currentKey, currentVal] = val.split('=');
      if (trim(currentKey) === key) {
        return true;
      }
    });
    if (keyVal) {
      const [currentKey, currentVal] = keyVal.split('=');
      return currentVal;
    }
    return null;
  }

  setItem(key, value, infinite?: boolean) {
    let cookie = `${key}=${value};path=/`;

    if (infinite) {
      cookie = `${cookie};expires=Thu, 18 Dec 3000 12:00:00 UTC`;
    }

    document.cookie = cookie;
  }

  setTldItem(key, value) {
    document.cookie = `${key}=${value};path=/;${this.topLevelDomainKeyVal}`;
  }

  removeItem(key) {
    document.cookie = `${key}=;path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC`;
  }

  removeTldItem(key) {
    document.cookie = `${key}=;path=/;${this.topLevelDomainKeyVal};expires=Thu, 01 Jan 1970 00:00:00 UTC`;
  }
}
