import { ApiService } from '../core/api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ContentBlockService {
  constructor(private apiService: ApiService) {}

  getContentBlock(section, id, title, serviceAreaId): Observable<Object> {
    return this.apiService.get(
      `/content/guaranteed?section=${section}&page=${id}&title=${title}&service_area_id=${serviceAreaId}`,
    );
  }
}
