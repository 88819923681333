import * as tslib_1 from "tslib";
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
var MembershipGraphQL = /** @class */ (function (_super) {
    tslib_1.__extends(MembershipGraphQL, _super);
    function MembershipGraphQL() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.document = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n    query MembershipResult {\n      membership {\n        planId\n        planType\n        plan {\n          ... on B2bPlan {\n            name\n            company {\n              name\n              includesDependent\n            }\n          }\n          ... on StripePlan {\n            amount\n            amountInDollars\n          }\n        }\n      }\n    }\n  "], ["\n    query MembershipResult {\n      membership {\n        planId\n        planType\n        plan {\n          ... on B2bPlan {\n            name\n            company {\n              name\n              includesDependent\n            }\n          }\n          ... on StripePlan {\n            amount\n            amountInDollars\n          }\n        }\n      }\n    }\n  "])));
        return _this;
    }
    return MembershipGraphQL;
}(Query));
export { MembershipGraphQL };
var templateObject_1;
