import { filter } from 'rxjs/operators';
import { AppointmentCancellationReason } from '@app/appointment/appointment-cancellation-reason';
import { AppointmentType } from './appointment-type';
import { BehaviorSubject, Observable } from 'rxjs';
import { FromAppointment } from './from-appointment';
import { Provider } from '../shared/provider';
import { ProviderType, ProviderTypeUtil } from './provider-type';
import { SelectedReason } from './selected-reason';
import { ServiceArea } from '../shared/service-area';
import { Task, VisitFollowUpOrderContent } from '../shared/task';
import { SurveyData } from '../survey/survey-data';

interface TrackableBookingState {
  appointment_type?: string;
  is_routed?: boolean;
  provider_category?: string;
  provider_id?: number;
  reason?: object[];
  reason_count?: number;
  reason_for_cancel?: string;
  send_sms?: boolean;
  service_area?: string;
}

// Map appointment types to surveys
export const enum HardcodedAppointmentTypeId {
  IUDInsertion = 176,
  IUDRemoval = 177,
  IUDReplacement = 178,
}
export const enum HardcodedSurveyId {
  IUDInsertion = 17, // insertion survey is for replacement too
  IUDRemoval = 18,
}

export const PROVIDER_TYPE_DEFAULT = ProviderType.anyAvailableProvider;

function appointmentTypeIdToSurveyId(appointmentTypeId: number): HardcodedSurveyId | undefined {
  const appointmentSurveyMap = new Map([
    [HardcodedAppointmentTypeId.IUDInsertion, HardcodedSurveyId.IUDInsertion],
    [HardcodedAppointmentTypeId.IUDRemoval, HardcodedSurveyId.IUDRemoval],
    [HardcodedAppointmentTypeId.IUDReplacement, HardcodedSurveyId.IUDInsertion],
  ]);
  return appointmentSurveyMap.get(appointmentTypeId);
}

export class AppointmentBookingState {
  get associatedTask() {
    return this._associatedTask;
  }
  set associatedTask(task) {
    this._associatedTask = task;
    if (task.content instanceof VisitFollowUpOrderContent) {
      this.setSelectedProvider(task.content.visitWithProvider);
      this.setSelectedProviderType(ProviderType.specificProvider);
      if (task.content.visitReason) {
        this.selectedReasons = [new SelectedReason(task.content.visitReason)];
      }
    }
  }

  get bookingParams() {
    const params = {
      appointment: {
        appointment_cancellation_reason_id: this.getCancellationReasonId(),
        appointment_type_id: this.getAppointmentTypeId(),
        from_appointment_id: this.getFromAppointmentId(),
        patient_survey_id: this.createdPatientSurveyId,
        reason: this.customReasons(),
        send_sms: this.sendSms,
        sms_number: this.sendSms ? this.phoneNumber : null,
        visit_reason_categories: this.getCategorizedReasons(),
      },
    };

    if (this.associatedTask) {
      params['appointment']['booked_from'] = {
        resource: this.associatedTask.type,
        id: this.associatedTask.id,
      };
    }

    return params;
  }

  get trackableProperties(): TrackableBookingState {
    const properties: TrackableBookingState = {};

    if (!!this.selectedReasons) {
      properties.reason = this.selectedReasons.map(sr => sr.toApiV2());
      properties.reason_count = this.selectedReasons.length;
      properties.is_routed = this.getIsAppointmentRecommended();
    }

    if (!!this.appointmentType) {
      properties.appointment_type = this.appointmentType.displayName;
    }

    const selectedServiceArea = this.getSelectedServiceArea();
    if (!!selectedServiceArea) {
      properties.service_area = selectedServiceArea.name;
    }

    if (this.selectedProviderType != null) {
      properties.provider_category = ProviderTypeUtil.getTypeDescription(this.selectedProviderType);
    }

    const provider = this.selectedProvider;
    if (!!provider) {
      properties.provider_id = provider.id;
      properties.provider_category = properties.provider_category || 'Specific Provider: ' + provider.preparedName;
    }

    if (this.sendSms) {
      properties.send_sms = true;
    }

    if (this.getCancellationReasonId()) {
      properties.reason_for_cancel = this.cancellationReason.displayReason;
    }

    return properties;
  }

  constructor() {}
  private _selectedServiceArea$ = new BehaviorSubject<ServiceArea>(null);
  selectedServiceArea$: Observable<ServiceArea> = this._selectedServiceArea$
    .asObservable()
    .pipe(filter(sa => sa != null));

  private _isAppointmentRecommended = new BehaviorSubject<boolean>(false);
  isAppointmentRecommended$ = this._isAppointmentRecommended.asObservable();

  appointmentType: AppointmentType;
  private _associatedTask: Task;
  cancellationReason: AppointmentCancellationReason | undefined;
  createdPatientSurveyId: number;
  fromAppointment: FromAppointment | undefined;
  phoneNumber: string;
  selectedReasons: SelectedReason[] = [];
  selectedProviderType: ProviderType = ProviderType.anyAvailableProvider;
  selectedProvider: Provider;
  sendSms: boolean;
  requiredSurveyId: number;
  surveyData: SurveyData | undefined;

  static fromApiV2(response): AppointmentBookingState {
    const appointmentBookingState = new AppointmentBookingState();
    const bookingStateResponse = response['appointment_booking_state'];

    if (!bookingStateResponse) {
      return appointmentBookingState;
    }

    appointmentBookingState.selectedReasons = bookingStateResponse['selected_reasons'].map(sr =>
      SelectedReason.fromApiV2(sr),
    );

    appointmentBookingState.setSelectedServiceArea(new ServiceArea(bookingStateResponse['service_area']));

    if (bookingStateResponse['provider']) {
      appointmentBookingState.setSelectedProvider(Provider.fromApiV2(bookingStateResponse['provider']));

      if (bookingStateResponse['task']) {
        const task = new Task();
        task.id = bookingStateResponse['task'].id;
        appointmentBookingState.associatedTask = task;
      }
    }

    appointmentBookingState.setSelectedProviderType(bookingStateResponse['provider_type']);
    appointmentBookingState.setSelectedAppointmentType(
      AppointmentType.fromApiV2(bookingStateResponse['appointment_type']),
    );
    appointmentBookingState.setIsAppointmentRecommended(bookingStateResponse['is_appointment_recommended']);

    if (bookingStateResponse['from_appointment']) {
      appointmentBookingState.setFromAppointment(FromAppointment.fromApiV2(bookingStateResponse['from_appointment']));
    }

    if (bookingStateResponse['appointment_cancellation_reason']) {
      appointmentBookingState.setCancellationReason(
        AppointmentCancellationReason.fromApiV2(bookingStateResponse['appointment_cancellation_reason']),
      );
    }

    return appointmentBookingState;
  }

  setIsAppointmentRecommended(isAppointmentRecommended: boolean) {
    this._isAppointmentRecommended.next(isAppointmentRecommended);
  }

  getIsAppointmentRecommended() {
    return this._isAppointmentRecommended.value;
  }

  setSelectedServiceArea(serviceArea: ServiceArea) {
    this._selectedServiceArea$.next(serviceArea);
  }

  getSelectedServiceArea() {
    return this._selectedServiceArea$.value;
  }

  setSelectedProviderType(providerType: ProviderType) {
    const ensureProviderType = Object.values(ProviderType).includes(providerType)
      ? providerType
      : PROVIDER_TYPE_DEFAULT;
    this.selectedProviderType = ensureProviderType;
  }

  setSelectedProvider(provider: Provider) {
    this.selectedProvider = provider;
  }

  resetProviderSelection() {
    this.selectedProviderType = PROVIDER_TYPE_DEFAULT;
    this.selectedProvider = null;
  }

  getSelectedProviderId(): number {
    if (this.selectedProvider) {
      return this.selectedProvider.id;
    }
  }

  setSelectedAppointmentType(appointmentType: AppointmentType, resetSurvey: boolean = true) {
    this.appointmentType = appointmentType;
    if (resetSurvey) {
      this.resetSurvey();
    }
  }

  setCancellationReason(cancellationReason: AppointmentCancellationReason) {
    this.cancellationReason = cancellationReason;
  }

  getCancellationReasonId(): number {
    return this.cancellationReason ? this.cancellationReason.id : undefined;
  }

  setFromAppointment(fromAppointment: FromAppointment) {
    this.fromAppointment = fromAppointment;
  }

  getFromAppointmentId(): number {
    return this.fromAppointment ? this.fromAppointment.id : undefined;
  }

  resetSurvey() {
    this.requiredSurveyId = appointmentTypeIdToSurveyId(this.getAppointmentTypeId());
    this.createdPatientSurveyId = undefined;
    this.surveyData = undefined;
  }

  hasReasons(): boolean {
    return this.selectedReasons.length > 0;
  }

  serviceAreaSet(): boolean {
    return this.getSelectedServiceArea() != null;
  }

  providerTypeSelected(): boolean {
    return this.selectedProviderType != null;
  }

  appointmentTypeSelected(): boolean {
    return this.appointmentType != null;
  }

  bookingStateComplete(): boolean {
    return (
      this.hasReasons() &&
      this.providerTypeSelected() &&
      this.serviceAreaSet() &&
      this.appointmentTypeSelected() &&
      (!this.requiredSurveyId || (this.requiredSurveyId && !!this.surveyData))
    );
  }

  selectedReasonsString(withWhitespace: boolean = false): string {
    const delimiter = withWhitespace ? ', ' : ',';
    return this.selectedReasons.map(sr => sr.text).join(delimiter);
  }

  toApiV2() {
    const api_v2_data = {
      appointment_booking_state: {
        appointment_cancellation_reason: this.getCancellationReasonId() ? this.cancellationReason.toApiV2() : null,
        appointment_type: this.appointmentType.toApiV2(),
        is_appointment_recommended: this.getIsAppointmentRecommended(),
        provider: this.selectedProvider != null ? this.selectedProvider.toApiV2() : null,
        provider_type: this.selectedProviderType,
        selected_reasons: this.selectedReasons.map(sr => sr.toApiV2()),
        service_area: this.getSelectedServiceArea().toApiV2(),
      },
      appointment_category: ProviderTypeUtil.getTypeCategory(this.selectedProviderType),
      appointment_reason: this.customReasons(),
      appointment_type_id: this.getAppointmentTypeId(),
      provider_id: this.getSelectedProviderId(),
      service_area_id: this.getSelectedServiceArea().id,
      task: this.associatedTask != null ? this.associatedTask.id : null,
      visit_reason_categories: this.getCategorizedReasons(),
    };

    if (this.fromAppointment) {
      api_v2_data['appointment_booking_state']['from_appointment'] = this.fromAppointment.toApiV2();
    }
    return api_v2_data;
  }

  customReasons(): string {
    const fullCustomReasons = this.selectedReasons
      .filter(sr => sr.visitReasonCategory == null)
      .map(sr => this.trimReasonAndRemoveTrailingPunctuation(sr.text.trim()))
      .join(', ');
    return this.exceedsMaxLength(fullCustomReasons) ? `${fullCustomReasons.substring(0, 125)}...` : fullCustomReasons;
  }

  private exceedsMaxLength(reason) {
    return reason.length > 128;
  }

  private trimReasonAndRemoveTrailingPunctuation(reason: string) {
    return /\W/.test(reason.slice(-1)) ? reason.slice(0, -1).trim() : reason;
  }

  private getCategorizedReasons() {
    return this.selectedReasons
      .filter(sr => sr.visitReasonCategory != null)
      .map(sr => sr.visitReasonCategory.toApiV2());
  }

  private getAppointmentTypeId() {
    return this.appointmentType ? this.appointmentType.id : undefined;
  }

  updateRecommendedAppointmentType(recommendedAppointmentType: AppointmentType | undefined) {
    if (!recommendedAppointmentType) {
      this.setSelectedAppointmentType(null);
      this.setIsAppointmentRecommended(false);
    } else {
      this.setSelectedAppointmentType(recommendedAppointmentType);
      this.setIsAppointmentRecommended(true);
    }
  }
}
