/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logged-in-wrapper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../navbar/navbar.component.ngfactory";
import * as i3 from "../navbar/navbar.component";
import * as i4 from "../../core/login.service";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "@angular/router";
import * as i7 from "../../core/window.service";
import * as i8 from "../../core/config.service";
import * as i9 from "../../core/membership.service";
import * as i10 from "../navbar/navbar-analytics.service";
import * as i11 from "../../core/user.service";
import * as i12 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i13 from "@angular/common";
import * as i14 from "../expired-membership-banner/expired-membership-banner.component.ngfactory";
import * as i15 from "../expired-membership-banner/expired-membership-banner.component";
import * as i16 from "../footer/footer.component.ngfactory";
import * as i17 from "../footer/footer.component";
import * as i18 from "./logged-in-wrapper.component";
var styles_LoggedInWrapperComponent = [i0.styles];
var RenderType_LoggedInWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoggedInWrapperComponent, data: {} });
export { RenderType_LoggedInWrapperComponent as RenderType_LoggedInWrapperComponent };
function View_LoggedInWrapperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "om-navbar", [], null, null, null, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(1, 245760, null, 0, i3.NavbarComponent, [i4.LoginService, i5.NgbModal, i6.Router, i7.WindowService, i8.ConfigService, i9.MembershipService, i10.NavbarAnalyticsService, i11.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_LoggedInWrapperComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ngb-alert", [["class", "text-center alert"], ["role", "alert"], ["type", "success"]], [[2, "alert-dismissible", null]], [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = ((_co.closed = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_NgbAlert_0, i12.RenderType_NgbAlert)), i1.ɵdid(1, 638976, null, 0, i5.NgbAlert, [i5.NgbAlertConfig, i1.Renderer2, i1.ElementRef], { type: [0, "type"] }, { close: "close" }), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var currVal_1 = "success"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).dismissible; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.notice; _ck(_v, 2, 0, currVal_2); }); }
export function View_LoggedInWrapperComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoggedInWrapperComponent_1)), i1.ɵdid(3, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "om-expired-membership-banner", [], null, null, null, i14.View_ExpiredMembershipBannerComponent_0, i14.RenderType_ExpiredMembershipBannerComponent)), i1.ɵdid(6, 245760, null, 0, i15.ExpiredMembershipBannerComponent, [i8.ConfigService, i9.MembershipService], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoggedInWrapperComponent_2)), i1.ɵdid(9, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "flex-grow-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(14, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "om-footer", [], null, null, null, i16.View_FooterComponent_0, i16.RenderType_FooterComponent)), i1.ɵdid(17, 114688, null, 0, i17.FooterComponent, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isWebView; _ck(_v, 3, 0, currVal_0); _ck(_v, 6, 0); var currVal_1 = (_co.notice && !_co.closed); _ck(_v, 9, 0, currVal_1); _ck(_v, 14, 0); _ck(_v, 17, 0); }, null); }
export function View_LoggedInWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "om-logged-in-wrapper", [], null, null, null, View_LoggedInWrapperComponent_0, RenderType_LoggedInWrapperComponent)), i1.ɵdid(1, 114688, null, 0, i18.LoggedInWrapperComponent, [i6.ActivatedRoute, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoggedInWrapperComponentNgFactory = i1.ɵccf("om-logged-in-wrapper", i18.LoggedInWrapperComponent, View_LoggedInWrapperComponent_Host_0, {}, {}, []);
export { LoggedInWrapperComponentNgFactory as LoggedInWrapperComponentNgFactory };
