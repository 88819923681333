/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./svg-membership-and-billing.component";
var styles_SvgMembershipAndBillingComponent = [];
var RenderType_SvgMembershipAndBillingComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SvgMembershipAndBillingComponent, data: {} });
export { RenderType_SvgMembershipAndBillingComponent as RenderType_SvgMembershipAndBillingComponent };
export function View_SvgMembershipAndBillingComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(1, 0, null, null, 7, ":svg:svg", [["fill", "none"], ["height", "24"], ["viewBox", "0 0 24 24"], ["width", "24"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(3, 0, null, null, 0, ":svg:path", [["clip-rule", "evenodd"], ["d", "M19.4855 18.0163H4.93947C3.86791 18.0163 3 17.189 3 16.1676V7.84866C3 6.82728 3.86791 6 4.93947 6H19.4855C20.5571 6 21.425 6.82728 21.425 7.84866V16.1676C21.425 17.189 20.5571 18.0163 19.4855 18.0163Z"], ["fill-rule", "evenodd"]], [[1, "stroke", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(5, 0, null, null, 0, ":svg:path", [["d", "M3 9.22437H21.425"]], [[1, "stroke", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(7, 0, null, null, 0, ":svg:path", [["clip-rule", "evenodd"], ["d", "M19.1018 14.1711C19.1018 15.0555 18.384 15.7733 17.4996 15.7733C16.6152 15.7733 15.8975 15.0555 15.8975 14.1711C15.8975 13.2867 16.6152 12.5689 17.4996 12.5689C18.384 12.5689 19.1018 13.2867 19.1018 14.1711Z"], ["fill-rule", "evenodd"]], [[1, "stroke", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n  "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.color; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.color; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.color; _ck(_v, 7, 0, currVal_2); }); }
export function View_SvgMembershipAndBillingComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "om-svg-membership-and-billing", [], null, null, null, View_SvgMembershipAndBillingComponent_0, RenderType_SvgMembershipAndBillingComponent)), i0.ɵdid(1, 49152, null, 0, i1.SvgMembershipAndBillingComponent, [], null, null)], null, null); }
var SvgMembershipAndBillingComponentNgFactory = i0.ɵccf("om-svg-membership-and-billing", i1.SvgMembershipAndBillingComponent, View_SvgMembershipAndBillingComponent_Host_0, { color: "color" }, {}, []);
export { SvgMembershipAndBillingComponentNgFactory as SvgMembershipAndBillingComponentNgFactory };
