<svg
  style="display:block; margin:auto;"
  width="100%"
  height="100%"
  viewBox="0 0 120 120"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <defs></defs>
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(-530.000000, -482.000000)">
      <g id="Group" transform="translate(440.000000, 482.000000)">
        <g transform="translate(90.000000, 0.000000)">
          <circle id="Oval-2" fill="#BFD6D2" cx="60" cy="60" r="60"></circle>
          <g id="Group-3" transform="translate(51.000000, 37.000000)" stroke="#0B5D66" stroke-width="2">
            <path
              d="M12.2967857,4.53261261 C12.2967857,6.55243243 10.6735714,8.19027027 8.67178571,8.19027027 C6.67,8.19027027 5.04678571,6.55243243 5.04678571,4.53261261 C5.04678571,2.51279279 6.67,0.874954955 8.67178571,0.874954955 C10.6735714,0.874954955 12.2967857,2.51279279 12.2967857,4.53261261 L12.2967857,4.53261261 Z"
              id="Stroke-13"
            ></path>
            <path
              d="M13.7139286,47.7958559 L13.7139286,33.2427027 L17.0478571,28.4985586 C17.0478571,28.4985586 15.1353571,15.0264865 14.0085714,13.776036 C12.88,12.5255856 9.04607143,12.2643243 9.04607143,12.2643243 L8.66392857,12.2589189 C8.66392857,12.2589189 4.68714286,12.5255856 3.56035714,13.776036 C2.43357143,15.0264865 0.928214286,28.6589189 0.928214286,28.6589189 L4.78535714,33.4751351 L4.78535714,47.7958559"
              id="Stroke-15"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
