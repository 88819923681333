/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logo-spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./logo-spinner.component";
var styles_LogoSpinner = [i0.styles];
var RenderType_LogoSpinner = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoSpinner, data: {} });
export { RenderType_LogoSpinner as RenderType_LogoSpinner };
function View_LogoSpinner_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "i", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { background: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.color); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LogoSpinner_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "mx-auto my-0"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { height: 0, width: 1 }), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "om-logo-spinner"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LogoSpinner_1)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, (_co.size + "px"), (_co.size + "px")); _ck(_v, 3, 0, currVal_0); var currVal_1 = " ".repeat(5).split(""); _ck(_v, 9, 0, currVal_1); }, null); }
export function View_LogoSpinner_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "om-logo-spinner", [], null, null, null, View_LogoSpinner_0, RenderType_LogoSpinner)), i1.ɵdid(1, 49152, null, 0, i3.LogoSpinner, [], null, null)], null, null); }
var LogoSpinnerNgFactory = i1.ɵccf("om-logo-spinner", i3.LogoSpinner, View_LogoSpinner_Host_0, { size: "size", color: "color" }, {}, []);
export { LogoSpinnerNgFactory as LogoSpinnerNgFactory };
