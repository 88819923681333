import { HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthService } from '@app/core/auth.service';
import { ConfigService } from '@app/core/config.service';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, concat } from 'apollo-link';

import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
const introspectionQueryResultData = require('./fragmentTypes.json');

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

export function createApollo(httpLink: HttpLink, authService: AuthService, configService: ConfigService) {
  const uri = `${configService.json.apiServer}/api/graphql`;
  const http = httpLink.create({ uri });

  const authMiddleware = new ApolloLink((operation, forward) => {
    const token = authService.getToken();
    if (token) {
      const authHeader = `Bearer ${token}`;
      operation.setContext({
        headers: new HttpHeaders().set('Authorization', authHeader),
      });
    }

    return forward(operation);
  });

  return {
    link: concat(authMiddleware, http),
    cache: new InMemoryCache({ fragmentMatcher }),
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthService, ConfigService],
    },
  ],
})
export class GraphQLModule {}
