<div class="footer d-flex">
  <om-logo-sm fill="currentColor" class="mr-4 mb-4 mb-lg-0"></om-logo-sm>

  <div class="d-flex flex-wrap w-100">
    <div class="flex-grow-1 d-flex flex-column">
      <div class="links-section mb-lg-2 primary-links">
        <a href="https://www.onemedical.com/blog/">Blog</a> <span class="dot"></span>
        <a href="https://www.onemedical.com/mediacenter/">Media center</a> <span class="dot"></span>
        <a href="https://www.onemedical.com/about-us/">About</a> <span class="dot"></span>
        <a href="https://www.onemedical.com/practice-integration/">Practice integration</a>
      </div>
      <div class="links-section primary-links">
        <a href="https://www.onemedical.com/sponsored-membership/">Sponsored membership</a> <span class="dot"></span>
        <a href="https://www.onemedical.com/careers/">Careers</a> <span class="dot"></span>
        <a href="https://www.onemedical.com/contact-us/">Contact us</a> <span class="dot"></span>
        <a class="no-margin" href="https://www.onemedical.com/faq/">FAQs</a>
      </div>
    </div>

    <div class="my-4 my-lg-0">
      <om-svg-facebook-link class="social-media-link" fill="currentColor"></om-svg-facebook-link>
      <om-svg-twitter-link class="social-media-link" fill="currentColor"></om-svg-twitter-link>
      <om-svg-youtube-link class="social-media-link" fill="currentColor"></om-svg-youtube-link>
      <om-svg-instagram-link class="social-media-link" fill="currentColor"></om-svg-instagram-link>
      <om-svg-linked-in-link class="social-media-link" fill="currentColor"></om-svg-linked-in-link>
    </div>

    <div class="break d-none d-lg-inline-block"></div>

    <div class="links-section flex-grow-1 secondary-links mb-3 mb-lg-0">
      <a class="small-font-md" href="https://www.onemedical.com/membership-terms/">Membership terms of service</a>
      <span class="dot"></span>
      <a class="small-font-md" href="https://www.onemedical.com/medical-terms-service/">Medical terms of service</a>
      <span class="dot"></span> <a class="small-font-md" href="https://www.onemedical.com/privacy/">Privacy policy</a>
      <span class="dot"></span> <a class="small-font-md" href="https://www.onemedical.com/hipaa/">HIPAA NPP</a>
    </div>

    <span class="small-font-md">&copy; 2019 1Life Healthcare, Inc. All rights reserved.</span>
  </div>
</div>
