
    <a href="https://www.linkedin.com/company/one-medical-group" target="_blank">
      <svg
        [attr.fill]="fill"
        height="18px"
        viewBox="0 0 18 19"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" stroke-width="1" fill-rule="evenodd">
          <g transform="translate(-1318.000000, -173.000000)" fill-rule="nonzero">
            <g transform="translate(1141.000000, 174.000000)">
              <g transform="translate(176.000000, 0.000000)">
                <path
                  d="M4.90624069,17.249997 L1.27343685,17.249997 L1.27343685,5.57033737 L4.90624069,5.57033737 L4.90624069,17.249997 Z M3.10936997,3.96877868 C2.53645447,3.96877868 2.04166438,3.76044605 1.62499851,3.34378017 C1.20833264,2.9271143 1,2.43232421 1,1.85940871 C1,1.28649321 1.20833264,0.791703125 1.62499851,0.375037253 C2.04166438,-0.041628619 2.53645447,-0.249961257 3.10936997,-0.249961257 C3.68228547,-0.249961257 4.17707556,-0.041628619 4.59374143,0.375037253 C5.0104073,0.791703125 5.21873994,1.28649321 5.21873994,1.85940871 C5.21873994,2.43232421 5.0104073,2.9271143 4.59374143,3.34378017 C4.17707556,3.76044605 3.68228547,3.96877868 3.10936997,3.96877868 Z M18.4999583,17.249997 L14.8671544,17.249997 L14.8671544,11.5468856 C14.8671544,10.6093879 14.7890296,9.93230633 14.63278,9.51564046 C14.3463225,8.81251714 13.7864276,8.46095547 12.9530965,8.46095547 C12.1197654,8.46095547 11.5338293,8.77345473 11.1952882,9.39845324 C10.9348719,9.86720212 10.8046641,10.5573048 10.8046641,11.4687608 L10.8046641,17.249997 L7.21092269,17.249997 L7.21092269,5.57033737 L10.6874769,5.57033737 L10.6874769,7.17189605 L10.7265393,7.17189605 C10.9869556,6.65106416 11.4036208,6.22137768 11.9765363,5.88283662 C12.6015348,5.46617075 13.3307,5.25783811 14.1640311,5.25783811 C15.8567352,5.25783811 17.0416286,5.7916912 17.7187101,6.85939679 C18.239542,7.71876974 18.4999583,9.04689158 18.4999583,10.8437623 L18.4999583,17.249997 Z"
                  id="Linkedin"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </a>
  