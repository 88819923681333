<div>
  <div class="doc-title p-3 d-flex justify-content-between" [class.agree]="agree">
    <span class="agreement-title"><ng-content></ng-content></span>

    <button (click)="agreeSelected($event)" [ngClass]="agree || processing ? 'icon' : 'text'" [disabled]="agree">
      <span *ngIf="!agree && !processing" class="button-hint">I agree</span>
      <om-logo-spinner class="spinner" *ngIf="processing" size="20"></om-logo-spinner>
      <om-svg *ngIf="agree" image="tos_checkmark" class="checkmark"></om-svg>
    </button>
  </div>
</div>
