import { map } from 'rxjs/operators';
import mapValues from 'lodash-es/mapValues';
import lodashMap from 'lodash-es/map';
import merge from 'lodash-es/merge';
import pick from 'lodash-es/pick';
import { ApiService } from '../core/api.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Task } from './task';

interface UpdateParams {
  feedback?: string;
  event_type?: string;
  year?: string;
  month?: string;
  otherProvider?: object;
}

@Injectable()
export class TaskService {
  private _tasks$ = new Subject();
  tasks$ = this._tasks$.asObservable();

  constructor(private apiService: ApiService) {}

  getTasks() {
    const params = {
      completed: false,
      declined: false,
      group_by: 'due_range',
    };
    this.apiService.get('/api/v2/patient/tasks', false, params).subscribe((groups: any) => {
      const groupedTasks = mapValues(groups, tasks => lodashMap(tasks, task => Task.fromApiV2(task)));
      this._tasks$.next(groupedTasks);
    });
  }

  updateTask(id, event_type, params: UpdateParams = {}) {
    let postParams: UpdateParams = { event_type: event_type };
    postParams = merge(postParams, pick(params, ['feedback', 'message', 'year', 'month', 'other_provider']));
    return this.apiService.patch(`/api/v2/patient/tasks/${id}`, postParams);
  }

  getTask(id) {
    return this.apiService.get(`/api/v2/patient/tasks/${id}`).pipe(map(task => Task.fromApiV2(task)));
  }
}
