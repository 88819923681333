import { Component, OnInit, ViewChild } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { ConfigService } from '../config.service';
import { LoginService } from '../login.service';
import { Membership } from '../membership';
import { MembershipService } from '../membership.service';
import { RollbarService } from '../rollbar.service';
import { WindowService } from '../window.service';

@Component({
  selector: 'om-login-form',
  providers: [],
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})

// Component controller
export class LoginFormComponent implements OnInit {
  static ENTER_KEY_CODE = 13;

  @ViewChild('userNameInput', { static: true }) userNameInput;
  @ViewChild('passwordInput', { static: true }) passwordInput;

  processing: boolean;
  formError = '';
  username = '';
  password = '';
  private notifier;

  constructor(
    private authService: AuthService,
    public config: ConfigService,
    private loginService: LoginService,
    private rollbarService: RollbarService,
    private membershipService: MembershipService,
    private windowService: WindowService,
  ) {}

  ngOnInit() {
    this.processing = false;
    this.notifier = (<any>this.rollbarService).scope({ component: { name: 'login-form' } });
  }

  login(): void {
    this.processing = true;
    this.loginService
      .login(this.username, this.password)
      .pipe(switchMap(() => this.membershipService.getMembershipWithRequest()))
      .subscribe({
        next: (membership: Membership) => {
          if (membership.isExpired()) {
            this.authService.setAttemptedPath('/membership/renew');
            this.authService.navigateToAttemptedPath();
          } else if (membership.hasLimitedAccess()) {
            this.windowService.redirect(`${this.config.json.myoneServer}/pt/guest`);
          } else {
            this.authService.navigateToAttemptedPath();
          }
        },
        error: response => {
          if (response.status === 400) {
            if (response.error) {
              // TODO: Use translation file
              if (response.error.error === 'invalid_resource_owner' || response.error.error === 'invalid_grant') {
                this.formError = 'Username or password is incorrect';
              } else if (response.error.error) {
                this.formError = response.error.error;
              } else {
                this.notifier.warning(`400 unhandled error: ${response}`);
                this.formError = 'We are having some difficulties at the moment.';
              }
            }
          } else {
            this.notifier.error(`${response.status} error: ${response.error}`);
            this.formError = 'We are having some difficulties at the moment.';
          }
          this.processing = false;
        },
      });

    this.formError = '';
    this.userNameInput.control.markAsTouched();
    this.passwordInput.control.markAsTouched();
  }

  handleKeyPress(event: KeyboardEvent): void {
    if (event.keyCode === LoginFormComponent.ENTER_KEY_CODE) {
      this.login();
    }
  }
}
