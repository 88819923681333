import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';

export interface FeatureFlag {
  name: string;
  enabledForDiscountCode: boolean;
}

export interface Response {
  featureFlag: FeatureFlag;
}

export interface Variables {
  code: string;
  name: string;
}

@Injectable()
export class FeatureFlagGraphQL extends Query<Response, Variables> {
  document = gql`
    query($name: String!, $code: String!) {
      featureFlag(name: $name) {
        name
        enabledForDiscountCode(code: $code)
      }
    }
  `;
}
