
    <a href="https://www.youtube.com/channel/UCVuDmERByiofU8ss3Nz_Odw/featured" target="_blank">
      <svg [attr.fill]="fill" height="18px" viewBox="0 0 22 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" stroke-width="1" fill-rule="evenodd">
          <g transform="translate(-1228.000000, -176.000000)" fill-rule="nonzero">
            <g transform="translate(1141.000000, 174.000000)">
              <g transform="translate(88.000000, 0.000000)">
                <path
                  d="M20.4843238,4.34378017 C20.6666152,5.0469035 20.796823,6.11460909 20.8749478,7.54689754 L20.9140103,9.50001788 L20.8749478,11.4531382 C20.796823,12.9114679 20.6666152,13.9921947 20.4843238,14.695318 C20.354116,15.1380251 20.1197415,15.5286491 19.7812005,15.8671902 C19.4426594,16.2057313 19.0390147,16.4401057 18.5702658,16.5703135 C17.8931843,16.752605 16.3046462,16.8828128 13.8046522,16.9609376 L10.2499732,17 L6.69529415,16.9609376 C4.19530011,16.8828128 2.60676204,16.752605 1.92968052,16.5703135 C1.46093163,16.4401057 1.05728696,16.2057313 0.718745902,15.8671902 C0.380204844,15.5286491 0.145830403,15.1380251 0.0156225786,14.695318 C-0.166668852,13.9921947 -0.296876676,12.9114679 -0.37500149,11.4531382 L-0.414063897,9.50001788 C-0.414063897,8.92710238 -0.401043293,8.27606266 -0.37500149,7.54689754 C-0.296876676,6.11460909 -0.166668852,5.0469035 0.0156225786,4.34378017 C0.145830403,3.87503129 0.380204844,3.47138662 0.718745902,3.13284556 C1.05728696,2.7943045 1.46093163,2.55993006 1.92968052,2.42972224 C2.60676204,2.24743081 4.19530011,2.11722298 6.69529415,2.03909817 L10.2499732,2.00003576 L13.8046522,2.03909817 C16.3046462,2.11722298 17.8931843,2.24743081 18.5702658,2.42972224 C19.0390147,2.55993006 19.4426594,2.7943045 19.7812005,3.13284556 C20.1197415,3.47138662 20.354116,3.87503129 20.4843238,4.34378017 Z M8.06247839,12.7031352 L13.6484026,9.50001788 L8.06247839,6.33596293 L8.06247839,12.7031352 Z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </a>
  