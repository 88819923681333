import { of as observableOf, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { ConfigService } from '../core/config.service';
import { Injectable } from '@angular/core';
import { UserService } from '../core/user.service';

@Injectable()
export class RegistrationCompleteGuardService implements CanActivate {
  private desiredRoute: string;

  constructor(
    private authService: AuthService,
    private config: ConfigService,
    private userService: UserService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    this.desiredRoute = state.url.split('?')[0];

    if (this.authService.getToken() == null && this.desiredRoute === '/registration') {
      return true;
    } else {
      return this.userService.getUser(true).pipe(
        map((user: any) => {
          if (this.desiredRoute === '/registration' && user.is_reg_complete) {
            window.location.href = this.config.json.myoneServer + '/pt/my-one/index';
            return false;
          } else if (this.desiredRoute === '/registration' && !user.is_reg_complete) {
            return true;
          } else if (this.desiredRoute !== '/registration' && !user.is_reg_complete) {
            window.location.href = this.config.json.myoneServer + '/pt/registration/restart_registration';
            return false;
          } else {
            return true;
          }
        }),
        catchError(error => {
          this.router.navigate(['/messages'], { replaceUrl: true });
          return observableOf(false);
        }),
      );
    }
  }
}
