import { VisitReasonCategory } from './visit-reason-category';

export class SelectedReason {
  constructor(text: string, visitReasonCategory?: VisitReasonCategory) {
    this.text = text;
    this.visitReasonCategory = visitReasonCategory;
  }
  text: string;
  visitReasonCategory: VisitReasonCategory;

  static fromApiV2(json) {
    const visitReasonCategory =
      json['visit_reason_category'] != null ? VisitReasonCategory.fromApiV2(json['visit_reason_category']) : null;
    return new SelectedReason(json['text'], visitReasonCategory);
  }

  categorizable(): boolean {
    return this.visitReasonCategory != null;
  }

  categorizableId(): number {
    return this.categorizable() ? this.visitReasonCategory.categorizableId : null;
  }

  categorizableType(): string {
    return this.categorizable() ? this.visitReasonCategory.categorizableType : null;
  }

  toApiV2() {
    const apiParams = {
      text: this.text,
    };
    if (this.categorizable()) {
      apiParams['visit_reason_category'] = this.categoryToApiV2();
    }
    return apiParams;
  }

  private categoryToApiV2() {
    return this.categorizable() ? this.visitReasonCategory.toApiV2() : null;
  }
}
