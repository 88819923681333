import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService {
  json;

  constructor() {
    this.json = environment;
  }
}
