/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./svg-gift-box-icon.component";
var styles_SvgGiftBoxIcon = [];
var RenderType_SvgGiftBoxIcon = i0.ɵcrt({ encapsulation: 2, styles: styles_SvgGiftBoxIcon, data: {} });
export { RenderType_SvgGiftBoxIcon as RenderType_SvgGiftBoxIcon };
export function View_SvgGiftBoxIcon_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, ":svg:svg", [["fill", "none"], ["height", "24"], ["viewBox", "0 0 24 24"], ["width", "24"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(2, 0, null, null, 0, ":svg:path", [["d", "M23.4 5.9892H18.1428C19.1496 5.4468 19.8 4.5912 19.8 3.6C19.8 1.9704 17.9268 0 15.6 0C13.9812 0 12.7032 1.8816 12 3.618C11.2968 1.8816 10.0188 0 8.4 0C6.0732 0 4.2 1.9704 4.2 3.6C4.2 4.5912 4.8504 5.4468 5.8572 5.9892H0.6C0.2688 5.9892 0 6.258 0 6.5892V10.1892C0 10.5204 0.2688 10.7892 0.6 10.7892H2.4V23.4C2.4 23.7312 2.6688 24 3 24H21C21.3312 24 21.6 23.7312 21.6 23.4V10.7892H23.4C23.7312 10.7892 24 10.5204 24 10.1892V6.5892C24 6.258 23.7312 5.9892 23.4 5.9892ZM9.6 9.5892V7.1892H14.4V9.5892H9.6ZM14.4 10.7892V22.8H9.6V10.7892H14.4ZM15.6 1.2C17.3112 1.2 18.6 2.6556 18.6 3.6C18.6 4.5756 17.226 5.4 15.6 5.4C14.73 5.4 13.9536 5.622 13.3308 5.7984C13.05 5.8776 12.7812 5.9556 12.6 5.9796C12.6156 4.7268 14.1336 1.2 15.6 1.2ZM8.4 1.2C9.8268 1.2 11.3028 4.5396 11.3868 5.9772C11.2056 5.9508 10.9428 5.8764 10.6692 5.7984C10.0464 5.622 9.27 5.4 8.4 5.4C6.774 5.4 5.4 4.5756 5.4 3.6C5.4 2.6556 6.6888 1.2 8.4 1.2ZM1.2 7.1892H8.4V9.5892H1.2V7.1892ZM3.6 10.7892H8.4V22.8H3.6V10.7892ZM20.4 22.8H15.6V10.7892H20.4V22.8ZM22.8 9.5892H15.6V7.1892H22.8V9.5892Z"]], [[1, "fill", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fill; _ck(_v, 2, 0, currVal_0); }); }
export function View_SvgGiftBoxIcon_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "om-svg-gift-box-icon", [], null, null, null, View_SvgGiftBoxIcon_0, RenderType_SvgGiftBoxIcon)), i0.ɵdid(1, 49152, null, 0, i1.SvgGiftBoxIcon, [], null, null)], null, null); }
var SvgGiftBoxIconNgFactory = i0.ɵccf("om-svg-gift-box-icon", i1.SvgGiftBoxIcon, View_SvgGiftBoxIcon_Host_0, { fill: "fill" }, {}, []);
export { SvgGiftBoxIconNgFactory as SvgGiftBoxIconNgFactory };
