<ng-template #logo>
  <div class="d-block d-md-none d-lg-block"><om-logo></om-logo></div>
  <div class="d-none d-md-block d-lg-none"><om-logo-sm></om-logo-sm></div>
</ng-template>
<ng-template #brand>
  <a
    routerLink="/"
    [omTrackLink]="trackHomeLink"
    class="navbar-brand home"
    data-cy="om-logo-nav"
    [class.disabled]="!membership.isActive"
  >
    <ng-container *ngTemplateOutlet="logo"></ng-container>
  </a>
</ng-template>

<nav
  aria-label="Main Nav"
  data-cy="main-nav"
  class="navbar full d-none d-md-flex navbar-expand-md justify-content-between bg-white fixed-top shadow-sm"
  *ngIf="membership"
>
  <ng-container *ngTemplateOutlet="brand"></ng-container>

  <ul class="navbar-nav d-flex align-items-center">
    <li class="nav-item">
      <a
        routerLink="/"
        [omTrackLink]="trackHomeLink"
        data-cy="home-nav"
        class="nav-link text-link home"
        [class.disabled]="!membership.isActive"
        >Home</a
      >
    </li>
    <li class="nav-item"
      ><a
        routerLink="/health-record"
        [omTrackLink]="trackHealthRecordLink"
        data-cy="health-record-nav"
        class="nav-link text-link health-record"
        >Health Record</a
      ></li
    >
    <li class="nav-item"
      ><a
        routerLink="/messages"
        [omTrackLink]="trackMessagesLink"
        data-cy="messages-nav"
        class="nav-link text-link messages"
        >Messages</a
      ></li
    >
    <li class="nav-item">
      <a routerLink="/tasks" [omTrackLink]="trackTasksLink" data-cy="care-plan-nav" class="nav-link text-link tasks"
        >Care Plan</a
      >
    </li>
    <li class="nav-item">
      <a
        routerLink="/appointments/new"
        [omTrackLink]="trackBookVisitLink"
        data-cy="book-visit-nav"
        class="nav-link btn btn-outline-primary book-visit"
        [class.disabled]="!membership.canBookVisit"
        >Book Visit</a
      >
    </li>
    <li
      *ngIf="(this.userService.user$ | async) as currentUser"
      class="nav-item"
      ngbDropdown
      display="dynamic"
      placement="bottom-right"
    >
      <button aria-label="Profile Menu Toggle" class="nav-link navbar-menu" ngbDropdownToggle id="profile-menu-label">
        <om-profile-bubble
          aria-hidden="true"
          [profile]="user"
          [colorCombo]="user.id"
          class="navbar-menu"
          data-cy="profile-menu-nav"
        ></om-profile-bubble>
      </button>
      <div aria-label="Profile Menu" ngbDropdownMenu aria-labelledby="profile-menu-label" class="dropdown-menu">
        <a class="dropdown-item" routerLink="/membership/settings" data-cy="account-nav">Account</a>
        <a class="dropdown-item" [href]="config.json.myoneServer + '/pt/appointments'" data-cy="appointments-nav"
          >Appointments</a
        >
        <a class="dropdown-item" [href]="config.json.myoneServer + '/pt/my_account'" data-cy="profile-nav">Profile</a>
        <a *ngIf="user.isDirectSignupEligible" class="dropdown-item" (click)="registerFamily()" data-cy="family-nav">
          Register Family Member
        </a>
        <a class="dropdown-item inline-icon-with-text" (click)="openInviteModal()" data-cy="invite-nav">
          {{ inviteCta }}
          <om-svg-gift-box-icon fill="currentColor" class="nav-icon ml-3"></om-svg-gift-box-icon>
        </a>
        <a class="dropdown-item" href="#" (click)="logout($event)" data-cy="log-out-nav">Log Out</a>
      </div>
    </li>
  </ul>
</nav>

<div
  class="d-flex d-md-none flex-column fixed-top mobile-nav-container"
  data-cy="hamburger-menu"
  [class.open]="slideMenuOpen"
  *ngIf="membership"
>
  <nav aria-label="Main Nav" class="navbar full d-flex justify-content-between bg-white shadow-sm">
    <ng-container *ngTemplateOutlet="brand"></ng-container>

    <div class="mr-2">
      <a
        routerLink="/messages"
        [omTrackLink]="trackMessagesLink"
        aria-label="Messages"
        data-cy="messages-icon-nav"
        class="svg-message-container"
        *ngIf="!slideMenuOpen"
      >
        <om-svg-message stroke="currentColor"></om-svg-message>
      </a>

      <button
        class="btn navbar-toggler navbar-side-control"
        type="button"
        (click)="toggleSlideMenu()"
        [attr.aria-expanded]="slideMenuOpen"
        aria-label="Toggle Menu"
        title="Toggle navigation"
        data-cy="toggle-slide-menu"
      >
        <ng-container *ngIf="slideMenuOpen; then closeButton; else openButton"></ng-container>
      </button>

      <ng-template #openButton
        ><om-svg-hamburger stroke="currentColor" data-cy="open-slide-menu"></om-svg-hamburger
      ></ng-template>

      <ng-template #closeButton><om-close-x stroke="currentColor" data-cy="close-slide-menu"></om-close-x></ng-template>
    </div>
  </nav>

  <div class="slide-menu" [class.open]="slideMenuOpen">
    <nav aria-label="Expandable Main Menu" class="navbar d-flex">
      <ul class="navbar-nav" (click)="toggleSlideMenu()">
        <li>
          <a
            class="dropdown-item book-visit"
            [class.disabled]="!membership.canBookVisit"
            routerLink="/appointments/new"
            [omTrackLink]="trackBookVisitLink"
            data-cy="book-visit-slide-menu"
          >
            Book Visit
          </a>
        </li>
        <li><a class="dropdown-item messages" routerLink="/messages" data-cy="messages-slide-menu">Messages</a></li>
        <li>
          <a
            class="dropdown-item tasks"
            routerLink="/tasks"
            [omTrackLink]="trackTasksLink"
            data-cy="care-plan-slide-menu"
          >
            Care Plan
          </a>
        </li>
        <li><div class="dropdown-item disabled" data-cy="health-record-slide-menu">Health Record</div></li>
        <li>
          <a class="dropdown-item sub-nav inline-icon-with-text" routerLink="/health-record/lab-results">
            <om-svg-lab-result
              color="currentColor"
              class="nav-icon"
              data-cy="lab-results-slide-menu"
            ></om-svg-lab-result>
            Lab Results
          </a>
        </li>
        <li>
          <a class="dropdown-item sub-nav inline-icon-with-text" routerLink="/health-record/blood-pressure">
            <om-svg-vitals color="currentColor" class="nav-icon" data-cy="blood-pressure-slide-menu"></om-svg-vitals>
            Blood Pressure
          </a>
        </li>
        <li><div class="dropdown-item disabled" data-cy="account-slide-menu">Account</div></li>
        <li>
          <a class="dropdown-item sub-nav inline-icon-with-text" routerLink="/membership/settings">
            <om-svg-membership-and-billing
              color="#005450"
              class="nav-icon"
              data-cy="membership-billing-slide-menu"
            ></om-svg-membership-and-billing>
            Membership &amp; Billing
          </a>
        </li>
        <li>
          <a class="dropdown-item sub-nav inline-icon-with-text" routerLink="/account/notification-settings">
            <om-svg-notification-settings
              color="currentColor"
              class="nav-icon"
              data-cy="notification-settings-slide-menu"
            ></om-svg-notification-settings>
            Notification Settings
          </a>
        </li>
        <li *ngIf="user && !user.isPediatric()">
          <a
            class="dropdown-item sub-nav inline-icon-with-text hie-link"
            routerLink="/account/health-information-exchange"
          >
            <om-svg-health-information-exchange
              color="currentColor"
              class="nav-icon"
              data-cy="health-information-exchange-slide-menu"
            ></om-svg-health-information-exchange>
            Health Information Exchange
          </a>
        </li>
        <li class="mobile-app-link" *ngIf="!!mobileAppLink">
          <a
            class="dropdown-item"
            target="_blank"
            [href]="mobileAppLink"
            (click)="handleAppLinkClick()"
            data-cy="moblie-app-link"
            >Get The App</a
          >
        </li>
        <li
          ><a
            class="dropdown-item"
            [href]="config.json.myoneServer + '/pt/appointments'"
            data-cy="appointments-slide-menu"
            >Appointments</a
          ></li
        >
        <li>
          <a class="dropdown-item" [href]="config.json.myoneServer + '/pt/my_account'" data-cy="profile-slide-menu">
            Profile
          </a>
        </li>
        <li>
          <a
            class="dropdown-item sub-nav inline-icon-with-text"
            (click)="openInviteModal()"
            data-cy="profile-slide-menu"
          >
            <om-svg-gift-box-icon fill="currentColor" class="nav-icon"></om-svg-gift-box-icon>
            {{ inviteCta }}
          </a>
        </li>
        <li><a class="dropdown-item" href="#" (click)="logout($event)" data-cy="log-out-slide-menu">Log Out</a></li>
      </ul>
    </nav>
  </div>

  <div class="overlay" [class.open]="slideMenuOpen" [@overlayVisible]="slideMenuOpen" (click)="toggleSlideMenu()"></div>
</div>
