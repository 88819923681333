/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./spinner.component";
var styles_Spinner = [i0.styles];
var RenderType_Spinner = i1.ɵcrt({ encapsulation: 0, styles: styles_Spinner, data: {} });
export { RenderType_Spinner as RenderType_Spinner };
function View_Spinner_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, ":svg:svg", [["class", "spinner animation-rotate"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:circle", [["class", "animation-processing-dash"], ["cx", "26"], ["cy", "26"], ["r", "24"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, null); }
function View_Spinner_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, ":svg:svg", [["class", "spinner animation-rotate extra-small"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:circle", [["class", "animation-processing-dash"], ["cx", "22"], ["cy", "22"], ["r", "19"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, null); }
function View_Spinner_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, ":svg:svg", [["class", "spinner animation-rotate large"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:circle", [["class", "animation-processing-large-dash"], ["cx", "31"], ["cy", "31"], ["r", "29"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, null); }
function View_Spinner_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, ":svg:svg", [["class", "spinner animation-rotate extra-large"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:circle", [["class", "animation-processing-extra-large-dash"], ["cx", "93.5"], ["cy", "93.5"], ["r", "91.5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, null); }
export function View_Spinner_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Spinner_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Spinner_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Spinner_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Spinner_4)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.size != "large") && (_co.size != "extra-large")) && (_co.size != "extra-small")); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.size == "extra-small"); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.size == "large"); _ck(_v, 8, 0, currVal_2); var currVal_3 = (_co.size == "extra-large"); _ck(_v, 11, 0, currVal_3); }, null); }
export function View_Spinner_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "om-spinner", [], null, null, null, View_Spinner_0, RenderType_Spinner)), i1.ɵdid(1, 49152, null, 0, i3.Spinner, [], null, null)], null, null); }
var SpinnerNgFactory = i1.ɵccf("om-spinner", i3.Spinner, View_Spinner_Host_0, { size: "size" }, {}, []);
export { SpinnerNgFactory as SpinnerNgFactory };
