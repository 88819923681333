import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-spinner',
  providers: [],
  template: `
    <span *ngIf="size != 'large' && size != 'extra-large' && size != 'extra-small'">
      <svg class="spinner animation-rotate">
        <circle class="animation-processing-dash" r="24" cx="26" cy="26"></circle>
      </svg>
    </span>
    <span *ngIf="size == 'extra-small'">
      <svg class="spinner animation-rotate extra-small">
        <circle class="animation-processing-dash" r="19" cx="22" cy="22"></circle>
      </svg>
    </span>
    <span *ngIf="size == 'large'">
      <svg class="spinner animation-rotate large">
        <circle class="animation-processing-large-dash" r="29" cx="31" cy="31"></circle>
      </svg>
    </span>
    <span *ngIf="size == 'extra-large'">
      <svg class="spinner animation-rotate extra-large">
        <circle class="animation-processing-extra-large-dash" r="91.5" cx="93.5" cy="93.5"></circle>
      </svg>
    </span>
  `,
  styleUrls: ['./spinner.component.scss'],
})
export class Spinner {
  @Input() size = 'small';

  constructor() {}
}
