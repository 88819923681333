import * as tslib_1 from "tslib";
import { AnalyticsService } from '@app/core/analytics.service';
var NavbarAnalyticsService = /** @class */ (function (_super) {
    tslib_1.__extends(NavbarAnalyticsService, _super);
    function NavbarAnalyticsService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.submodule = 'Navigation Bar';
        return _this;
    }
    NavbarAnalyticsService.prototype.trackHealthRecordLink = function () {
        return _super.prototype.trackHealthRecordLink.call(this, {
            submodule: this.submodule,
        });
    };
    NavbarAnalyticsService.prototype.trackBookVisitLink = function () {
        return _super.prototype.trackBookVisitLink.call(this, {
            submodule: this.submodule,
        });
    };
    NavbarAnalyticsService.prototype.trackHomeLink = function (querySelector) {
        return this.trackWithDefaultProperties('Home Clicked', {
            flow: 'Home Page Navigation',
            submodule: this.submodule,
        });
    };
    NavbarAnalyticsService.prototype.trackTasksLink = function () {
        return this.trackWithDefaultProperties('Tasks Clicked', {
            flow: 'Patient Tasks',
            submodule: this.submodule,
        });
    };
    NavbarAnalyticsService.prototype.trackMessagesLink = function () {
        return this.trackWithDefaultProperties('Messages Clicked', {
            flow: 'Patient Tasks',
            submodule: this.submodule,
        });
    };
    return NavbarAnalyticsService;
}(AnalyticsService));
export { NavbarAnalyticsService };
