<div class="card">
  <div class="card-body">
    <div class="text-center">
      <om-logo class="om-logo"></om-logo>
      <p class="mb-3">Please log in to your One Medical account</p>
    </div>

    <form #loginForm="ngForm">
      <div class="form-group mb-3" [class.has-error]="!userNameInput.valid && userNameInput.touched">
        <input
          type="email"
          name="username"
          class="form-control lined-input"
          required
          [(ngModel)]="username"
          #userNameInput="ngModel"
          (keypress)="handleKeyPress($event)"
          placeholder="Email"
        />
      </div>
      <div class="form-group mb-1" [class.has-error]="!passwordInput.valid && passwordInput.touched">
        <input
          type="password"
          name="password"
          class="form-control lined-input"
          required
          [(ngModel)]="password"
          #passwordInput="ngModel"
          (keypress)="handleKeyPress($event)"
          placeholder="Password"
        />
      </div>
      <div class="forgot-password small text-right mb-3">
        <a [href]="config.json.myoneServer + '/pt/auth/forgot_password'">Forgot Password?</a>
      </div>

      <button type="button" class="btn btn-lg btn-primary btn-block mt-4" (click)="login()" [disabled]="processing">
        <span [hidden]="processing">Log In</span> <span [hidden]="!processing">Logging In...</span>
      </button>
      <div [hidden]="formError.length == 0" class="text-danger">{{ formError }}</div>
    </form>
  </div>
</div>

<div class="card-body">
  <div class="text-center">
    <p class="mb-3">
      Not a One Medical member? <a [href]="config.json.myoneServer + '/pt/registration/register'">Join now</a>
    </p>
    <p class="mb-3">
      Have an employee benefit code?
      <a [href]="config.json.myoneServer + '/enterprise_registration/determine_b2b_type'">Enter code</a>
    </p>
  </div>
</div>
