import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class FlashService {
  private initialMessages: object = {
    alert: [],
    error: [],
    notice: [],
  };
  private messages: object;
  private _messages$ = new Subject<Object>();
  messages$: Observable<Object> = this._messages$.asObservable();

  constructor() {
    this.clearAll();
  }

  addFlashMessage(message, type) {
    this.messages[type].push(message);
    this._messages$.next(this.messages);
  }

  clearMessages(type) {
    this.messages[type] = [];
    this._messages$.next(this.messages);
  }

  clearAll() {
    this.messages = this.initialMessages;
    this._messages$.next(this.messages);
  }
}
