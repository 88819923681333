import { Injectable } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics.service';

@Injectable()
export class NavbarAnalyticsService extends AnalyticsService {
  readonly submodule = 'Navigation Bar';

  trackHealthRecordLink() {
    return super.trackHealthRecordLink({
      submodule: this.submodule,
    });
  }

  trackBookVisitLink() {
    return super.trackBookVisitLink({
      submodule: this.submodule,
    });
  }

  trackHomeLink(querySelector: string) {
    return this.trackWithDefaultProperties('Home Clicked', {
      flow: 'Home Page Navigation',
      submodule: this.submodule,
    });
  }

  trackTasksLink() {
    return this.trackWithDefaultProperties('Tasks Clicked', {
      flow: 'Patient Tasks',
      submodule: this.submodule,
    });
  }

  trackMessagesLink() {
    return this.trackWithDefaultProperties('Messages Clicked', {
      flow: 'Patient Tasks',
      submodule: this.submodule,
    });
  }
}
