import map from 'lodash-es/map';
import range from 'lodash-es/range';
import take from 'lodash-es/take';
var MonthData = /** @class */ (function () {
    function MonthData() {
    }
    return MonthData;
}());
export { MonthData };
var DateService = /** @class */ (function () {
    function DateService() {
        this.monthData = this.buildMonthData();
        this.currentYear = moment().year();
        this.currentMonth = moment().month() + 1;
    }
    DateService.prototype.buildMonthData = function () {
        return map(moment.months(), function (month, index) {
            var monthNumber = index + 1;
            var monthNumberString = monthNumber < 10 ? "0" + monthNumber.toString() : monthNumber.toString();
            return { name: month, monthNumber: monthNumberString };
        });
    };
    Object.defineProperty(DateService.prototype, "months", {
        get: function () {
            return this.monthData;
        },
        enumerable: true,
        configurable: true
    });
    DateService.prototype.lastXYears = function (number) {
        return range(this.currentYear, this.currentYear - number);
    };
    DateService.prototype.pastCurrentMonthsForYear = function (year) {
        if (year < this.currentYear) {
            return this.months;
        }
        return take(this.monthData, this.currentMonth);
    };
    return DateService;
}());
export { DateService };
