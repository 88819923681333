import replace from 'lodash-es/replace';
import upperFirst from 'lodash-es/upperFirst';
import { Provider } from '../shared/provider';
import { MarkdownService } from '@app/core/markdown.service';

export class LabOrderContent {
  fastingRequired: boolean;
  numberOfTests: number;
  testsOrdered: string[];
  appointmentNeeded: boolean;
}

export class VisitFollowUpOrderContent {
  visitReason: string;
  visitWithProvider: Provider;
  appointmentType: string;
}

export class BasicFollowUpOrderContent {
  problem: string;
  feedbackOptions: string[];
  feedback: string;
}

export class GenericFollowUpOrderContent {
  question: string;
  message: string;
  title: string;
}

export class VaccineOrderContent {
  isAutomated: boolean;
  name: string;
  step: string;
  walkin: boolean;
  isFluVaccine: boolean;
  information: string;
  buttonName: string;
  buttonLink: string;
  showNearbyLocations: boolean;
  instruction: string;
  title: string;
}

export class SurveyOrderContent {
  surveyId: number;
  instructions: string;
}

export class ConsultOrderContent {
  providerImageUrl: string;
  providerName: string;
  consultantDisplayName: string;
  consultantPhoneNumber: string;
  consultantSpecialtyName: string;
  consultantFacilityName: string;
  consultantAddress1: string;
  consultantAddress2: string;
  consultantCity: string;
  consultantState: string;
  consultantZip: string;
  consultantLatitude: string;
  consultantLongitude: string;
}

export class ProcedureOrderContent {
  providerImageUrl: string;
  providerName: string;
  consultantDisplayName: string;
  consultantPhoneNumber: string;
  consultantSpecialtyName: string;
  consultantFacilityName: string;
  consultantAddress1: string;
  consultantAddress2: string;
  consultantCity: string;
  consultantState: string;
  consultantZip: string;
  consultantLatitude: string;
  consultantLongitude: string;
}

export class Task {
  id: number;
  state: string;
  scheduledAt: string;
  type: string;
  iconName: string;
  title: string;
  isCompletable: boolean;
  isCompleted: boolean;
  completedOn: string;

  subtitle: string;
  phiVisibleTitle: string;
  instructions: string;
  instructionsTitle: string;
  orderedByString: string;
  scheduledAtString: string;
  taskOptionsTitle: string;
  taskOptionsDescription: string;

  isDeclined: boolean;
  isAssigned: boolean;
  declinedOn: string;
  provider: Provider;
  content:
    | BasicFollowUpOrderContent
    | ConsultOrderContent
    | ProcedureOrderContent
    | GenericFollowUpOrderContent
    | LabOrderContent
    | SurveyOrderContent
    | VaccineOrderContent
    | VisitFollowUpOrderContent;

  constructor() {}

  static fromApiV2(object): Task {
    const markdownService = new MarkdownService();
    const task = new Task();
    task.id = object.id;
    task.state = object.state;
    task.scheduledAt = object.scheduled_at;
    task.type = object.content_type;
    task.iconName = replace(object.content_type, /_/g, '-');
    task.isCompletable = Task.isCompletable(task.type);
    task.isCompleted = Task.isCompleted(task.state);
    task.completedOn = object.completed_on;
    task.isDeclined = Task.isDeclined(task.state);
    task.declinedOn = object.declined_on;
    task.isAssigned = task.state === 'assigned';
    task.phiVisibleTitle = object.phi_visible_title;
    task.instructions = markdownService.convert(object.instructions || '');
    task.instructionsTitle = object.instructions_title;
    task.subtitle = object.subtitle;
    task.orderedByString = object.ordered_by_string;
    task.scheduledAtString = object.scheduled_at_string;
    task.taskOptionsTitle = object.task_options_title;
    task.taskOptionsDescription = object.task_options_description;
    task.subtitle = object.subtitle;
    task.provider = Provider.fromApiV2(object.internal_user);
    task.content = Task.buildContent(object);
    return task;
  }

  private static isCompletable(type: string): boolean {
    return (
      type !== 'basic_follow_up_order' &&
      type !== 'lab_order' &&
      type !== 'survey_order' &&
      type !== 'generic_follow_up_order'
    );
  }

  private static isCompleted(state: string): boolean {
    return state === 'completed';
  }

  private static isDeclined(state: string): boolean {
    return state === 'declined';
  }

  private static buildContent(object) {
    if (object.content_type === 'lab_order') {
      return Task.buildLabOrderContent(object.content_attributes);
    } else if (object.content_type === 'visit_follow_up_order') {
      return Task.buildVisitFollowUpOrderContent(object.content_attributes);
    } else if (object.content_type === 'basic_follow_up_order') {
      return Task.buildBasicFollowUpOrderContent(object.content_attributes);
    } else if (object.content_type === 'vaccine_order') {
      return Task.buildVaccineOrderContent(object.content_attributes);
    } else if (object.content_type === 'survey_order') {
      return Task.buildSurveyOrderContent(object.content_attributes);
    } else if (object.content_type === 'generic_follow_up_order') {
      return Task.buildGenericFollowUpOrderContent(object.content_attributes);
    } else if (object.content_type === 'consult_order') {
      return Task.buildConsultOrderContent(object.content_attributes);
    } else if (object.content_type === 'procedure_order') {
      return Task.buildProcedureOrderContent(object.content_attributes);
    }
  }

  private static buildLabOrderContent(attributes): LabOrderContent {
    const content = new LabOrderContent();
    content.fastingRequired = attributes.fasting_needed;
    content.numberOfTests = attributes.tests_ordered.length;
    content.testsOrdered = attributes.tests_ordered;
    content.appointmentNeeded = attributes.appointment_needed;
    return content;
  }

  private static buildVisitFollowUpOrderContent(attributes): VisitFollowUpOrderContent {
    const content = new VisitFollowUpOrderContent();
    content.appointmentType = attributes.appointment_type_description.toLowerCase();
    content.visitReason = attributes.reason_details;
    content.visitWithProvider = attributes.visit_with_provider && Provider.fromApiV2(attributes.visit_with_provider);
    return content;
  }

  private static buildBasicFollowUpOrderContent(attributes): BasicFollowUpOrderContent {
    const content = new BasicFollowUpOrderContent();
    content.problem = attributes.problem.description;
    content.feedbackOptions = ['Worse', 'Same', 'Better', 'Great'];
    content.feedback = attributes.patient_feedback ? upperFirst(attributes.patient_feedback) : null;
    return content;
  }

  private static buildVaccineOrderContent(attributes): VaccineOrderContent {
    const content = new VaccineOrderContent();
    content.isAutomated = attributes.is_automated;
    content.name = attributes.name;
    content.step = attributes.step;
    content.walkin = attributes.walkin;
    content.isFluVaccine = attributes.is_flu_vaccine;
    content.information = content.isFluVaccine ? attributes.flu_task_display_details.information : null;
    content.buttonName = content.isFluVaccine ? attributes.flu_task_display_details.button_name : 'Book Visit';
    content.buttonLink = content.isFluVaccine ? attributes.flu_task_display_details.button_link : null;
    content.showNearbyLocations = content.isFluVaccine
      ? attributes.flu_task_display_details.show_nearby_locations
      : null;
    return content;
  }

  private static buildSurveyOrderContent(attributes): SurveyOrderContent {
    const content = new SurveyOrderContent();
    content.instructions = 'Please complete the following questionnaire.';
    content.surveyId = attributes.survey.id;
    return content;
  }

  private static buildGenericFollowUpOrderContent(attributes): GenericFollowUpOrderContent {
    const content = new GenericFollowUpOrderContent();
    content.question = attributes.question;
    return content;
  }

  private static buildConsultOrderContent(attributes): ConsultOrderContent {
    const content = new ConsultOrderContent();
    content.consultantDisplayName = attributes.consultant.display_name;
    content.consultantPhoneNumber = attributes.consultant.phone_number;
    content.consultantSpecialtyName = attributes.consultant.specialty_name;
    content.consultantFacilityName = attributes.consultant.facility_name;
    content.consultantAddress1 = attributes.consultant.address.address1;
    content.consultantAddress2 = attributes.consultant.address.address2;
    content.consultantCity = attributes.consultant.address.city;
    content.consultantState = attributes.consultant.address.state;
    content.consultantZip = attributes.consultant.address.zip;
    content.consultantLatitude = attributes.consultant.address.latitude;
    content.consultantLongitude = attributes.consultant.address.longitude;
    return content;
  }

  private static buildProcedureOrderContent(attributes): ProcedureOrderContent {
    const content = new ProcedureOrderContent();
    content.consultantDisplayName = attributes.consultant.display_name;
    content.consultantPhoneNumber = attributes.consultant.phone_number;
    content.consultantSpecialtyName = attributes.consultant.specialty_name;
    content.consultantFacilityName = attributes.consultant.facility_name;
    content.consultantAddress1 = attributes.consultant.address.address1;
    content.consultantAddress2 = attributes.consultant.address.address2;
    content.consultantCity = attributes.consultant.address.city;
    content.consultantState = attributes.consultant.address.state;
    content.consultantZip = attributes.consultant.address.zip;
    content.consultantLatitude = attributes.consultant.address.latitude;
    content.consultantLongitude = attributes.consultant.address.longitude;
    return content;
  }
}
