import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'om-patient-icon',
  templateUrl: './patient-icon.component.html',
})
export class PatientIconComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
