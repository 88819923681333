import each from 'lodash-es/each';
var AppointmentType = /** @class */ (function () {
    function AppointmentType() {
        this.selected = false;
        this.firstAppointmentSelected = false;
    }
    Object.defineProperty(AppointmentType.prototype, "id", {
        get: function () {
            return this.firstAppointmentSelected ? this.firstVisitAppointmentTypeId : this._id;
        },
        enumerable: true,
        configurable: true
    });
    AppointmentType.fromApiV2 = function (response) {
        var appointmentType = new AppointmentType();
        each(AppointmentType.fieldMapping, function (value, key) {
            appointmentType[key] = response[value];
        });
        appointmentType.hasAdditionalQuestion = appointmentType.firstVisitAppointmentTypeId != null;
        return appointmentType;
    };
    AppointmentType.prototype.toApiV2 = function () {
        var _this = this;
        var response = {};
        each(AppointmentType.fieldMapping, function (value, key) {
            response[value] = _this[key];
        });
        return response;
    };
    AppointmentType.fieldMapping = {
        _id: 'id',
        description: 'description',
        displayedDuration: 'displayed_duration',
        displayName: 'display_name',
        firstVisitAppointmentTypeId: 'first_visit_appointment_type_id',
        isSpecialty: 'is_specialty',
        position: 'position',
        firstAppointmentSelected: 'first_appointment_selected',
    };
    return AppointmentType;
}());
export { AppointmentType };
