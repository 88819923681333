import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppointmentService } from '../appointment/appointment.service';
import { SharedModule } from '../shared/shared.module';
import { AnalyticsService } from './analytics.service';
import { ApiHeaderService } from './api-header.service';
import { ApiService } from './api.service';
import { AuthGuardService } from './auth-guard.service';
import { AuthService } from './auth.service';
import { AwsService } from './aws.service';
import { ConfigService } from './config.service';
import { DateService } from './date.service';
import { DevRouteGuardService } from './dev-route-guard.service';
import { GraphqlService } from './graphql.service';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginService } from './login.service';
import { Login } from './login/login.component';
import { MarkdownService } from './markdown.service';
import { ProviderService } from './provider.service';
import { RegistrationCompleteGuardService } from './registration-complete-guard.service';
import { RollbarService } from './rollbar.service';
import { StorageService } from './storage.service';
import { TosService } from './tos.service';
import { TosInterstitialComponent } from './tos/tos-interstitial.component';
import { UserAlertService } from './user-alert.service';
import { UserService } from './user.service';
import { WindowService } from './window.service';
import { AccountActiveGuardService } from '@app/core/account-active-guard.service';
import { GuestGuardService } from '@app/core/guest-guard.service';
import { MembershipService } from '@app/core/membership.service';
import { AlreadyLoggedInGuardService } from '@app/core/already-logged-in-guard.service';
import { CorePlusPlanGuardService } from './core-plus-plan-guard.service';

@NgModule({
  declarations: [Login, LoginFormComponent, TosInterstitialComponent],
  imports: [CommonModule, FormsModule, SharedModule],
  providers: [
    AccountActiveGuardService,
    AlreadyLoggedInGuardService,
    AnalyticsService,
    ApiHeaderService,
    ApiService,
    AppointmentService,
    AuthGuardService,
    AuthService,
    AwsService,
    ConfigService,
    DateService,
    DevRouteGuardService,
    GraphqlService,
    GuestGuardService,
    LoginService,
    MarkdownService,
    MembershipService,
    CorePlusPlanGuardService,
    ProviderService,
    RegistrationCompleteGuardService,
    RollbarService,
    StorageService,
    TosService,
    UserAlertService,
    UserService,
    WindowService,
  ],
  exports: [],
})
export class CoreModule {}
