/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../login-form/login-form.component.ngfactory";
import * as i3 from "../login-form/login-form.component";
import * as i4 from "../auth.service";
import * as i5 from "../config.service";
import * as i6 from "../login.service";
import * as i7 from "../rollbar.service";
import * as i8 from "../membership.service";
import * as i9 from "../window.service";
import * as i10 from "./login.component";
import * as i11 from "@angular/platform-browser";
var styles_Login = [i0.styles];
var RenderType_Login = i1.ɵcrt({ encapsulation: 0, styles: styles_Login, data: {} });
export { RenderType_Login as RenderType_Login };
export function View_Login_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "login-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "login-box"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "col-sm-8 col-md-6 col-lg-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "om-login-form", [], null, null, null, i2.View_LoginFormComponent_0, i2.RenderType_LoginFormComponent)), i1.ɵdid(11, 114688, null, 0, i3.LoginFormComponent, [i4.AuthService, i5.ConfigService, i6.LoginService, i7.RollbarService, i8.MembershipService, i9.WindowService], null, null), (_l()(), i1.ɵted(-1, null, [" "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { _ck(_v, 11, 0); }, null); }
export function View_Login_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "om-login", [], null, null, null, View_Login_0, RenderType_Login)), i1.ɵdid(1, 114688, null, 0, i10.Login, [i11.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginNgFactory = i1.ɵccf("om-login", i10.Login, View_Login_Host_0, {}, {}, []);
export { LoginNgFactory as LoginNgFactory };
