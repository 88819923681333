/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./expired-membership-banner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./expired-membership-banner.component";
import * as i7 from "../../core/config.service";
import * as i8 from "../../core/membership.service";
var styles_ExpiredMembershipBannerComponent = [i0.styles];
var RenderType_ExpiredMembershipBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExpiredMembershipBannerComponent, data: {} });
export { RenderType_ExpiredMembershipBannerComponent as RenderType_ExpiredMembershipBannerComponent };
function View_ExpiredMembershipBannerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 9, "ngb-alert", [["class", "text-center alert"], ["role", "alert"], ["type", "info"]], [[2, "alert-dismissible", null]], [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = ((_co.closed = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NgbAlert_0, i2.RenderType_NgbAlert)), i1.ɵdid(3, 638976, null, 0, i3.NgbAlert, [i3.NgbAlertConfig, i1.Renderer2, i1.ElementRef], { type: [0, "type"] }, { close: "close" }), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your membership has expired."])), (_l()(), i1.ɵted(-1, 0, [" Please\n      "])), (_l()(), i1.ɵeld(8, 0, null, 0, 2, "a", [["routerLink", "/membership/renew"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["update your credit card"])), (_l()(), i1.ɵted(-1, 0, [" to view your messages and tasks.\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var currVal_1 = "info"; _ck(_v, 3, 0, currVal_1); var currVal_4 = "/membership/renew"; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).dismissible; _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 9).target; var currVal_3 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_2, currVal_3); }); }
function View_ExpiredMembershipBannerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 8, "ngb-alert", [["class", "text-center alert"], ["role", "alert"], ["type", "info"]], [[2, "alert-dismissible", null]], [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = ((_co.closed = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NgbAlert_0, i2.RenderType_NgbAlert)), i1.ɵdid(3, 638976, null, 0, i3.NgbAlert, [i3.NgbAlertConfig, i1.Renderer2, i1.ElementRef], { type: [0, "type"] }, { close: "close" }), (_l()(), i1.ɵted(-1, 0, ["\n      Your employer-sponsored membership "])), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵted(-1, 0, [". "])), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Re-activate now."])), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var currVal_1 = "info"; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).dismissible; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.formattedExpiryString; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.b2bLink; _ck(_v, 8, 0, currVal_3); }); }
function View_ExpiredMembershipBannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExpiredMembershipBannerComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExpiredMembershipBannerComponent_3)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.membership.isSelfPaid() && _co.membership.isExpired()); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.membership.isB2b() && (_co.membership.isExpired() || _co.membershipExpiresSoon)); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_ExpiredMembershipBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExpiredMembershipBannerComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.closed && _co.membership); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ExpiredMembershipBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "om-expired-membership-banner", [], null, null, null, View_ExpiredMembershipBannerComponent_0, RenderType_ExpiredMembershipBannerComponent)), i1.ɵdid(1, 245760, null, 0, i6.ExpiredMembershipBannerComponent, [i7.ConfigService, i8.MembershipService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExpiredMembershipBannerComponentNgFactory = i1.ɵccf("om-expired-membership-banner", i6.ExpiredMembershipBannerComponent, View_ExpiredMembershipBannerComponent_Host_0, {}, {}, []);
export { ExpiredMembershipBannerComponentNgFactory as ExpiredMembershipBannerComponentNgFactory };
