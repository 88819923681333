import { share, finalize, onErrorResumeNext, map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { WindowService } from './window.service';

@Injectable()
export class LoginService {
  constructor(private apiService: ApiService, private authService: AuthService, private windowService: WindowService) {}

  login(username, password) {
    const params = {
      username: username,
      password: password,
      grant_type: 'password',
    };

    return this.apiService.post('/oauth/token', params, true).pipe(
      map((response: any) => {
        if (!response.error) {
          this.authService.setToken(response.access_token);
        }
        return response;
      }),
    );
  }

  logout() {
    const request = this.apiService.delete('/api/v2/patient/logout').pipe(
      onErrorResumeNext(),
      finalize(() => {
        this.destroyTokenAndRedirect();
      }),
      share(),
    );
    request.subscribe();
    return request;
  }

  private destroyTokenAndRedirect() {
    this.authService.deleteToken();
    this.windowService.redirect('/login');
  }
}
