import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { UserAlert } from '../shared/user-alert';

@Injectable()
export class UserAlertService {
  static DISMISSABLE_ACTIONS = {
    survey: { 1: 'health_history_survey', 2: 'health_history_survey' },
  };

  constructor(private apiService: ApiService) {}

  getAlert(key: string) {
    return this.apiService.get(`/api/v2/user_alerts/for_key?alert_key=${key}`).pipe(
      map((response: any) => {
        if (response.user_alert && response.user_alert.length) {
          return UserAlert.fromApiV2(response.user_alert[0]);
        } else {
          return null;
        }
      }),
    );
  }

  dismissAlert(key: string) {
    const params = { alert_key: key };
    const request = this.apiService.post('/api/v2/user_alerts/dismiss_alert', params);
    request.subscribe();
    return request;
  }

  dismissIfDismissable(object: string, id: number) {
    const alertKey = this.getDismissableActionKey(object, id);
    if (alertKey) {
      this.dismissAlert(alertKey);
    }
  }

  private getDismissableActionKey(object: string, id: number): string {
    if (object in UserAlertService.DISMISSABLE_ACTIONS) {
      return UserAlertService.DISMISSABLE_ACTIONS[object][id];
    }
  }
}
