import { ApiHeaderService } from './api-header.service';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private apiHeaderService: ApiHeaderService,
    private config: ConfigService,
    private authService: AuthService,
  ) {}

  get(path: string, ignoreUnauthorized: boolean = false, body: any = {}) {
    return this.http
      .get(`${this.config.json.apiServer}${path}`, {
        headers: this.apiHeaderService.headers,
        params: body,
        withCredentials: true,
      })
      .pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
        catchError((error, caught) => {
          return this.handleHttpError(error, ignoreUnauthorized);
        }),
      );
  }

  getCheckIfChanged(path: string) {
    this.apiHeaderService.setCacheType();
    const request = this.get(path);
    this.apiHeaderService.resetCacheType();
    return request;
  }

  post(path: string, body: any, ignoreUnauthorized: boolean = false) {
    return this.http
      .post(`${this.config.json.apiServer}${path}`, body, {
        headers: this.apiHeaderService.headers,
        withCredentials: true,
      })
      .pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
        catchError((error, caught) => {
          return this.handleHttpError(error, ignoreUnauthorized);
        }),
      );
  }

  patch(path: string, body: any, ignoreUnauthorized: boolean = false) {
    return this.http
      .patch(`${this.config.json.apiServer}${path}`, body, {
        headers: this.apiHeaderService.headers,
        withCredentials: true,
      })
      .pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
        catchError((error, caught) => {
          return this.handleHttpError(error, ignoreUnauthorized);
        }),
      );
  }

  delete(path: string, body: any = {}, ignoreUnauthorized: boolean = false) {
    return this.http
      .delete(`${this.config.json.apiServer}${path}`, {
        headers: this.apiHeaderService.headers,
        params: body,
        withCredentials: true,
      })
      .pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
        catchError((error, caught) => {
          return this.handleHttpError(error, ignoreUnauthorized);
        }),
      );
  }

  private handleHttpError(error, ignoreUnauthorized) {
    if (error.status === 401 && !ignoreUnauthorized) {
      this.authService.deleteToken();
      window.location.href = '/login';
      return observableOf();
    }
    return observableThrowError(error);
  }
}
