import { catchError, filter, map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { BehaviorSubject, Subject, throwError as observableThrowError } from 'rxjs';
import { Membership } from './membership';
import { PusherService } from '../shared/pusher.service';
import debounce from 'lodash-es/debounce';
var MembershipService = /** @class */ (function () {
    function MembershipService(apiService, pusherService) {
        this.apiService = apiService;
        this.pusherService = pusherService;
        this._membership$ = new BehaviorSubject(null);
        this.membership$ = this._membership$.asObservable().pipe(filter(function (membership) { return membership != null; }));
        this._debouncedGetMembership = debounce(this._getMembership.bind(this), 1000, {
            leading: true,
        });
    }
    MembershipService.prototype.getMembership = function (force) {
        if (force === void 0) { force = false; }
        if (force) {
            this._getMembership();
        }
        else if (this._membership$.getValue() == null) {
            this._debouncedGetMembership();
        }
    };
    MembershipService.prototype.updateMembership = function (stripeTokenId, coupon) {
        var _this = this;
        var stripeResponse = new Subject();
        var params = {
            stripe_token: stripeTokenId,
            callback_type: 'pusher',
        };
        if (coupon && coupon.id) {
            params['stripe_coupon_id'] = coupon.id;
        }
        this.apiService
            .patch('/api/v2/patient/membership', params)
            .subscribe(function (_a) {
            var channel_name = _a.channel_name, event_name = _a.event_name;
            var channel = _this.pusherService.subscribeTo(channel_name);
            channel.bind(event_name, function (response) {
                if (!response.error) {
                    stripeResponse.next(response);
                }
                else {
                    stripeResponse.error(response.error);
                }
                channel.unbind(event_name);
                stripeResponse.complete();
            });
        });
        return stripeResponse;
    };
    MembershipService.prototype.deactivateMembership = function (deactivateReasonId, deactivateReasonNotes) {
        return this.apiService
            .post('/api/v2/patient/membership/deactivate_recurring_billing', {
            deactivate_recurring_billing_reason: deactivateReasonId,
            deactivate_recurring_billing_notes: deactivateReasonNotes,
        })
            .pipe(catchError(function (error) { return observableThrowError(error); }));
    };
    MembershipService.prototype.reactivateMembership = function () {
        return this.apiService.post('/api/v2/patient/membership/reactivate_recurring_billing', {});
    };
    MembershipService.prototype.updateCreditCard = function (stripeTokenId) {
        var _this = this;
        var stripeResponse = new Subject();
        var params = {
            stripe_token: stripeTokenId,
        };
        this.apiService
            .patch('/api/v2/patient/memberships/credit_card', params)
            .subscribe(function (_a) {
            var channel_name = _a.channel_name, event_name = _a.event_name;
            var channel = _this.pusherService.subscribeTo(channel_name);
            channel.bind(event_name, function (response) {
                if (response.success) {
                    _this.getMembership(true);
                    stripeResponse.next(response);
                }
                else {
                    stripeResponse.error(response.message);
                }
                channel.unbind(event_name);
                stripeResponse.complete();
            });
        });
        return stripeResponse;
    };
    MembershipService.prototype._getMembership = function (ignoreUnauthorized) {
        var _this = this;
        if (ignoreUnauthorized === void 0) { ignoreUnauthorized = false; }
        var request = this.apiService
            .get('/api/v2/patient/membership', ignoreUnauthorized)
            .pipe(map(function (membership) { return Membership.fromApiV2(membership); }));
        request.subscribe(function (membership) {
            _this._membership$.next(membership);
        });
        return request;
    };
    MembershipService.prototype.getMembershipWithRequest = function () {
        return this._getMembership(true);
    };
    return MembershipService;
}());
export { MembershipService };
