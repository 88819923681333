<div
  class="profile-bubble"
  [class.small]="size == 'small'"
  [class.large]="size == 'large'"
  [class.extra-large]="size == 'extra-large'"
  [class.semi-large]="size == 'semi-large'"
>
  <div [hidden]="status !== profileBubbleStates.DANGER">
    <span *ngIf="size != 'large' && size != 'extra-large'" class="decorator">
      <svg><circle class="danger" cx="26" cy="26" r="24"></circle></svg>
    </span>
    <span *ngIf="size == 'large'" class="decorator">
      <svg><circle class="danger" cx="31" cy="31" r="29"></circle></svg>
    </span>
    <span *ngIf="size == 'extra-large'" class="decorator">
      <svg><circle class="danger" cx="93.5" cy="93.5" r="91.5"></circle></svg>
    </span>
    <img src="assets/images/icons/error.svg" class="danger-icon" alt="" />
  </div>
  <div [hidden]="status !== profileBubbleStates.PROCESSING">
    <om-spinner class="decorator" [size]="size"></om-spinner>
  </div>
  <div class="bubble combo-{{ colorCombo }}">
    <div [ngStyle]="profileImageStyles()" class="profile-image" *ngIf="source"></div>
    <img class="profile-image hidden" [src]="source" *ngIf="source" (error)="fallbackToInitials($event)" alt="" />
    <div class="initials" [hidden]="source">{{ initials }}</div> <div class="shadow"></div>
  </div>
</div>
