import { Component, OnInit } from '@angular/core';
import { FlashService } from '../flash.service';

@Component({
  selector: 'om-flash',
  templateUrl: './flash.component.html',
  styleUrls: ['./flash.component.scss'],
})
export class FlashComponent implements OnInit {
  errors: string[] = [];
  alerts: string[] = [];
  notices: string[] = [];

  constructor(private flashService: FlashService) {}

  ngOnInit() {
    this.flashService.messages$.subscribe(data => {
      this.errors = data['error'];
      this.alerts = data['alert'];
      this.notices = data['notice'];
    });
  }
}
