import { AnalyticsService } from './core/analytics.service';
import { Component, OnInit } from '@angular/core';
import { WindowService } from './core/window.service';

@Component({
  selector: 'om-app',
  templateUrl: './app.component.html',
  providers: [],
})
// Component controller
export class MyOneApp implements OnInit {
  constructor(private windowService: WindowService, private analyticsService: AnalyticsService) {}

  ngOnInit() {
    this.windowService.appInstallChoiceStream.subscribe(choiceResult => {
      if (choiceResult.outcome === 'dismissed') {
        this.analyticsService.appInstallBannerDismissed();
      } else {
        this.analyticsService.appInstallBannerAccepted();
      }
    });
  }
}
