import { filter } from 'rxjs/operators';
import { AppointmentCancellationReason } from '@app/appointment/appointment-cancellation-reason';
import { AppointmentType } from './appointment-type';
import { BehaviorSubject } from 'rxjs';
import { FromAppointment } from './from-appointment';
import { Provider } from '../shared/provider';
import { ProviderType, ProviderTypeUtil } from './provider-type';
import { SelectedReason } from './selected-reason';
import { ServiceArea } from '../shared/service-area';
import { Task, VisitFollowUpOrderContent } from '../shared/task';
export var PROVIDER_TYPE_DEFAULT = ProviderType.anyAvailableProvider;
function appointmentTypeIdToSurveyId(appointmentTypeId) {
    var appointmentSurveyMap = new Map([
        [176 /* IUDInsertion */, 17 /* IUDInsertion */],
        [177 /* IUDRemoval */, 18 /* IUDRemoval */],
        [178 /* IUDReplacement */, 17 /* IUDInsertion */],
    ]);
    return appointmentSurveyMap.get(appointmentTypeId);
}
var AppointmentBookingState = /** @class */ (function () {
    function AppointmentBookingState() {
        this._selectedServiceArea$ = new BehaviorSubject(null);
        this.selectedServiceArea$ = this._selectedServiceArea$
            .asObservable()
            .pipe(filter(function (sa) { return sa != null; }));
        this._isAppointmentRecommended = new BehaviorSubject(false);
        this.isAppointmentRecommended$ = this._isAppointmentRecommended.asObservable();
        this.selectedReasons = [];
        this.selectedProviderType = ProviderType.anyAvailableProvider;
    }
    Object.defineProperty(AppointmentBookingState.prototype, "associatedTask", {
        get: function () {
            return this._associatedTask;
        },
        set: function (task) {
            this._associatedTask = task;
            if (task.content instanceof VisitFollowUpOrderContent) {
                this.setSelectedProvider(task.content.visitWithProvider);
                this.setSelectedProviderType(ProviderType.specificProvider);
                if (task.content.visitReason) {
                    this.selectedReasons = [new SelectedReason(task.content.visitReason)];
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppointmentBookingState.prototype, "bookingParams", {
        get: function () {
            var params = {
                appointment: {
                    appointment_cancellation_reason_id: this.getCancellationReasonId(),
                    appointment_type_id: this.getAppointmentTypeId(),
                    from_appointment_id: this.getFromAppointmentId(),
                    patient_survey_id: this.createdPatientSurveyId,
                    reason: this.customReasons(),
                    send_sms: this.sendSms,
                    sms_number: this.sendSms ? this.phoneNumber : null,
                    visit_reason_categories: this.getCategorizedReasons(),
                },
            };
            if (this.associatedTask) {
                params['appointment']['booked_from'] = {
                    resource: this.associatedTask.type,
                    id: this.associatedTask.id,
                };
            }
            return params;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppointmentBookingState.prototype, "trackableProperties", {
        get: function () {
            var properties = {};
            if (!!this.selectedReasons) {
                properties.reason = this.selectedReasons.map(function (sr) { return sr.toApiV2(); });
                properties.reason_count = this.selectedReasons.length;
                properties.is_routed = this.getIsAppointmentRecommended();
            }
            if (!!this.appointmentType) {
                properties.appointment_type = this.appointmentType.displayName;
            }
            var selectedServiceArea = this.getSelectedServiceArea();
            if (!!selectedServiceArea) {
                properties.service_area = selectedServiceArea.name;
            }
            if (this.selectedProviderType != null) {
                properties.provider_category = ProviderTypeUtil.getTypeDescription(this.selectedProviderType);
            }
            var provider = this.selectedProvider;
            if (!!provider) {
                properties.provider_id = provider.id;
                properties.provider_category = properties.provider_category || 'Specific Provider: ' + provider.preparedName;
            }
            if (this.sendSms) {
                properties.send_sms = true;
            }
            if (this.getCancellationReasonId()) {
                properties.reason_for_cancel = this.cancellationReason.displayReason;
            }
            return properties;
        },
        enumerable: true,
        configurable: true
    });
    AppointmentBookingState.fromApiV2 = function (response) {
        var appointmentBookingState = new AppointmentBookingState();
        var bookingStateResponse = response['appointment_booking_state'];
        if (!bookingStateResponse) {
            return appointmentBookingState;
        }
        appointmentBookingState.selectedReasons = bookingStateResponse['selected_reasons'].map(function (sr) {
            return SelectedReason.fromApiV2(sr);
        });
        appointmentBookingState.setSelectedServiceArea(new ServiceArea(bookingStateResponse['service_area']));
        if (bookingStateResponse['provider']) {
            appointmentBookingState.setSelectedProvider(Provider.fromApiV2(bookingStateResponse['provider']));
            if (bookingStateResponse['task']) {
                var task = new Task();
                task.id = bookingStateResponse['task'].id;
                appointmentBookingState.associatedTask = task;
            }
        }
        appointmentBookingState.setSelectedProviderType(bookingStateResponse['provider_type']);
        appointmentBookingState.setSelectedAppointmentType(AppointmentType.fromApiV2(bookingStateResponse['appointment_type']));
        appointmentBookingState.setIsAppointmentRecommended(bookingStateResponse['is_appointment_recommended']);
        if (bookingStateResponse['from_appointment']) {
            appointmentBookingState.setFromAppointment(FromAppointment.fromApiV2(bookingStateResponse['from_appointment']));
        }
        if (bookingStateResponse['appointment_cancellation_reason']) {
            appointmentBookingState.setCancellationReason(AppointmentCancellationReason.fromApiV2(bookingStateResponse['appointment_cancellation_reason']));
        }
        return appointmentBookingState;
    };
    AppointmentBookingState.prototype.setIsAppointmentRecommended = function (isAppointmentRecommended) {
        this._isAppointmentRecommended.next(isAppointmentRecommended);
    };
    AppointmentBookingState.prototype.getIsAppointmentRecommended = function () {
        return this._isAppointmentRecommended.value;
    };
    AppointmentBookingState.prototype.setSelectedServiceArea = function (serviceArea) {
        this._selectedServiceArea$.next(serviceArea);
    };
    AppointmentBookingState.prototype.getSelectedServiceArea = function () {
        return this._selectedServiceArea$.value;
    };
    AppointmentBookingState.prototype.setSelectedProviderType = function (providerType) {
        var ensureProviderType = Object.values(ProviderType).includes(providerType)
            ? providerType
            : PROVIDER_TYPE_DEFAULT;
        this.selectedProviderType = ensureProviderType;
    };
    AppointmentBookingState.prototype.setSelectedProvider = function (provider) {
        this.selectedProvider = provider;
    };
    AppointmentBookingState.prototype.resetProviderSelection = function () {
        this.selectedProviderType = PROVIDER_TYPE_DEFAULT;
        this.selectedProvider = null;
    };
    AppointmentBookingState.prototype.getSelectedProviderId = function () {
        if (this.selectedProvider) {
            return this.selectedProvider.id;
        }
    };
    AppointmentBookingState.prototype.setSelectedAppointmentType = function (appointmentType, resetSurvey) {
        if (resetSurvey === void 0) { resetSurvey = true; }
        this.appointmentType = appointmentType;
        if (resetSurvey) {
            this.resetSurvey();
        }
    };
    AppointmentBookingState.prototype.setCancellationReason = function (cancellationReason) {
        this.cancellationReason = cancellationReason;
    };
    AppointmentBookingState.prototype.getCancellationReasonId = function () {
        return this.cancellationReason ? this.cancellationReason.id : undefined;
    };
    AppointmentBookingState.prototype.setFromAppointment = function (fromAppointment) {
        this.fromAppointment = fromAppointment;
    };
    AppointmentBookingState.prototype.getFromAppointmentId = function () {
        return this.fromAppointment ? this.fromAppointment.id : undefined;
    };
    AppointmentBookingState.prototype.resetSurvey = function () {
        this.requiredSurveyId = appointmentTypeIdToSurveyId(this.getAppointmentTypeId());
        this.createdPatientSurveyId = undefined;
        this.surveyData = undefined;
    };
    AppointmentBookingState.prototype.hasReasons = function () {
        return this.selectedReasons.length > 0;
    };
    AppointmentBookingState.prototype.serviceAreaSet = function () {
        return this.getSelectedServiceArea() != null;
    };
    AppointmentBookingState.prototype.providerTypeSelected = function () {
        return this.selectedProviderType != null;
    };
    AppointmentBookingState.prototype.appointmentTypeSelected = function () {
        return this.appointmentType != null;
    };
    AppointmentBookingState.prototype.bookingStateComplete = function () {
        return (this.hasReasons() &&
            this.providerTypeSelected() &&
            this.serviceAreaSet() &&
            this.appointmentTypeSelected() &&
            (!this.requiredSurveyId || (this.requiredSurveyId && !!this.surveyData)));
    };
    AppointmentBookingState.prototype.selectedReasonsString = function (withWhitespace) {
        if (withWhitespace === void 0) { withWhitespace = false; }
        var delimiter = withWhitespace ? ', ' : ',';
        return this.selectedReasons.map(function (sr) { return sr.text; }).join(delimiter);
    };
    AppointmentBookingState.prototype.toApiV2 = function () {
        var api_v2_data = {
            appointment_booking_state: {
                appointment_cancellation_reason: this.getCancellationReasonId() ? this.cancellationReason.toApiV2() : null,
                appointment_type: this.appointmentType.toApiV2(),
                is_appointment_recommended: this.getIsAppointmentRecommended(),
                provider: this.selectedProvider != null ? this.selectedProvider.toApiV2() : null,
                provider_type: this.selectedProviderType,
                selected_reasons: this.selectedReasons.map(function (sr) { return sr.toApiV2(); }),
                service_area: this.getSelectedServiceArea().toApiV2(),
            },
            appointment_category: ProviderTypeUtil.getTypeCategory(this.selectedProviderType),
            appointment_reason: this.customReasons(),
            appointment_type_id: this.getAppointmentTypeId(),
            provider_id: this.getSelectedProviderId(),
            service_area_id: this.getSelectedServiceArea().id,
            task: this.associatedTask != null ? this.associatedTask.id : null,
            visit_reason_categories: this.getCategorizedReasons(),
        };
        if (this.fromAppointment) {
            api_v2_data['appointment_booking_state']['from_appointment'] = this.fromAppointment.toApiV2();
        }
        return api_v2_data;
    };
    AppointmentBookingState.prototype.customReasons = function () {
        var _this = this;
        var fullCustomReasons = this.selectedReasons
            .filter(function (sr) { return sr.visitReasonCategory == null; })
            .map(function (sr) { return _this.trimReasonAndRemoveTrailingPunctuation(sr.text.trim()); })
            .join(', ');
        return this.exceedsMaxLength(fullCustomReasons) ? fullCustomReasons.substring(0, 125) + "..." : fullCustomReasons;
    };
    AppointmentBookingState.prototype.exceedsMaxLength = function (reason) {
        return reason.length > 128;
    };
    AppointmentBookingState.prototype.trimReasonAndRemoveTrailingPunctuation = function (reason) {
        return /\W/.test(reason.slice(-1)) ? reason.slice(0, -1).trim() : reason;
    };
    AppointmentBookingState.prototype.getCategorizedReasons = function () {
        return this.selectedReasons
            .filter(function (sr) { return sr.visitReasonCategory != null; })
            .map(function (sr) { return sr.visitReasonCategory.toApiV2(); });
    };
    AppointmentBookingState.prototype.getAppointmentTypeId = function () {
        return this.appointmentType ? this.appointmentType.id : undefined;
    };
    AppointmentBookingState.prototype.updateRecommendedAppointmentType = function (recommendedAppointmentType) {
        if (!recommendedAppointmentType) {
            this.setSelectedAppointmentType(null);
            this.setIsAppointmentRecommended(false);
        }
        else {
            this.setSelectedAppointmentType(recommendedAppointmentType);
            this.setIsAppointmentRecommended(true);
        }
    };
    return AppointmentBookingState;
}());
export { AppointmentBookingState };
