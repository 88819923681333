import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ApiHeaderService {
  headers: HttpHeaders;

  constructor() {
    this.headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  setCacheType() {
    this.headers = this.headers.set('Cache-Control', 'no-cache');
  }

  resetCacheType() {
    this.headers = this.headers.delete('Cache-Control');
  }

  setAccessToken(token) {
    this.headers = this.headers.set('Authorization', `Bearer ${token}`);
  }

  revokeAccessToken() {
    this.headers = this.headers.delete('Authorization');
  }
}
