import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TermsOfService } from './terms-of-service';
import { GraphqlService } from './graphql.service';
import { FlashService } from '../shared/flash.service';
import { Nonmember, User } from '@app/shared/user';
import { of as observableOf, throwError as observableThrowError } from 'rxjs';
import { ApiService } from '@app/core/api.service';
import { catchError, map, tap } from 'rxjs/operators';
import forEach from 'lodash-es/forEach';

export interface Signer {
  type: string;
  id: number;
}

export interface DocumentSigner {
  email: string;
  firstName: string;
  lastName: string;
  id: number;
  type: string;
}

@Injectable()
export class TosService {
  private _tos$ = new Subject<TermsOfService[]>();
  tos$: Observable<TermsOfService[]> = this._tos$.asObservable();

  constructor(
    private flashService: FlashService,
    private graphQlService: GraphqlService,
    private apiService: ApiService,
  ) {}

  getTos() {
    this.graphQlService.postQuery(this.docFetchQuery()).subscribe({
      next: data => {
        this._tos$.next(
          data.legal_documents.map(doc => {
            return TermsOfService.fromGraphql(doc);
          }),
        );
      },
      error: data => {
        this.flashService.addFlashMessage(
          // tododo: add logout link? allow flash service to take html? pull out errors/messages into a yaml file?
          "We've encountered an issue submitting your request. Please log out and try again.",
          'error',
        );
        return;
      },
    });
  }

  setLegalGuardianForBeneficiaryWhenLoggedInAsAdministrator(beneficiary) {
    const body = {
      relationship: { beneficiary_id: beneficiary.id },
    };
    return this.setRelationship(body);
  }

  setLegalGuardianForBeneficiaryWhenLoggedInAsBeneficiary(administrator) {
    const body = {
      relationship: { administrator_id: administrator.id },
    };
    return this.setRelationship(body);
  }

  private setRelationship(body) {
    return this.apiService
      .post('/api/v2/pediatric/current_patient/relationship', body)
      .pipe(catchError(error => observableThrowError(error)));
  }

  signDocument(doc) {
    const query = this.docSignatureQuery();
    const vars = {
      type: doc.type,
    };
    return this.graphQlService.postQuery(query, vars, true);
  }

  private docFetchQuery() {
    return `legal_documents {
      id
      type
      detail_hint
      button_hint
      text
      signed
    }`;
  }

  private docSignatureQuery() {
    return `SignLegalDocument($type:String!) {
      sign_legal_document(input:{type: $type}) {
        legal_document {
          type
          signed
        }
      }
    }`;
  }

  getTosFor(beneficiary: User) {
    this.apiService
      .get(`/api/v2/patient/administrator/beneficiaries/${beneficiary.id}/tos.json`)
      .subscribe((data: []) => this.setTos(data));
  }

  getTosForChild() {
    this.apiService.get(`/api/v2/patient/beneficiary/tos.json`).subscribe((data: []) => this.setTos(data));
  }

  private setTos(data: []) {
    const tos = data.map(doc => TermsOfService.fromGraphql(doc));
    this._tos$.next(tos);
  }

  signForBeneficiaryWhenLoggedInAsAdministrator(doc: TermsOfService, beneficiary: User) {
    return this.apiService.post(`/api/v2/patient/administrator/beneficiaries/${beneficiary.id}/tos_signatures.json`, {
      tos: { type: doc.type, version: doc.version },
      tos_signature: { user_id: beneficiary.id },
    });
  }

  signForBeneficiaryWhenLoggedInAsBeneficiary(doc: TermsOfService, beneficiary: User, signer: Signer) {
    return this.apiService.post('/api/v2/patient/beneficiary/tos_signatures.json', {
      tos: { type: doc.type, version: doc.version },
      tos_signature: { user_id: beneficiary.id, signer_id: signer.id, signer_type: signer.type },
    });
  }
}
