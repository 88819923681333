import { Injectable } from '@angular/core';

declare const marked;

@Injectable()
export class MarkdownService {
  constructor() {
    const renderer = new marked.Renderer();

    renderer.blockquote = this.noop;
    renderer.code = this.noop;
    renderer.codespan = this.noop;
    renderer.heading = this.noop;

    marked.setOptions({
      breaks: true,
      renderer: renderer,
      sanitize: true,
      tables: false,
    });
  }

  convert(text: string): string {
    return marked(text);
  }

  private noop(text: string): string {
    return text;
  }
}
