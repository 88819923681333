import { AfterContentInit, Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'om-svg',
  providers: [],
  template: `
    <span class="inline-svg" [innerHTML]="inlineSvg"></span>
  `,
})
export class Svg implements AfterContentInit {
  static PATHS = {
    appointment: 'assets/images/icons/tasks/ico-button-appointment.svg',
    arrow_right: 'assets/images/icons/icon-arrow-right.svg',
    back: 'assets/images/icons/icon-back.svg',
    checkmark: 'assets/images/icons/icon-checkmark.svg',
    next: 'assets/images/icons/icon-next.svg',
    next_no_circle: 'assets/images/icons/next.svg',
    sendMessage: 'assets/images/icons/tasks/ico-button-message.svg',
    show_password: 'assets/images/icons/icon-show-password.svg',
    tos_checkmark: 'assets/images/icons/icon-checkmark-tos.svg',
  };

  @Input() image: string;

  inlineSvg: SafeHtml;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
    this.inlineSvg = null;
  }

  ngAfterContentInit() {
    if (!this.image) {
      return;
    }

    const url = Svg.PATHS[this.image] || `assets/images/icons/${this.image}.svg`;

    this.http.get(url, { responseType: 'text' }).subscribe(response => {
      this.inlineSvg = this.sanitizer.bypassSecurityTrustHtml(response);
    });
  }
}
