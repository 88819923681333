import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { catchError, map } from 'rxjs/operators';
import { combineLatest, of as observableOf } from 'rxjs';
import { ConfigService } from './config.service';
import { MembershipService } from './membership.service';
import { TosService } from './tos.service';
import { WindowService } from './window.service';
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(authService, config, membershipService, router, tosService, windowService) {
        this.authService = authService;
        this.config = config;
        this.membershipService = membershipService;
        this.router = router;
        this.tosService = tosService;
        this.windowService = windowService;
    }
    AuthGuardService.prototype.canActivate = function (route, state) {
        var _this = this;
        if (this.authService.isTokenBlank()) {
            return this.handleUnauthenticated(state.url);
        }
        var authCheckStream = combineLatest(this.membershipService.getMembershipWithRequest(), this.tosService.tos$);
        this.tosService.getTos();
        return authCheckStream.pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), membership = _b[0], termsOfService = _b[1];
            var unsignedDocs = termsOfService.filter(function (doc) {
                return !doc.signed;
            });
            if (membership.isPending()) {
                _this.windowService.redirect(_this.config.json.myoneServer + "/pt/patient/login");
                return false;
            }
            else if (unsignedDocs.length) {
                _this.authService.setAttemptedPath(state.url);
                _this.router.navigate(['terms-of-service'], { replaceUrl: true });
                return false;
            }
            else {
                return true;
            }
        }), catchError(function (error) {
            return _this.handleUnauthenticated(state.url);
        }));
    };
    AuthGuardService.prototype.handleUnauthenticated = function (url) {
        this.authService.setAttemptedPath(url);
        this.router.navigate(['/login'], { replaceUrl: true });
        return observableOf(false);
    };
    return AuthGuardService;
}());
export { AuthGuardService };
