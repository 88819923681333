/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./svg-copy-icon.component";
var styles_SvgCopyIcon = [];
var RenderType_SvgCopyIcon = i0.ɵcrt({ encapsulation: 2, styles: styles_SvgCopyIcon, data: {} });
export { RenderType_SvgCopyIcon as RenderType_SvgCopyIcon };
export function View_SvgCopyIcon_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, ":svg:svg", [["fill", "#fff"], ["height", "100%"], ["viewBox", "0 0 24 24"], ["width", "100%"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(2, 0, null, null, 0, ":svg:rect", [["height", "16"], ["rx", "2"], ["stroke", "#777777"], ["width", "11"], ["x", "4"], ["y", "6"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(4, 0, null, null, 0, ":svg:rect", [["height", "16"], ["rx", "2"], ["stroke", "#777777"], ["width", "11"], ["x", "8"], ["y", "2"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, null); }
export function View_SvgCopyIcon_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "om-svg-copy-icon", [], null, null, null, View_SvgCopyIcon_0, RenderType_SvgCopyIcon)), i0.ɵdid(1, 49152, null, 0, i1.SvgCopyIcon, [], null, null)], null, null); }
var SvgCopyIconNgFactory = i0.ɵccf("om-svg-copy-icon", i1.SvgCopyIcon, View_SvgCopyIcon_Host_0, {}, {}, []);
export { SvgCopyIconNgFactory as SvgCopyIconNgFactory };
