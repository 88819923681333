import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'om-close-x',
  templateUrl: './close-x.component.html',
  styleUrls: ['./close-x.component.scss'],
})
export class CloseXComponent implements OnInit {
  @Input() stroke = '#343434';
  @Input() fill = '#F7F6F4';

  @Input() width = 36;
  @Input() height = 36;

  constructor() {}

  ngOnInit() {}
}
