import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
import { Query } from 'apollo-angular';
var FeatureFlagGraphQL = /** @class */ (function (_super) {
    tslib_1.__extends(FeatureFlagGraphQL, _super);
    function FeatureFlagGraphQL() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.document = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n    query($name: String!, $code: String!) {\n      featureFlag(name: $name) {\n        name\n        enabledForDiscountCode(code: $code)\n      }\n    }\n  "], ["\n    query($name: String!, $code: String!) {\n      featureFlag(name: $name) {\n        name\n        enabledForDiscountCode(code: $code)\n      }\n    }\n  "])));
        return _this;
    }
    return FeatureFlagGraphQL;
}(Query));
export { FeatureFlagGraphQL };
var templateObject_1;
