import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MembershipService } from '@app/core/membership.service';
import { Membership } from '@app/core/membership';
import { ConfigService } from '@app/core/config.service';

@Injectable()
export class GuestGuardService implements CanActivate {
  constructor(private membershipService: MembershipService, private config: ConfigService) {}

  canActivate(): boolean | Observable<boolean> {
    this.membershipService.getMembership();

    return this.membershipService.membership$.pipe(
      map((membership: Membership) => {
        if (!membership.isGuest()) {
          return true;
        } else {
          window.location.href = `${this.config.json.myoneServer}/pt/guest`;
          return false;
        }
      }),
    );
  }
}
