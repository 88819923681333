import { SvgWelcomeToOfficeImageComponent } from './svg/images/svg-welcome-to-office-image/svg-welcome-to-office-image.component';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ConfigService } from '../core/config.service';

import {
  AddressAutocompleteComponent,
  AddressBasicInputComponent,
  AddressOptionInputComponent,
  AddToCalendarComponent,
  AppStoreRedirectService,
  CardTableComponent,
  CardTableColDirective,
  CardTableEmptyStateDirective,
  CardTableHeaderDirective,
  CardTableRowDirective,
  CardTableRowFooterDirective,
  Checkbox,
  ContentBlockService,
  DateInputComponent,
  DirectSignupBannerComponent,
  EmailInputComponent,
  ExpandingTextBox,
  ExpiredMembershipBannerComponent,
  FeatureFlagService,
  FileSizePipe,
  FlashComponent,
  FlashService,
  FocusInputDirective,
  FooterComponent,
  InlineFormComponent,
  InviteFriendsModalComponent,
  LabelWrapper,
  LegalDocComponent,
  LoggedInWrapperComponent,
  LogoSpinner,
  MapsApiConfig,
  ModuleWithSidebarComponent,
  MomentPipe,
  NameInputComponent,
  NavbarAnalyticsService,
  NavbarComponent,
  OfficeDetailsComponent,
  PaginationComponent,
  PasswordInputComponent,
  PhoneNumberInputComponent,
  ProfileBubble,
  ProfileBubbleWithDetailsComponent,
  PusherService,
  RadioGroupComponent,
  ReferralBannerComponent,
  RoutingGroupComponent,
  RoutingGroupOptionComponent,
  ServiceAreaSelectComponent,
  SexGenderInputComponent,
  Spinner,
  StateService,
  StyledMapComponent,
  SubmitButton,
  TaskService,
  ToastComponent,
  ToggleComponent,
  TosComponent,
  TrackLinkDirective,
  TruncatedMarkdown,
  LegalAgreementComponent,
  ModalTemplateComponent,
} from '.';

import {
  ArrowBackComponent,
  ArrowLeftComponent,
  ArrowNextComponent,
  ArrowRightComponent,
  CalendarComponent,
  CircleWithCheckmarkComponent,
  CloseComponent,
  CloseXComponent,
  ExclamationInBubbleComponent,
  GroupIconComponent,
  InfoComponent,
  LocationOnMapComponent,
  LogoComponent,
  LogoSmComponent,
  PatientIconComponent,
  PencilComponent,
  ProviderComponent,
  ShowPasswordComponent,
  SpecialtyComponent,
  SpouseIconComponent,
  Svg,
  SvgVitalsComponent,
  SvgCopyIcon,
  SvgFacebookLinkComponent,
  SvgGiftBoxIcon,
  SvgHamburgerComponent,
  SvgHealthInformationExchangeComponent,
  SvgInstagramLinkComponent,
  SvgLabResultComponent,
  SvgLinkedInLinkComponent,
  SvgMembershipAndBillingComponent,
  SvgMessageComponent,
  SvgNotificationSettingsComponent,
  SvgPhoneInGreenCricle,
  SvgTwitterLinkComponent,
  SvgServiceAreaMapComponent,
  SvgYoutubeLinkComponent,
  ToggleCaretComponent,
  UserOnCompComponent,
  XWithCircleComponent,
} from './svg';
import { FeatureFlagGraphQL } from '@app/shared/feature-flag-graphql.service';

@NgModule({
  declarations: [
    AddressAutocompleteComponent,
    AddressBasicInputComponent,
    AddressOptionInputComponent,
    AddToCalendarComponent,
    ArrowBackComponent,
    ArrowLeftComponent,
    ArrowNextComponent,
    ArrowRightComponent,
    CalendarComponent,
    CardTableComponent,
    CardTableColDirective,
    CardTableEmptyStateDirective,
    CardTableHeaderDirective,
    CardTableRowDirective,
    CardTableRowFooterDirective,
    Checkbox,
    CircleWithCheckmarkComponent,
    CloseComponent,
    CloseXComponent,
    DateInputComponent,
    DirectSignupBannerComponent,
    EmailInputComponent,
    ExclamationInBubbleComponent,
    ExpandingTextBox,
    ExpiredMembershipBannerComponent,
    FileSizePipe,
    FlashComponent,
    FocusInputDirective,
    FooterComponent,
    GroupIconComponent,
    InfoComponent,
    InlineFormComponent,
    InviteFriendsModalComponent,
    LabelWrapper,
    LegalAgreementComponent,
    LegalDocComponent,
    LocationOnMapComponent,
    LoggedInWrapperComponent,
    LogoComponent,
    LogoSmComponent,
    LogoSpinner,
    ModuleWithSidebarComponent,
    MomentPipe,
    NameInputComponent,
    NavbarComponent,
    OfficeDetailsComponent,
    PaginationComponent,
    PasswordInputComponent,
    PatientIconComponent,
    PencilComponent,
    PhoneNumberInputComponent,
    ProfileBubble,
    ProfileBubbleWithDetailsComponent,
    ProviderComponent,
    RadioGroupComponent,
    ReferralBannerComponent,
    RoutingGroupComponent,
    RoutingGroupOptionComponent,
    ServiceAreaSelectComponent,
    SexGenderInputComponent,
    ShowPasswordComponent,
    SpecialtyComponent,
    Spinner,
    SpouseIconComponent,
    StyledMapComponent,
    SubmitButton,
    Svg,
    SvgVitalsComponent,
    SvgCopyIcon,
    SvgFacebookLinkComponent,
    SvgGiftBoxIcon,
    SvgHamburgerComponent,
    SvgHealthInformationExchangeComponent,
    SvgInstagramLinkComponent,
    SvgLabResultComponent,
    SvgLinkedInLinkComponent,
    SvgMembershipAndBillingComponent,
    SvgMessageComponent,
    SvgNotificationSettingsComponent,
    SvgPhoneInGreenCricle,
    SvgServiceAreaMapComponent,
    SvgTwitterLinkComponent,
    SvgWelcomeToOfficeImageComponent,
    SvgYoutubeLinkComponent,
    ToastComponent,
    ToggleCaretComponent,
    ToggleComponent,
    TosComponent,
    TrackLinkDirective,
    TruncatedMarkdown,
    UserOnCompComponent,
    XWithCircleComponent,
    DirectSignupBannerComponent,
    PasswordInputComponent,
    ShowPasswordComponent,
    ModalTemplateComponent,
  ],
  imports: [AgmCoreModule.forRoot(), CommonModule, FormsModule, NgbModule, ReactiveFormsModule, RouterModule],
  providers: [
    AppStoreRedirectService,
    ConfigService,
    ContentBlockService,
    FeatureFlagService,
    FeatureFlagGraphQL,
    FlashService,
    NavbarAnalyticsService,
    PusherService,
    StateService,
    TaskService,
    {
      provide: LAZY_MAPS_API_CONFIG,
      useClass: MapsApiConfig,
      deps: [ConfigService],
    },
  ],
  entryComponents: [InviteFriendsModalComponent, SpouseIconComponent, PatientIconComponent],
  exports: [
    AddressAutocompleteComponent,
    AddressBasicInputComponent,
    AddressOptionInputComponent,
    AddToCalendarComponent,
    ArrowBackComponent,
    ArrowLeftComponent,
    ArrowNextComponent,
    ArrowRightComponent,
    CalendarComponent,
    CardTableComponent,
    CardTableColDirective,
    CardTableEmptyStateDirective,
    CardTableHeaderDirective,
    CardTableRowDirective,
    CardTableRowFooterDirective,
    Checkbox,
    CircleWithCheckmarkComponent,
    CloseComponent,
    CloseXComponent,
    DateInputComponent,
    DirectSignupBannerComponent,
    EmailInputComponent,
    ExclamationInBubbleComponent,
    ExpandingTextBox,
    ExpiredMembershipBannerComponent,
    FileSizePipe,
    FlashComponent,
    FocusInputDirective,
    GroupIconComponent,
    InfoComponent,
    InlineFormComponent,
    InviteFriendsModalComponent,
    LabelWrapper,
    LegalAgreementComponent,
    LegalDocComponent,
    LocationOnMapComponent,
    LoggedInWrapperComponent,
    LogoComponent,
    LogoSmComponent,
    LogoSpinner,
    ModuleWithSidebarComponent,
    MomentPipe,
    NameInputComponent,
    NavbarComponent,
    OfficeDetailsComponent,
    PaginationComponent,
    PasswordInputComponent,
    PatientIconComponent,
    PencilComponent,
    PhoneNumberInputComponent,
    ProfileBubble,
    ProfileBubbleWithDetailsComponent,
    ProviderComponent,
    RadioGroupComponent,
    ReferralBannerComponent,
    RoutingGroupComponent,
    RoutingGroupOptionComponent,
    ServiceAreaSelectComponent,
    SexGenderInputComponent,
    SpecialtyComponent,
    Spinner,
    SpouseIconComponent,
    StyledMapComponent,
    SubmitButton,
    Svg,
    SvgVitalsComponent,
    SvgCopyIcon,
    SvgGiftBoxIcon,
    SvgHamburgerComponent,
    SvgHealthInformationExchangeComponent,
    SvgLabResultComponent,
    SvgMembershipAndBillingComponent,
    SvgMessageComponent,
    SvgNotificationSettingsComponent,
    SvgPhoneInGreenCricle,
    SvgServiceAreaMapComponent,
    SvgWelcomeToOfficeImageComponent,
    ToastComponent,
    ToggleCaretComponent,
    ToggleComponent,
    TosComponent,
    TrackLinkDirective,
    TruncatedMarkdown,
    UserOnCompComponent,
    XWithCircleComponent,
    ReferralBannerComponent,
    ModalTemplateComponent,
  ],
})
export class SharedModule {}
