/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./svg.component";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/platform-browser";
var styles_Svg = [];
var RenderType_Svg = i0.ɵcrt({ encapsulation: 2, styles: styles_Svg, data: {} });
export { RenderType_Svg as RenderType_Svg };
export function View_Svg_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "inline-svg"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inlineSvg; _ck(_v, 1, 0, currVal_0); }); }
export function View_Svg_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "om-svg", [], null, null, null, View_Svg_0, RenderType_Svg)), i0.ɵdid(1, 1097728, null, 0, i1.Svg, [i2.HttpClient, i3.DomSanitizer], null, null)], null, null); }
var SvgNgFactory = i0.ɵccf("om-svg", i1.Svg, View_Svg_Host_0, { image: "image" }, {}, []);
export { SvgNgFactory as SvgNgFactory };
