import { AnalyticsService } from '@app/core/analytics.service';
import { ApiService } from '@app/core/api.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@app/core/user.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'om-invite-friends-modal',
  templateUrl: './invite-friends-modal.component.html',
  styleUrls: ['./invite-friends-modal.component.scss'],
})
export class InviteFriendsModalComponent implements OnInit, OnDestroy {
  referralLink: String;
  referralDiscount: String = null;
  showThankYou = false;
  invitePending = false;
  inviteeEmail: string;

  constructor(
    private analyticsService: AnalyticsService,
    private apiService: ApiService,
    private modal: NgbActiveModal,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.userService.user$.pipe(take(1)).subscribe(user => {
      this.referralLink = user.referralLinkWebSocial;

      if (user.referralDiscount) {
        this.referralDiscount = user.referralDiscount.getDiscountString();
      }
    });
    this.userService.getUser();

    this.analyticsService.trackReferralModalOpen();
  }

  ngOnDestroy() {
    this.analyticsService.trackReferralModalClosed();
  }

  validateAndSubmit(event, emailInput) {
    event.preventDefault();

    if (this.validateEmail(emailInput)) {
      this.sendReferral(this.inviteeEmail);
    }
  }

  copyLink(element, button) {
    element.select();
    document.execCommand('copy');

    this.showCopySuccess(button);
    this.analyticsService.trackReferralLinkCopied();
  }

  inviteMore() {
    this.showThankYou = false;

    this.analyticsService.trackInviteMoreFriends();
  }

  closeModal() {
    this.modal.close();
  }

  private showCopySuccess(copyIcon) {
    copyIcon.classList.add('success');

    setTimeout(
      _copyIcon => {
        _copyIcon.classList.remove('success');
        _copyIcon.classList.add('fade-checkmark');
      },
      1000,
      copyIcon,
    );
    setTimeout(
      _copyIcon => {
        _copyIcon.classList.remove('fade-checkmark');
      },
      1500,
      copyIcon,
    );
  }

  private validateEmail(element) {
    const email = element.value;

    if (this.emailValid(email)) {
      element.classList.remove('is-invalid');
      element.classList.add('is-valid');

      this.inviteeEmail = email;
      return true;
    } else {
      element.classList.remove('is-valid');
      element.classList.add('is-invalid');

      this.inviteeEmail = '';
      this.analyticsService.trackReferralSubmissionError();
      return false;
    }
  }

  private emailValid(email) {
    // probably should use form builder and email validator - look at `preferred-email.component` or `email-input.component`
    // in the interest of time just manually validate here. As a follow-up cleanup we can reuse the above.
    const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  private sendReferral(email) {
    this.invitePending = true;

    const requestPayload = this.formatParams(email);

    this.apiService
      .post('/api/v2/patient/referrals', requestPayload, true)
      .pipe(take(1))
      .subscribe(response => {
        this.analyticsService.trackReferralSubmitted(email);
        this.showThankYou = true;
        this.invitePending = false;
      });
  }

  private formatParams(email) {
    return {
      referral: {
        emails: [
          {
            email: email,
            referral_type: 'friend',
          },
        ],
      },
    };
  }
}
