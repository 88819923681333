export class TermsOfService {
  signed: boolean;
  text: string;
  type: string;
  version: string;
  detailHint: string;
  buttonHint: string;

  static fromGraphql(object) {
    const tos = new TermsOfService();
    tos.signed = object.signed;
    tos.text = object.text;
    tos.type = object.type;
    tos.version = object.version;
    tos.detailHint = object.detail_hint;
    tos.buttonHint = object.button_hint;
    return tos;
  }
}
