import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './core/auth-guard.service';
import { DevRouteGuardService } from './core/dev-route-guard.service';
import { Login } from './core/login/login.component';
import { RegistrationCompleteGuardService } from './core/registration-complete-guard.service';
import { TosInterstitialComponent } from './core/tos/tos-interstitial.component';
import { LoggedInWrapperComponent } from './shared/logged-in-wrapper/logged-in-wrapper.component';
import { AccountActiveGuardService } from '@app/core/account-active-guard.service';
import { GuestGuardService } from '@app/core/guest-guard.service';
import { AppStoreRedirectComponent } from '@app/app-store-redirect/app-store-redirect.component';
import { AlreadyLoggedInGuardService } from '@app/core/already-logged-in-guard.service';

const appRoutes: Routes = [
  { path: 'login', component: Login, canActivate: [AlreadyLoggedInGuardService] },
  { path: 'terms-of-service', component: TosInterstitialComponent },
  {
    path: '',
    component: LoggedInWrapperComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuardService, RegistrationCompleteGuardService, AccountActiveGuardService, GuestGuardService],
      },
    ],
  },
  {
    path: 'appointments',
    component: LoggedInWrapperComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/appointment/appointment.module').then(m => m.AppointmentModule),
        canActivate: [AuthGuardService, RegistrationCompleteGuardService, GuestGuardService],
      },
    ],
  },
  {
    path: 'billing',
    component: LoggedInWrapperComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/billing/billing.module').then(m => m.BillingModule),
        canActivate: [AuthGuardService, RegistrationCompleteGuardService],
      },
    ],
  },
  {
    path: 'health-record',
    component: LoggedInWrapperComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/health-record/health-record.module').then(m => m.HealthRecordModule),
        canActivate: [AuthGuardService, RegistrationCompleteGuardService, GuestGuardService],
      },
    ],
  },
  {
    path: 'membership',
    component: LoggedInWrapperComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/membership/membership.module').then(m => m.MembershipModule),
        canActivate: [AuthGuardService, RegistrationCompleteGuardService],
      },
    ],
  },
  {
    path: 'account',
    component: LoggedInWrapperComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/account/account.module').then(m => m.AccountModule),
        canActivate: [AuthGuardService, RegistrationCompleteGuardService],
      },
    ],
  },
  {
    path: 'messages',
    component: LoggedInWrapperComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/message/message.module').then(m => m.MessageModule),
        canActivate: [AuthGuardService, RegistrationCompleteGuardService],
      },
    ],
  },
  { path: 'profile', redirectTo: '/health-record/blood-pressure', pathMatch: 'full' },
  {
    path: 'profile/blood-pressure',
    redirectTo: '/health-record/blood-pressure',
    pathMatch: 'full',
  },
  {
    path: 'tasks',
    component: LoggedInWrapperComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/task/task.module').then(m => m.TaskModule),
        canActivate: [AuthGuardService, RegistrationCompleteGuardService, GuestGuardService],
      },
    ],
  },
  {
    path: 'survey',
    component: LoggedInWrapperComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/survey/survey.module').then(m => m.SurveyModule),
        canActivate: [AuthGuardService, RegistrationCompleteGuardService, GuestGuardService],
      },
    ],
  },
  {
    path: 'registration',
    loadChildren: () => import('app/registration/registration.module').then(m => m.RegistrationModule),
  },
  { path: 'app-store-redirect', component: AppStoreRedirectComponent },
  {
    path: 'style-guide',
    loadChildren: () => import('app/style-guide/style-guide.module').then(m => m.StyleGuideModule),
    canActivate: [DevRouteGuardService],
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
