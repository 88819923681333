import * as tslib_1 from "tslib";
import { of as observableOf, BehaviorSubject, zip } from 'rxjs';
import { map, first, switchMap } from 'rxjs/operators';
import lodashMap from 'lodash-es/map';
import { ApiService } from './api.service';
import { Provider } from '../shared/provider';
import { UserService } from './user.service';
var ProviderService = /** @class */ (function () {
    function ProviderService(userService, apiService) {
        var _this = this;
        this.userService = userService;
        this.apiService = apiService;
        this._bookableProviders$ = new BehaviorSubject([]);
        this.bookableProviders$ = this._bookableProviders$.asObservable();
        this.patientProviderTeam$ = this.userService.user$.pipe(first(), map(this.getProviderTeamsIdsByUser), switchMap(function (providerIds) { return _this.getProvidersByIds(providerIds); }));
    }
    ProviderService.prototype.getProviderById = function (providerId) {
        return this.apiService
            .get("/api/v2/patient/providers/" + providerId + ".json")
            .pipe(map(function (provider) { return Provider.fromApiV2(provider); }));
    };
    ProviderService.prototype.getBookableProvidersForServiceArea = function (serviceAreaId) {
        var _this = this;
        this.apiService
            .get("/api/v2/patient/providers/patient_bookable", false, { service_area_id: serviceAreaId })
            .pipe(map(function (rawProviders) { return lodashMap(rawProviders, function (rawProvider) { return Provider.fromApiV2(rawProvider); }); }))
            .subscribe(function (providers) { return _this._bookableProviders$.next(providers); });
    };
    ProviderService.prototype.getBookableProvidersForReason = function (reason, serviceAreaId) {
        var _this = this;
        var apiParams = {
            reason: reason,
            service_area_id: serviceAreaId,
        };
        this.apiService.post('/api/v2/patient/quickumls/get_providers_by_reason', apiParams, false).subscribe(function (response) {
            var providers = response['providers'];
            if (providers.length > 0) {
                _this._bookableProviders$.next(providers.map(function (provider) { return Provider.fromApiV2(provider); }));
            }
        });
    };
    ProviderService.prototype.getProvidersByIds = function (providerIds) {
        var _this = this;
        if (providerIds.length < 1) {
            return observableOf([]);
        }
        var providersObsArray = providerIds.map(function (pid) { return _this.getProviderById(pid); });
        return zip.apply(void 0, tslib_1.__spread(providersObsArray));
    };
    ProviderService.prototype.getProviderTeamsIdsByUser = function (user) {
        if (user.provider == null || user.provider.id == null) {
            return [];
        }
        return [user.provider.id].concat(user.provider.colleagues == null ? [] : user.provider.colleagues.map(function (provider) { return provider.id; }));
    };
    return ProviderService;
}());
export { ProviderService };
