import { Component, OnDestroy, OnInit } from '@angular/core';
import { MembershipService } from '../../core/membership.service';
import { Membership } from '../../core/membership';
import { ConfigService } from '../../core/config.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MembershipExpirationHelper } from '../membership-expiration-helper';

@Component({
  selector: 'om-expired-membership-banner',
  templateUrl: './expired-membership-banner.component.html',
  styleUrls: ['./expired-membership-banner.component.scss'],
})
export class ExpiredMembershipBannerComponent implements OnInit, OnDestroy {
  membership: Membership;
  closed = false;
  b2bLink: string;
  formattedExpiryString: string;
  membershipExpiresSoon: boolean;

  private closeSubscriptions = new Subject();

  constructor(private config: ConfigService, private membershipService: MembershipService) {
    this.b2bLink = `${this.config.json.myoneServer}/enterprise_registration/determine_b2b_type`;
  }

  ngOnInit() {
    this.membershipService.membership$.pipe(takeUntil(this.closeSubscriptions)).subscribe((membership: Membership) => {
      this.membership = membership;
      const membershipExpirationHelper = new MembershipExpirationHelper(this.membership);
      this.formattedExpiryString = membershipExpirationHelper.formattedExpiry();
      this.membershipExpiresSoon = membershipExpirationHelper.expiresSoon();
    });

    this.membershipService.getMembership();
  }

  ngOnDestroy(): void {
    this.closeSubscriptions.next();
    this.closeSubscriptions.complete();
  }
}
