import { Injectable, OnInit, NgModule } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApolloQueryResult } from 'apollo-client';

import { Membership } from '@app/core/membership';
import { Response, MembershipGraphQL } from '@app/core/membership-graphql.service';
import { B2bPlan } from '@app/core/plan';

@Injectable()
export class CorePlusPlanGuardService implements CanActivate {
  membership: Membership;

  constructor(private router: Router, private membershipGraphQL: MembershipGraphQL) {}

  canActivate(): Observable<boolean> {
    return this.membershipGraphQL.fetch().pipe(
      map((result: ApolloQueryResult<Response>) => {
        const membership = result.data.membership;
        if (
          membership.planType === 'B2bPlan' &&
          (membership.plan as B2bPlan).name === 'Core' &&
          (membership.plan as B2bPlan).company.includesDependent
        ) {
          return true;
        } else {
          this.router.navigate(['/'], { replaceUrl: true });
          return false;
        }
      }),
    );
  }
}
