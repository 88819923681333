import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { LoginService } from '../../core/login.service';
import { TermsOfService } from '../../core/terms-of-service';
import { DocumentSigner, TosService } from '../../core/tos.service';
import { User } from '@app/shared/user';

@Component({
  selector: 'om-terms-of-service',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.scss'],
})
export class TosComponent implements OnInit {
  @Input() beneficiary: User;
  @Input() signer: DocumentSigner;
  @Input() beneficiaryLoggedIn: boolean;
  @Input() signOnBehalf: boolean;
  @Output() nextAction = new EventEmitter<void>();

  legalGuardianSet = false;
  agreedToSignOnBehalf = false;
  documentsEnabled = true;

  termsToAgree: TermsOfService[];
  termsToAcknowledge: TermsOfService[];
  allTermsAccepted = false;
  redirecting = false;
  individualTosDocsLoaded = false;
  unsignedDocs: TermsOfService[] = [];

  constructor(public tosService: TosService, private loginService: LoginService) {}

  ngOnInit() {
    this.getTos();
    if (this.signOnBehalf) {
      this.documentsEnabled = false;
    }
    this.tosService.tos$.subscribe(tos => {
      this.setUpTermsOfService(tos);
    });
  }

  // this is triggered by event emitted when a document was successfully signed.
  // the purpose of it is to refresh all docs once the local state indicates there
  // are no more docs to sign - as a double check with server data; then set "allTermsAccepted" to true.
  docSigned(doc) {
    this.unsignedDocs = this.unsignedDocs.filter(d => {
      return d !== doc;
    });

    if (this.unsignedDocs.length === 0) {
      this.getTos();
    }
  }

  logout(e: MouseEvent) {
    e.preventDefault();
    this.loginService.logout().subscribe();
  }

  continue() {
    this.redirecting = true;
    this.nextAction.emit();
  }

  setUpTermsOfService = (docs: TermsOfService[]) => {
    this.individualTosDocsLoaded = true;

    this.termsToAcknowledge = docs.filter(doc => {
      return doc.type === 'hipaa_policy';
    });
    this.termsToAgree = docs.filter(doc => {
      return doc.type !== 'hipaa_policy';
    });
    this.unsignedDocs = docs.filter(doc => {
      return !doc.signed;
    });
    this.allTermsAccepted = this.unsignedDocs.length === 0;
  };

  setLegalGuardian() {
    const createGuardianRequest = this.beneficiaryLoggedIn
      ? this.tosService.setLegalGuardianForBeneficiaryWhenLoggedInAsBeneficiary(this.signer)
      : this.tosService.setLegalGuardianForBeneficiaryWhenLoggedInAsAdministrator(this.beneficiary);

    createGuardianRequest.subscribe(() => {
      this.legalGuardianSet = true;
      this.setEnableDocuments();
    });
  }

  setAgreedToSignOnBehalf() {
    this.agreedToSignOnBehalf = true;
    this.setEnableDocuments();
  }

  setEnableDocuments() {
    this.documentsEnabled = !this.signOnBehalf || (this.agreedToSignOnBehalf && this.legalGuardianSet);
  }

  private getTos() {
    if (this.signingForSelf()) {
      this.tosService.getTos();
    } else if (this.beneficiaryLoggedIn) {
      this.tosService.getTosForChild();
    } else {
      this.tosService.getTosFor(this.beneficiary);
    }
  }

  private signingForSelf() {
    return !this.signOnBehalf;
  }
}
