import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { DocumentSigner, TosService } from '../../core/tos.service';
import { TermsOfService } from '../../core/terms-of-service';
import { FlashService } from '../flash.service';
import { User } from '../user';

@Component({
  selector: 'om-legal-doc',
  templateUrl: './legal-doc.component.html',
  styleUrls: ['./legal-doc.component.scss'],
})
export class LegalDocComponent implements OnInit {
  @Input() doc: TermsOfService;
  @Input() beneficiary: User;
  @Input() signer: DocumentSigner;
  @Input() beneficiaryLoggedIn: boolean;
  @Input() signOnBehalf: boolean;
  @Input() enabled: boolean;
  @Output() docSigned = new EventEmitter<TermsOfService>();

  collapsed = true;
  signed = false;
  processing = false;

  constructor(public tosService: TosService, public flashService: FlashService) {}

  ngOnInit() {
    this.signed = this.doc.signed;
  }

  toggleDoc($event) {
    $event.preventDefault();
    this.collapsed = !this.collapsed;
  }

  signDoc($event) {
    $event.preventDefault();
    if (this.signed || this.processing) {
      return;
    } else {
      this.processing = true;

      this.sign();
    }
  }

  private sign() {
    let submitSignature;
    if (this.signOnBehalf) {
      if (this.beneficiaryLoggedIn) {
        submitSignature = this.tosService.signForBeneficiaryWhenLoggedInAsBeneficiary(
          this.doc,
          this.beneficiary,
          this.signer,
        );
      } else {
        submitSignature = this.tosService.signForBeneficiaryWhenLoggedInAsAdministrator(this.doc, this.beneficiary);
      }
    } else {
      submitSignature = this.tosService.signDocument(this.doc);
    }

    submitSignature.subscribe(data => this.handleResponse(data));
  }

  handleResponse = data => {
    if (data.error || data.errors) {
      this.flashService.addFlashMessage(
        "We've encountered an issue submitting your request. Please log out and try again.",
        'error',
      );
      this.processing = false;
    } else {
      this.signed = true;
      this.processing = false;
      this.docSigned.emit(this.doc);
    }
  };
}
