import each from 'lodash-es/each';
var FromAppointment = /** @class */ (function () {
    function FromAppointment() {
    }
    FromAppointment.fromApiV2 = function (response) {
        var fromAppointment = new FromAppointment();
        each(FromAppointment.fieldMapping, function (value, key) {
            fromAppointment[key] = response[value];
        });
        return fromAppointment;
    };
    FromAppointment.prototype.toApiV2 = function () {
        var _this = this;
        var response = {};
        each(FromAppointment.fieldMapping, function (value, key) {
            response[value] = _this[key];
        });
        return response;
    };
    Object.defineProperty(FromAppointment.prototype, "toReschedulingBannerHtml", {
        get: function () {
            return "Rescheduling Appointment       <strong>" + this.startTime + "</strong> at       <strong>" + this.officeName + "</strong> with       <strong>" + this.providerName + ".</strong> ";
        },
        enumerable: true,
        configurable: true
    });
    FromAppointment.fieldMapping = {
        id: 'id',
        startTime: 'start_time',
        officeName: 'office_name',
        providerName: 'provider_name',
    };
    return FromAppointment;
}());
export { FromAppointment };
