/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./svg-vitals.component";
var styles_SvgVitalsComponent = [];
var RenderType_SvgVitalsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SvgVitalsComponent, data: {} });
export { RenderType_SvgVitalsComponent as RenderType_SvgVitalsComponent };
export function View_SvgVitalsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(1, 0, null, null, 5, ":svg:svg", [["fill", "none"], ["height", "100%"], ["viewBox", "0 0 24 24"], ["width", "100%"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(3, 0, null, null, 0, ":svg:path", [["d", "M6 12.7339H9.27343L10.2694 15L12.8957 9L14.6177 12.7339H18"]], [[1, "stroke", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(5, 0, null, null, 0, ":svg:path", [["clip-rule", "evenodd"], ["d", "M13.7559 4.53014L11.9999 6.22428L10.2441 4.53014C8.12928 2.48995 4.70058 2.48995 2.58609 4.53014C0.471304 6.57033 0.471304 9.87781 2.58609 11.918L4.34188 13.6121L11.9999 21L19.6578 13.6121L21.4139 11.918C23.5287 9.87781 23.5287 6.57033 21.4139 4.53014C19.2991 2.48995 15.8707 2.48995 13.7559 4.53014Z"], ["fill-rule", "evenodd"]], [[1, "stroke", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n  "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.color; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.color; _ck(_v, 5, 0, currVal_1); }); }
export function View_SvgVitalsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "om-svg-vitals", [], null, null, null, View_SvgVitalsComponent_0, RenderType_SvgVitalsComponent)), i0.ɵdid(1, 49152, null, 0, i1.SvgVitalsComponent, [], null, null)], null, null); }
var SvgVitalsComponentNgFactory = i0.ɵccf("om-svg-vitals", i1.SvgVitalsComponent, View_SvgVitalsComponent_Host_0, { color: "color" }, {}, []);
export { SvgVitalsComponentNgFactory as SvgVitalsComponentNgFactory };
