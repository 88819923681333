import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocumentItem } from '@app/shared/document-item';
import { bindCallback, Observable } from 'rxjs';
import { CommentItem } from '../message/comment-item';
import { MessageItem } from '../message/message-item';
import { UploadableFile } from '../message/uploadable-file';
import { BasicFollowUpOrderContent, Task } from '../shared/task';
import { User } from '../shared/user';
import { UserService } from './user.service';
import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { environment } from '../../environments/environment';
import { MembershipService } from '@app/core/membership.service';
import { Membership } from '@app/core/membership';
import queryString from 'query-string';

mixpanel.init(environment.mixpanelToken);
mixpanel.set_config({ debug: environment.mixpanelDebug });

interface StructuredProperties {
  flow: string;
  module?: string;
  submodule?: string;
  membership_status?: string;
  om_membership_type?: string;
  service_area?: string;
  is_pediatrics?: boolean;
  is_logged_in?: boolean;
  is_whitelist?: boolean;
  is_peds_serviceable?: boolean;
  direct_sign_up_type?: string;
  birth_type?: 'Born' | 'Unborn';
  action_selected?: string;
}

@Injectable()
export class AnalyticsService {
  protected module: string;
  private mixpanel: Mixpanel;
  protected defaultProperties: StructuredProperties;

  /**
   * @deprecated use trackWithDefaultProperties instead
   */
  protected track(eventName: string, properties?: { [index: string]: any }): Observable<any> {
    const track = bindCallback(this.mixpanel.track).call(this.mixpanel, eventName, properties);
    track.subscribe();
    return track;
  }

  protected trackWithDefaultProperties(eventName: string, properties?: StructuredProperties): Observable<any> {
    const track = bindCallback(this.mixpanel.track).call(this.mixpanel, eventName, {
      ...this.defaultProperties,
      ...properties,
    });

    track.subscribe();

    return track;
  }

  constructor(protected userService: UserService, protected membershipService: MembershipService) {
    this.mixpanel = mixpanel;
    this.trackCampaigns(document.location);

    this.userService.user$.subscribe((user: User) => {
      this.identifyAndUpdateUser(user);
    });

    this.membershipService.membership$.subscribe((membership: Membership) => {
      this.updateMembershipProperties(membership);
    });
  }

  setModule(module: string) {
    this.module = module;
  }

  patientTaskViewed(task: Task) {
    return this.track('Patient Task Viewed', this.taskCommonProperties(task));
  }

  patientTaskOverviewViewed(taskCount: number) {
    return this.track('Patient Tasks Overview Viewed', {
      'Patient Task Count': taskCount,
      flow: 'Patient Tasks',
      module: this.module,
    });
  }

  patientTaskDeclineTapped(task: Task) {
    return this.track('Patient Task Decline Tapped', this.taskCommonProperties(task));
  }

  patientTaskDeclined(task: Task) {
    return this.track('Patient Task Declined', this.taskCommonProperties(task));
  }

  patientTaskAlreadyDoneTapped(task: Task, property?) {
    const taskProperties = this.taskCommonProperties(task);
    if (task.type === 'consult_order') {
      taskProperties['already_done_type'] = property.specialist;
    }
    return this.track('Patient Task Already Done Tapped', taskProperties);
  }

  patientTaskAlreadyDone(task: Task, property?) {
    const taskProperties = this.taskCommonProperties(task);
    if (task.type === 'consult_order') {
      taskProperties['already_done_type'] = property.specialist;
      this.consultOrderSpecialistEventSelector(task, property);
    }
    if (task.type === 'basic_follow_up_order') {
      this.patientTaskSubmitted(task);
    } else {
      return this.track('Patient Task Already Done Confirmed', taskProperties);
    }
  }

  consultOrderSpecialistEventSelector(task: Task, property) {
    if (property.specialist === 'Elsewhere') {
      const specialistModule = property.specialistInfo
        ? 'Specialist Information Submitted'
        : 'Specialist Information Unknown Submitted';
      this.track(specialistModule, this.taskCommonProperties(task));
    }
  }

  patientTaskSubmitted(task: Task) {
    const taskProperties = this.taskCommonProperties;
    taskProperties['Task Feedback'] = (<BasicFollowUpOrderContent>task.content).feedback;
    return this.track('Patient Task Submitted', taskProperties);
  }

  bloodPressureEntryViewed() {
    return this.track('Blood Pressure Entry Viewed');
  }

  bloodPressureSubmitted() {
    return this.track('Blood Pressure Submitted');
  }

  attachmentCreated(attachment: UploadableFile) {
    return this.track('Timeline Attachment Created', {
      'Content Type': attachment.file.type,
      'Content Length': attachment.file.size,
    });
  }

  attachmentCreationFailed(attachment: UploadableFile) {
    return this.track('Timeline Attachment Creation Failed', {
      'Content Type': attachment.file.type,
      'Content Length': attachment.file.size,
    });
  }

  attachmentDeleted(attachment: UploadableFile) {
    return this.track('Timeline Attachment Deleted', {
      'Content Type': attachment.file.type,
      'Content Length': attachment.file.size,
    });
  }

  attachmentViewed(document: DocumentItem) {
    return this.track('Timeline Attachment Deleted', {
      'Content Type': document.contentType,
      'Content Length': document.contentLength,
    });
  }

  attachmentDownloaded(document: DocumentItem) {
    return this.track('Timeline Attachment Deleted', {
      'Content Type': document.contentType,
      'Content Length': document.contentLength,
    });
  }

  messagesPageLoad() {
    return this.track('Timeline Post Overview Viewed', {
      Referrer: document.referrer,
    });
  }

  postCreated(post: MessageItem) {
    this.mixpanel.people.increment('Timeline Post Created');
    this.mixpanel.people.set({ 'Last Timeline Post Created': new Date(post.timestamp) });

    return this.track('Timeline Post Created', {
      'Post Id': post.id,
      Recipient: post.recipient,
      'Text Length': post.content.length,
      'Attachment Count': post.documents.length,
    });
  }

  postCreationFailed(post: MessageItem, error: HttpErrorResponse) {
    return this.track('Timeline Post Creation Failed', {
      Recipient: post.recipient,
      'Text Length': post.content.length,
      'Attachment Count': post.documents.length,
      'Error Code': error.status,
      'Error Description': error.message,
      'Error Status Text': error.statusText,
      'Error Type': error.name,
    });
  }

  commentCreated(post: MessageItem, comment: CommentItem) {
    this.mixpanel.people.increment('Timeline Comment Created');
    this.mixpanel.people.set({ 'Last Timeline Comment Created': new Date(comment.timestamp) });

    return this.track('Timeline Comment Created', {
      'Post Id': post.id,
      'Comment Id': comment.id,
      'Text Length': comment.content.length,
      'Attachment Count': comment.documents.length,
    });
  }

  commentCreationFailed(post: MessageItem, comment: CommentItem, error: HttpErrorResponse) {
    return this.track('Timeline Comment Creation Failed', {
      'Post Id': post.id,
      'Text Length': comment.content.length,
      'Attachment Count': comment.documents.length,
      'Error Code': error.status,
      'Error Description': error.message,
      'Error Status Text': error.statusText,
      'Error Type': error.name,
    });
  }

  surveyLoaded(surveyId: number) {
    return this.track('Survey Loaded', {
      'Survey Id': surveyId,
    });
  }

  surveyNextQuestion(surveyId: number, questionId: number) {
    return this.track('Load Next Question', {
      'Survey Id': surveyId,
      'Question Id': questionId,
    });
  }

  surveySubmitted(surveyId: number) {
    return this.track('Submit Survey', {
      'Survey Id': surveyId,
    });
  }

  appInstallBannerDismissed() {
    return this.track('App Install Banner Dismissed', {});
  }

  appInstallBannerAccepted() {
    return this.track('App Install Banner Accepted', {});
  }

  appStoreLinkClicked(location: string) {
    return this.track('App Store Link Clicked', {
      Location: location,
    });
  }

  trackHealthRecordLink(properties: { [key: string]: any } = {}) {
    return this.trackWithDefaultProperties('Health Record Clicked', {
      flow: 'Accessing PHR',
      ...properties,
    });
  }

  trackBookVisitLink(properties: { [key: string]: any } = {}) {
    return this.trackWithDefaultProperties('Book Visit Clicked', {
      flow: 'Appointment Booking',
      ...properties,
    });
  }

  trackDirectSignupStarted() {
    return this.track('Direct Sign Up Started', {
      ...this.defaultProperties,
      flow: 'Direct Sign Up',
      module: this.module,
      submodule: 'Header Banner',
    });
  }

  trackReferralModalOpen() {
    this.track('Friends and Family Referral Started', {
      ...this.defaultProperties,
      flow: 'Referral',
      module: 'MyOne Friends and Family Referral Modal',
      submodule: 'MyOne Header Banner',
    });
  }

  trackReferralBannerShown(properties) {
    this.track('Banner Displayed', {
      ...this.defaultProperties,
      ...properties,
      flow: 'Referral',
      module: this.module,
      submodule: 'MyOne Header Banner',
    });
  }

  trackReferralSubmissionError() {
    this.track('Submit Error Encountered', {
      ...this.defaultProperties,
      flow: 'Referral',
      module: 'MyOne Friends and Family Referral Modal',
    });
  }

  trackReferralLinkCopied() {
    this.track('Friends and Family Referral Link Copied', {
      ...this.defaultProperties,
      flow: 'Referral',
      module: 'MyOne Friends and Family Referral Modal',
    });
  }

  trackInviteMoreFriends() {
    this.track('Invite More Friends and Family Clicked', {
      ...this.defaultProperties,
      flow: 'Referral',
      module: 'MyOne Friends and Family Referral Modal',
    });
  }

  trackReferralModalClosed() {
    this.track('Friends and Family Referral Modal Closed', {
      ...this.defaultProperties,
      flow: 'Referral',
      module: 'MyOne Friends and Family Referral Modal',
    });
  }

  trackReferralSubmitted(email: string) {
    this.track('Friends and Family Referral Submitted', {
      ...this.defaultProperties,
      flow: 'Referral',
      module: 'MyOne Friends and Family Referral Modal',
      emails: [email],
      numEmails: 1,
    });
  }

  pedsRegLinkClicked(properties) {
    return this.track('Pediatric Link Clicked', properties);
  }

  trackWithErrors(eventName: string, properties) {
    this.ensureRequiredPropertiesIncluded(properties);
    return this.track(eventName, properties);
  }

  identifyNewUser(user: User) {
    this.mixpanel.alias(`${user.id}`);
    this.mixpanel.identify(`${user.id}`);
  }

  private ensureRequiredPropertiesIncluded(properties) {
    if (!properties.flow) {
      throw new Error('"flow" is a required property');
    }
    if (!properties.module) {
      throw new Error('"module" is a required property');
    }
  }

  private identifyAndUpdateUser(user: User) {
    this.defaultProperties = {
      ...this.defaultProperties,
      service_area: user.serviceArea.name,
    };

    this.mixpanel.identify(`${user.id}`);
    this.mixpanel.people.set({ Id: user.id });
  }

  private updateMembershipProperties(membership: Membership) {
    if (!membership.omMembershipType) {
      throw new Error(
        `Unset membership type. Expected om_membership_type from membership but ${
          membership.omMembershipType
        } was found`,
      );
    }

    this.defaultProperties = {
      ...this.defaultProperties,
      membership_status: membership.status,
      om_membership_type: membership.omMembershipType,
    };
  }

  private taskCommonProperties(task) {
    return {
      patient_task_id: task.id,
      'Task Id': task.id,
      patient_task_type: task.type,
      'Task Type': task.type,
      'Task State': task.state,
      'Task Scheduled At': task.scheduledAt,
      'Task Provider Id': task.provider.id,
      module: this.module,
      flow: 'Patient Tasks',
    };
  }

  private trackCampaigns(location) {
    const keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ');
    const lastValues = {},
      firstValues = {};
    const params = queryString.parse(location.search, { decode: true });
    for (const keyword of Object.keys(keywords)) {
      const value = params[keyword];
      if (value) {
        lastValues[keyword + ' [last touch]'] = value;
        firstValues[keyword + ' [first touch]'] = value;
      }
    }
    this.mixpanel.people.set_once(firstValues);
    this.mixpanel.people.set(lastValues);
    this.mixpanel.register(lastValues);
  }
}
