/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./svg-message.component";
var styles_SvgMessageComponent = [];
var RenderType_SvgMessageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SvgMessageComponent, data: {} });
export { RenderType_SvgMessageComponent as RenderType_SvgMessageComponent };
export function View_SvgMessageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, ":svg:svg", [["fill", "none"], ["height", "24"], ["viewBox", "0 0 26 24"], ["width", "26"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(2, 0, null, null, 0, ":svg:path", [["clip-rule", "evenodd"], ["d", "M22.728 21H3.27314C2.01829 21 1 19.9987 1 18.7635V5.2365C1 4.00125 2.01829 3 3.27314 3H22.728C23.9829 3 25 4.00125 25 5.2365V18.7635C25 19.9987 23.9829 21 22.728 21V21Z"], ["fill-rule", "evenodd"], ["stroke-width", "1.5"]], [[1, "stroke", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(4, 0, null, null, 0, ":svg:path", [["d", "M1 7L12.393 15.703C12.7514 15.9767 13.2486 15.9767 13.607 15.703L25 7"], ["stroke-width", "1.5"]], [[1, "stroke", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stroke; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.stroke; _ck(_v, 4, 0, currVal_1); }); }
export function View_SvgMessageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "om-svg-message", [], null, null, null, View_SvgMessageComponent_0, RenderType_SvgMessageComponent)), i0.ɵdid(1, 114688, null, 0, i1.SvgMessageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SvgMessageComponentNgFactory = i0.ɵccf("om-svg-message", i1.SvgMessageComponent, View_SvgMessageComponent_Host_0, { stroke: "stroke" }, {}, []);
export { SvgMessageComponentNgFactory as SvgMessageComponentNgFactory };
