/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/svg/logo/logo.component.ngfactory";
import * as i2 from "../../shared/svg/logo/logo.component";
import * as i3 from "../../shared/flash/flash.component.ngfactory";
import * as i4 from "../../shared/flash/flash.component";
import * as i5 from "../../shared/flash.service";
import * as i6 from "../../shared/tos/tos.component.ngfactory";
import * as i7 from "../../shared/tos/tos.component";
import * as i8 from "../tos.service";
import * as i9 from "../login.service";
import * as i10 from "./tos-interstitial.component";
import * as i11 from "../auth.service";
var styles_TosInterstitialComponent = [];
var RenderType_TosInterstitialComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TosInterstitialComponent, data: {} });
export { RenderType_TosInterstitialComponent as RenderType_TosInterstitialComponent };
export function View_TosInterstitialComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "text-center mt-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "om-logo", [["class", "logo mx-auto"]], null, null, null, i1.View_LogoComponent_0, i1.RenderType_LogoComponent)), i0.ɵdid(2, 114688, null, 0, i2.LogoComponent, [], null, null), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "om-flash", [], null, null, null, i3.View_FlashComponent_0, i3.RenderType_FlashComponent)), i0.ɵdid(5, 114688, null, 0, i4.FlashComponent, [i5.FlashService], null, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "om-terms-of-service", [], null, [[null, "nextAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("nextAction" === en)) {
        var pd_0 = (_co.continue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_TosComponent_0, i6.RenderType_TosComponent)), i0.ɵdid(8, 114688, null, 0, i7.TosComponent, [i8.TosService, i9.LoginService], null, { nextAction: "nextAction" }), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 5, 0); _ck(_v, 8, 0); }, null); }
export function View_TosInterstitialComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "om-tos", [], null, null, null, View_TosInterstitialComponent_0, RenderType_TosInterstitialComponent)), i0.ɵdid(1, 49152, null, 0, i10.TosInterstitialComponent, [i11.AuthService], null, null)], null, null); }
var TosInterstitialComponentNgFactory = i0.ɵccf("om-tos", i10.TosInterstitialComponent, View_TosInterstitialComponent_Host_0, {}, {}, []);
export { TosInterstitialComponentNgFactory as TosInterstitialComponentNgFactory };
