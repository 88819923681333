/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./legal-agreement.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../logo-spinner/logo-spinner.component.ngfactory";
import * as i3 from "../../logo-spinner/logo-spinner.component";
import * as i4 from "../../svg/svg.component.ngfactory";
import * as i5 from "../../svg/svg.component";
import * as i6 from "@angular/common/http";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/common";
import * as i9 from "./legal-agreement.component";
import * as i10 from "../../../core/tos.service";
var styles_LegalAgreementComponent = [i0.styles];
var RenderType_LegalAgreementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LegalAgreementComponent, data: {} });
export { RenderType_LegalAgreementComponent as RenderType_LegalAgreementComponent };
function View_LegalAgreementComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "button-hint"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["I agree"]))], null, null); }
function View_LegalAgreementComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "om-logo-spinner", [["class", "spinner"], ["size", "20"]], null, null, null, i2.View_LogoSpinner_0, i2.RenderType_LogoSpinner)), i1.ɵdid(1, 49152, null, 0, i3.LogoSpinner, [], { size: [0, "size"] }, null)], function (_ck, _v) { var currVal_0 = "20"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LegalAgreementComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "om-svg", [["class", "checkmark"], ["image", "tos_checkmark"]], null, null, null, i4.View_Svg_0, i4.RenderType_Svg)), i1.ɵdid(1, 1097728, null, 0, i5.Svg, [i6.HttpClient, i7.DomSanitizer], { image: [0, "image"] }, null)], function (_ck, _v) { var currVal_0 = "tos_checkmark"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LegalAgreementComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 18, "div", [["class", "doc-title p-3 d-flex justify-content-between"]], [[2, "agree", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "agreement-title"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(7, 0, null, null, 12, "button", [], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.agreeSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i8.ɵNgClassImpl, i8.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i8.NgClass, [i8.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegalAgreementComponent_1)), i1.ɵdid(12, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegalAgreementComponent_2)), i1.ɵdid(15, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegalAgreementComponent_3)), i1.ɵdid(18, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.agree || _co.processing) ? "icon" : "text"); _ck(_v, 9, 0, currVal_2); var currVal_3 = (!_co.agree && !_co.processing); _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.processing; _ck(_v, 15, 0, currVal_4); var currVal_5 = _co.agree; _ck(_v, 18, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.agree; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.agree; _ck(_v, 7, 0, currVal_1); }); }
export function View_LegalAgreementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "om-legal-agreement", [], null, null, null, View_LegalAgreementComponent_0, RenderType_LegalAgreementComponent)), i1.ɵdid(1, 114688, null, 0, i9.LegalAgreementComponent, [i10.TosService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LegalAgreementComponentNgFactory = i1.ɵccf("om-legal-agreement", i9.LegalAgreementComponent, View_LegalAgreementComponent_Host_0, { processing: "processing", agree: "agree" }, { agreed: "agreed" }, ["*"]);
export { LegalAgreementComponentNgFactory as LegalAgreementComponentNgFactory };
