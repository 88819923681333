<div>
  <om-navbar *ngIf="!isWebView"></om-navbar>
  <om-expired-membership-banner></om-expired-membership-banner>
  <ngb-alert class="text-center" type="success" *ngIf="notice && !closed" (close)="closed = true">{{
    notice
  }}</ngb-alert>
</div>

<div class="flex-grow-1"><router-outlet></router-outlet></div>

<om-footer></om-footer>
