
    <a href="https://twitter.com/onemedical" target="_blank">
      <svg [attr.fill]="fill" height="18px" viewBox="0 0 20 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Icon/Twitter</title>
        <desc>Twitter Icon</desc>
        <g stroke="none" stroke-width="1" fill-rule="evenodd">
          <g transform="translate(-1185.000000, -175.000000)" fill-rule="nonzero">
            <g transform="translate(1141.000000, 174.000000)">
              <g transform="translate(44.000000, 0.000000)">
                <path
                  d="M17.9296448,5.43752757 C17.9556866,5.54169418 17.9687072,5.71096442 17.9687072,5.94533886 C17.9687072,7.82033439 17.5129789,9.63022571 16.6015229,11.3750134 C15.6640252,13.2239671 14.3489239,14.695318 12.6562198,15.7890654 C10.8072661,17.0130206 8.68487493,17.6249985 6.28904751,17.6249985 C3.9973861,17.6249985 1.90103733,17.0130206 0,15.7890654 C0.286457452,15.8151072 0.611977906,15.8281278 0.976560172,15.8281278 C2.8775975,15.8281278 4.58332221,15.2421917 6.09373547,14.0703195 C5.18227951,14.0703195 4.38150017,13.8033932 3.69139745,13.2695401 C3.00129473,12.7356871 2.52603584,12.0651161 2.2656196,11.2578262 C2.52603584,11.283868 2.77343089,11.2968886 3.00780533,11.2968886 C3.37238759,11.2968886 3.73697046,11.2578262 4.10155272,11.1797014 C3.16405496,10.9713687 2.38280682,10.4895987 1.75780831,9.73439232 C1.1328098,8.97918599 0.820310544,8.11981304 0.820310544,7.15627347 L0.820310544,7.07814866 C1.39322604,7.41668971 2.00520335,7.59898055 2.65624367,7.62502235 C2.10936997,7.23439828 1.66666289,6.7396076 1.32812183,6.14065089 C0.989580775,5.54169418 0.820310544,4.88414327 0.820310544,4.16799934 C0.820310544,3.45185542 1.01562258,2.7682633 1.40624665,2.11722298 C2.42186923,3.39326181 3.66535565,4.40888438 5.1367065,5.16409072 C6.60805736,5.91929705 8.17706364,6.33596293 9.84372653,6.41408774 C9.79164352,6.10158848 9.76560172,5.78908923 9.76560172,5.47658997 C9.76560172,4.74742485 9.94789315,4.06383273 10.3124754,3.42581361 C10.6770577,2.7877945 11.1718484,2.28649321 11.7968469,1.92191095 C12.4218454,1.55732868 13.0989269,1.37503725 13.828092,1.37503725 C14.4270487,1.37503725 14.980433,1.49222447 15.4882443,1.72659891 C15.9960556,1.96097336 16.4452733,2.27347261 16.8358974,2.66409668 C17.7733951,2.48180525 18.6457887,2.15628539 19.4530786,1.68753651 C19.1405794,2.65107608 18.5416227,3.40628241 17.6562079,3.95315611 C18.437456,3.84898949 19.2187042,3.62763625 19.9999523,3.28909519 C19.4270368,4.12242634 18.7369347,4.83857086 17.9296448,5.43752757 Z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </a>
  