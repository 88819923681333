// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  apiServer: 'https://oakland.app.1life.com',
  enableProdMode: true,
  devRoutes: true,
  myoneServer: 'https://oakland.app.1life.com',
  marketingSite: 'https://www.onemedical.com',
  topLevelDomain: '.1life.com',
  oneMedicalPhone: '415-291-0480',
  mixpanelToken: '6819c6402e57e60abfeb330144a8f998',
  mixpanelDebug: true,
  gaAccount: 'UA-39757505-1',
  appStoreLink: 'https://itunes.apple.com/us/app/one-medical-mobile/id393507802?mt=8',
  googlePlayLink: 'https://play.google.com/store/apps/details?id=com.onemedical.android&hl=en',
  googlePlacesKey: 'AIzaSyDlUybHIKS9TSto22YOtzkNcIlTro4fXaA',
  recaptchaSiteKey: '6Lfo1F4UAAAAANq4xxbQW_4njCPCDMsLALZRKsJW',
  pusherKey: '5422148b626236f6bff3',
  rollbarAccessToken: '',
  rollbarEnvironment: '',
  rollbarEndpoint: '',
  stripePublishableKey: 'pk_test_n8l9UNf6ai3uDaNVAiuWp8Tu',
};
