import { Injectable } from '@angular/core';
import { WindowService } from '@app/core/window.service';
import { ConfigService } from '@app/core/config.service';

@Injectable()
export class AppStoreRedirectService {
  constructor(private windowService: WindowService, private config: ConfigService) {}

  getRedirectUrl(): string {
    if (this.windowService.isAndroid()) {
      return this.config.json.googlePlayLink;
    } else if (this.windowService.isIos()) {
      return this.config.json.appStoreLink;
    } else {
      return 'https://www.onemedical.com/technology/';
    }
  }
}
