import { ApiV2Response } from './membership.service';
import { B2bPlan, StripePlan } from './plan';

interface RenewalPlan {
  amount: number;
}

export enum ExpirationAction {
  RENEW = 'renew',
  EXPIRE = 'expire',
}

export enum MembershipPlan {
  B2B = 'B2bPlan',
  GUEST = 'GuestPlan',
  SELF_PAID = 'StripePlan',
}

export enum MembershipStatus {
  CURRENT = 'current',
  DEACTIVATED = 'deactivated',
  EXPIRED = 'expired',
  PENDING = 'pending',
}

export enum CreditCardBrand {
  VISA = 'Visa',
  MASTERCARD = 'MasterCard',
  AMEX = 'American Express',
  DISCOVER = 'Discover',
}

export type CreditCardBrandName = CreditCardBrand | string;

class ExpirationDate {
  date: string;
  action: ExpirationAction;
}

class CreditCard {
  brand: CreditCardBrandName;
  last4: string;
}

export class Membership {
  canBookVisit: boolean;
  canCancel: boolean;
  canReactivate: boolean;
  canUpdateB2bCode: boolean;
  canUpdateBilling: boolean;
  isActive: boolean;
  creditCard?: CreditCard;
  expirationDate: ExpirationDate;
  patientId: number;
  patientStatus: string;
  planId: number;
  planType: MembershipPlan;
  renewalPlan?: RenewalPlan;
  status: string;
  validUntil: string;
  omMembershipType: string;
  plan: B2bPlan | StripePlan;

  constructor() {}

  static fromApiV2(response: ApiV2Response): Membership {
    const membership = new Membership();

    membership.canBookVisit = response.can_book_visit;
    membership.canCancel = response.can_cancel;
    membership.canReactivate = response.can_reactivate;
    membership.canUpdateB2bCode = response.can_update_b2b_code;
    membership.canUpdateBilling = response.can_update_billing;
    membership.isActive = response.is_active;
    membership.patientStatus = response.patient_status;
    membership.planId = response.plan_id;
    membership.planType = response.plan_type as MembershipPlan;
    membership.status = response.status;
    membership.validUntil = response.valid_until;
    membership.omMembershipType = response.om_membership_type;

    if (response.renewal_plan) {
      membership.renewalPlan = { amount: response.renewal_plan.amount / 100 };
    }

    if (response.expiration_date) {
      const expirationDate = new ExpirationDate();
      expirationDate.date = response.expiration_date.date;
      expirationDate.action = <ExpirationAction>response.expiration_date.action;
      membership.expirationDate = expirationDate;
    }

    if (response.credit_card) {
      const creditCard = new CreditCard();
      creditCard.brand = <CreditCardBrandName>response.credit_card.brand;
      creditCard.last4 = response.credit_card.last4;
      membership.creditCard = creditCard;
    }

    return membership;
  }

  hasLimitedAccess(): boolean {
    return this.planType === MembershipPlan.GUEST;
  }

  isExpired(): boolean {
    return this.status === MembershipStatus.EXPIRED || this.status === MembershipStatus.DEACTIVATED;
  }

  isPending(): boolean {
    return this.status === MembershipStatus.PENDING;
  }

  hasReadOnlyMessaging(): boolean {
    return this.hasLimitedAccess() || this.isExpired();
  }

  isB2b(): boolean {
    return this.planType === MembershipPlan.B2B;
  }

  isSelfPaid(): boolean {
    return this.planType === MembershipPlan.SELF_PAID;
  }

  isGuest(): boolean {
    return this.planType === MembershipPlan.GUEST;
  }

  isCurrent(): boolean {
    return this.status === MembershipStatus.CURRENT;
  }
}
