import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'om-logged-in-wrapper',
  templateUrl: './logged-in-wrapper.component.html',
  styleUrls: ['./logged-in-wrapper.component.scss'],
})
export class LoggedInWrapperComponent implements OnInit {
  notice?: string;
  closed = false;
  isWebView = false;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.notice = this.route.snapshot.queryParams.notice;
    this.router.navigate([], {
      queryParams: {
        notice: null,
      },
      queryParamsHandling: 'merge',
    });

    this.isWebView = this.route.snapshot.queryParams.is_web_view === 'true';
  }
}
