import each from 'lodash-es/each';

export class AppointmentType {
  get id(): number {
    return this.firstAppointmentSelected ? this.firstVisitAppointmentTypeId : this._id;
  }

  constructor() {}

  static readonly fieldMapping = {
    _id: 'id',
    description: 'description',
    displayedDuration: 'displayed_duration',
    displayName: 'display_name',
    firstVisitAppointmentTypeId: 'first_visit_appointment_type_id',
    isSpecialty: 'is_specialty',
    position: 'position',
    firstAppointmentSelected: 'first_appointment_selected',
  };
  description: string;
  displayedDuration: number;
  displayName: string;
  hasAdditionalQuestion: boolean;
  isSpecialty: boolean;
  position: number;
  selected = false;
  firstAppointmentSelected = false;
  firstVisitAppointmentTypeId: number;

  private _id: number;

  static fromApiV2(response): AppointmentType {
    const appointmentType = new AppointmentType();

    each(AppointmentType.fieldMapping, (value, key) => {
      appointmentType[key] = response[value];
    });

    appointmentType.hasAdditionalQuestion = appointmentType.firstVisitAppointmentTypeId != null;

    return appointmentType;
  }

  toApiV2() {
    const response = {};
    each(AppointmentType.fieldMapping, (value, key) => {
      response[value] = this[key];
    });
    return response;
  }
}
