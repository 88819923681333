import each from 'lodash-es/each';

export class ServiceArea {
  id: number;
  name: string;
  code: string;
  default_office_id: number;
  registration_hidden: boolean;

  constructor(attrs = {}) {
    this.setAttributes(attrs);
  }

  static fromApiV2(response): ServiceArea {
    const serviceArea = new ServiceArea();
    serviceArea.id = response.id;
    serviceArea.name = response.name;
    serviceArea.code = response.code;
    return serviceArea;
  }

  toApiV2() {
    return {
      id: this.id,
      name: this.name,
      code: this.code,
      default_office_id: this.default_office_id,
      registration_hidden: this.registration_hidden,
    };
  }

  private setAttributes(attrs): void {
    each(['id', 'name', 'code', 'default_office_id', 'registration_hidden'], attrName => {
      if (attrs[attrName]) {
        this[attrName] = attrs[attrName];
      }
    });
  }
}
