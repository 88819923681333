import { ApiHeaderService } from './api-header.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from './storage.service';
import { WindowService } from './window.service';

@Injectable()
export class AuthService {
  static TOKEN_KEY = 'api_token';
  static PATH_KEY = 'attempted_path';

  constructor(
    private storageService: StorageService,
    private apiHeaderService: ApiHeaderService,
    private windowService: WindowService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    const token = this.storageService.getItem(AuthService.TOKEN_KEY);
    if (token) {
      this.setToken(token);
    }
  }

  getToken() {
    return this.storageService.getItem(AuthService.TOKEN_KEY);
  }

  isTokenBlank() {
    const token = this.getToken();
    return token == null || token.length === 0;
  }

  isLoggedIn() {
    return !this.isTokenBlank();
  }

  setToken(token) {
    this.storageService.setTldItem(AuthService.TOKEN_KEY, token);
    this.apiHeaderService.setAccessToken(token);
  }

  deleteToken() {
    this.storageService.removeItem(AuthService.TOKEN_KEY);
    this.storageService.removeTldItem(AuthService.TOKEN_KEY);
    this.apiHeaderService.revokeAccessToken();
  }

  setAttemptedPath(path: string) {
    this.storageService.setItem(AuthService.PATH_KEY, encodeURIComponent(path));
  }

  navigateToAttemptedPath() {
    let redirect;
    const attemptedPath = this.getAttemptedPath();
    if (attemptedPath) {
      redirect = attemptedPath;
      this.deleteAttemptedPath();
    } else if (this.route.snapshot && this.route.snapshot.queryParams.redirect_to) {
      this.windowService.redirect(this.route.snapshot.queryParams.redirect_to);
    } else {
      redirect = '/';
    }

    this.router.navigateByUrl(redirect, { replaceUrl: true });
  }

  private getAttemptedPath() {
    return (
      this.storageService.getItem(AuthService.PATH_KEY) &&
      decodeURIComponent(this.storageService.getItem(AuthService.PATH_KEY))
    );
  }

  private deleteAttemptedPath() {
    this.storageService.removeItem(AuthService.PATH_KEY);
  }
}
