import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MyOneAppModule } from './app/app.module';
import { environment } from './environments/environment';

const rollbarConfig = {
  accessToken: environment.rollbarAccessToken,
  captureUncaught: true,
  payload: {
    environment: environment.rollbarEnvironment,
  },
};
(<any>window).rollbar = require('./vendors/rollbar.min').init(rollbarConfig);

if (environment.enableProdMode) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(MyOneAppModule, {
  preserveWhitespaces: true,
});
