import { Routes } from '@angular/router';
import { AuthGuardService } from './core/auth-guard.service';
import { DevRouteGuardService } from './core/dev-route-guard.service';
import { Login } from './core/login/login.component';
import { RegistrationCompleteGuardService } from './core/registration-complete-guard.service';
import { TosInterstitialComponent } from './core/tos/tos-interstitial.component';
import { LoggedInWrapperComponent } from './shared/logged-in-wrapper/logged-in-wrapper.component';
import { AccountActiveGuardService } from '@app/core/account-active-guard.service';
import { GuestGuardService } from '@app/core/guest-guard.service';
import { AppStoreRedirectComponent } from '@app/app-store-redirect/app-store-redirect.component';
import { AlreadyLoggedInGuardService } from '@app/core/already-logged-in-guard.service';
var ɵ0 = function () { return import("./home/home.module.ngfactory").then(function (m) { return m.HomeModuleNgFactory; }); }, ɵ1 = function () { return import("./appointment/appointment.module.ngfactory").then(function (m) { return m.AppointmentModuleNgFactory; }); }, ɵ2 = function () { return import("./billing/billing.module.ngfactory").then(function (m) { return m.BillingModuleNgFactory; }); }, ɵ3 = function () { return import("./health-record/health-record.module.ngfactory").then(function (m) { return m.HealthRecordModuleNgFactory; }); }, ɵ4 = function () { return import("./membership/membership.module.ngfactory").then(function (m) { return m.MembershipModuleNgFactory; }); }, ɵ5 = function () { return import("./account/account.module.ngfactory").then(function (m) { return m.AccountModuleNgFactory; }); }, ɵ6 = function () { return import("./message/message.module.ngfactory").then(function (m) { return m.MessageModuleNgFactory; }); }, ɵ7 = function () { return import("./task/task.module.ngfactory").then(function (m) { return m.TaskModuleNgFactory; }); }, ɵ8 = function () { return import("./survey/survey.module.ngfactory").then(function (m) { return m.SurveyModuleNgFactory; }); }, ɵ9 = function () { return import("./registration/registration.module.ngfactory").then(function (m) { return m.RegistrationModuleNgFactory; }); }, ɵ10 = function () { return import("./style-guide/style-guide.module.ngfactory").then(function (m) { return m.StyleGuideModuleNgFactory; }); };
var appRoutes = [
    { path: 'login', component: Login, canActivate: [AlreadyLoggedInGuardService] },
    { path: 'terms-of-service', component: TosInterstitialComponent },
    {
        path: '',
        component: LoggedInWrapperComponent,
        children: [
            {
                path: '',
                loadChildren: ɵ0,
                canActivate: [AuthGuardService, RegistrationCompleteGuardService, AccountActiveGuardService, GuestGuardService],
            },
        ],
    },
    {
        path: 'appointments',
        component: LoggedInWrapperComponent,
        children: [
            {
                path: '',
                loadChildren: ɵ1,
                canActivate: [AuthGuardService, RegistrationCompleteGuardService, GuestGuardService],
            },
        ],
    },
    {
        path: 'billing',
        component: LoggedInWrapperComponent,
        children: [
            {
                path: '',
                loadChildren: ɵ2,
                canActivate: [AuthGuardService, RegistrationCompleteGuardService],
            },
        ],
    },
    {
        path: 'health-record',
        component: LoggedInWrapperComponent,
        children: [
            {
                path: '',
                loadChildren: ɵ3,
                canActivate: [AuthGuardService, RegistrationCompleteGuardService, GuestGuardService],
            },
        ],
    },
    {
        path: 'membership',
        component: LoggedInWrapperComponent,
        children: [
            {
                path: '',
                loadChildren: ɵ4,
                canActivate: [AuthGuardService, RegistrationCompleteGuardService],
            },
        ],
    },
    {
        path: 'account',
        component: LoggedInWrapperComponent,
        children: [
            {
                path: '',
                loadChildren: ɵ5,
                canActivate: [AuthGuardService, RegistrationCompleteGuardService],
            },
        ],
    },
    {
        path: 'messages',
        component: LoggedInWrapperComponent,
        children: [
            {
                path: '',
                loadChildren: ɵ6,
                canActivate: [AuthGuardService, RegistrationCompleteGuardService],
            },
        ],
    },
    { path: 'profile', redirectTo: '/health-record/blood-pressure', pathMatch: 'full' },
    {
        path: 'profile/blood-pressure',
        redirectTo: '/health-record/blood-pressure',
        pathMatch: 'full',
    },
    {
        path: 'tasks',
        component: LoggedInWrapperComponent,
        children: [
            {
                path: '',
                loadChildren: ɵ7,
                canActivate: [AuthGuardService, RegistrationCompleteGuardService, GuestGuardService],
            },
        ],
    },
    {
        path: 'survey',
        component: LoggedInWrapperComponent,
        children: [
            {
                path: '',
                loadChildren: ɵ8,
                canActivate: [AuthGuardService, RegistrationCompleteGuardService, GuestGuardService],
            },
        ],
    },
    {
        path: 'registration',
        loadChildren: ɵ9,
    },
    { path: 'app-store-redirect', component: AppStoreRedirectComponent },
    {
        path: 'style-guide',
        loadChildren: ɵ10,
        canActivate: [DevRouteGuardService],
    },
    { path: '**', redirectTo: '/', pathMatch: 'full' },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
