import { catchError, filter, map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { BehaviorSubject, Subject, throwError as observableThrowError } from 'rxjs';
import { Coupon } from 'app/membership/coupon';
import { Injectable } from '@angular/core';
import { Membership } from './membership';
import { PusherService } from '../shared/pusher.service';
import debounce from 'lodash-es/debounce';

export interface ApiV2Response {
  can_book_visit: boolean;
  can_cancel: boolean;
  can_reactivate: boolean;
  can_update_b2b_code: boolean;
  can_update_billing: boolean;
  is_active: boolean;
  credit_card?: {
    brand: string;
    last4: string;
  };
  expiration_date: {
    date: string;
    action: string;
  };
  patient_status: string;
  plan_id: number;
  plan_type: string;
  renewal_plan?: {
    amount: number;
  };
  status: string;
  valid_until: string;
  om_membership_type: string;
}

interface UpdateMembershipResponse {
  error: string;
  membership_id: number;
  success: boolean;
}

interface UpdateCreditCardResponse {
  success: any;
  message: string;
}

interface AsyncChannelResponse {
  channel_name: string;
  event_name: string;
}

@Injectable()
export class MembershipService {
  private _membership$ = new BehaviorSubject(null);
  readonly membership$ = this._membership$.asObservable().pipe(filter(membership => membership != null));

  private _debouncedGetMembership;

  constructor(private apiService: ApiService, private pusherService: PusherService) {
    this._debouncedGetMembership = debounce(this._getMembership.bind(this), 1000, {
      leading: true,
    });
  }

  getMembership(force = false) {
    if (force) {
      this._getMembership();
    } else if (this._membership$.getValue() == null) {
      this._debouncedGetMembership();
    }
  }

  updateMembership(stripeTokenId: string, coupon: Coupon): Subject<UpdateMembershipResponse> {
    const stripeResponse = new Subject<UpdateMembershipResponse>();

    const params = {
      stripe_token: stripeTokenId,
      callback_type: 'pusher',
    };

    if (coupon && coupon.id) {
      params['stripe_coupon_id'] = coupon.id;
    }

    this.apiService
      .patch('/api/v2/patient/membership', params)
      .subscribe(({ channel_name, event_name }: AsyncChannelResponse) => {
        const channel = this.pusherService.subscribeTo(channel_name);
        channel.bind(event_name, (response: UpdateMembershipResponse) => {
          if (!response.error) {
            stripeResponse.next(response);
          } else {
            stripeResponse.error(response.error);
          }
          channel.unbind(event_name);
          stripeResponse.complete();
        });
      });

    return stripeResponse;
  }

  deactivateMembership(deactivateReasonId: number, deactivateReasonNotes?: string) {
    return this.apiService
      .post('/api/v2/patient/membership/deactivate_recurring_billing', {
        deactivate_recurring_billing_reason: deactivateReasonId,
        deactivate_recurring_billing_notes: deactivateReasonNotes,
      })
      .pipe(catchError(error => observableThrowError(error)));
  }

  reactivateMembership() {
    return this.apiService.post('/api/v2/patient/membership/reactivate_recurring_billing', {});
  }

  updateCreditCard(stripeTokenId: string) {
    const stripeResponse = new Subject<UpdateCreditCardResponse>();

    const params = {
      stripe_token: stripeTokenId,
    };
    this.apiService
      .patch('/api/v2/patient/memberships/credit_card', params)
      .subscribe(({ channel_name, event_name }: AsyncChannelResponse) => {
        const channel = this.pusherService.subscribeTo(channel_name);
        channel.bind(event_name, (response: UpdateCreditCardResponse) => {
          if (response.success) {
            this.getMembership(true);
            stripeResponse.next(response);
          } else {
            stripeResponse.error(response.message);
          }
          channel.unbind(event_name);
          stripeResponse.complete();
        });
      });

    return stripeResponse;
  }

  private _getMembership(ignoreUnauthorized: boolean = false) {
    const request = this.apiService
      .get('/api/v2/patient/membership', ignoreUnauthorized)
      .pipe(map((membership: ApiV2Response) => Membership.fromApiV2(membership)));
    request.subscribe(membership => {
      this._membership$.next(membership);
    });
    return request;
  }

  getMembershipWithRequest() {
    return this._getMembership(true);
  }
}
