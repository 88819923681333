import { throwError as observableThrowError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';

/**
 * @deprecated Use the Apollo Client
 */
@Injectable()
export class GraphqlService {
  constructor(private apiService: ApiService, private authService: AuthService) {}

  // This method will take a query string that looks like:
  //
  //   service_areas {
  //     id
  //     name
  //   }
  //
  // and submit it after formatting it properly to this:
  //
  //   {
  //     "query": "query {
  //       viewer {
  //         ... on AnonymousUser {
  //           service_areas {
  //             id
  //             name
  //           }
  //         }
  //       }
  //     }"
  //   }
  //
  // If the query requires the user to be signed in, it will pass the correct auth token.
  //
  // Optional params: variables to add and isMutation (pass in true for mutations)

  /**
   * @deprecated Use the Apollo Client
   */
  postQuery(query: string, variables?: object, isMutation?: boolean) {
    let json;
    const token = this.authService.getToken();

    const queryJson = isMutation ? `mutation ${query}` : `query { ${query} }`;

    json = {
      query: queryJson,
      variables: {},
    };

    if (token) {
      json.variables.token = token;
    }

    if (variables) {
      json.variables = Object.assign(json.variables, variables);
    }

    return this.apiService.post(`/api/graphql.json`, json).pipe(
      map((result: any) => {
        if ('errors' in result) {
          throw result.errors[0].message;
        } else {
          return result.data;
        }
      }),
    );
  }
}
