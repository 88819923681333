import each from 'lodash-es/each';

export class FromAppointment {
  static readonly fieldMapping = {
    id: 'id',
    startTime: 'start_time',
    officeName: 'office_name',
    providerName: 'provider_name',
  };
  id: number;
  startTime: string;
  officeName: string;
  providerName: string;

  static fromApiV2(response): FromAppointment {
    const fromAppointment = new FromAppointment();
    each(FromAppointment.fieldMapping, (value, key) => {
      fromAppointment[key] = response[value];
    });

    return fromAppointment;
  }

  toApiV2() {
    const response = {};
    each(FromAppointment.fieldMapping, (value, key) => {
      response[value] = this[key];
    });

    return response;
  }

  get toReschedulingBannerHtml(): string {
    return `Rescheduling Appointment \
      <strong>${this.startTime}</strong> at \
      <strong>${this.officeName}</strong> with \
      <strong>${this.providerName}.</strong> `;
  }
}
