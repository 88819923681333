import camelCase from 'lodash-es/camelCase';
import each from 'lodash-es/each';
import find from 'lodash-es/find';
import flatten from 'lodash-es/flatten';
import { Validators } from '@angular/forms';
var Address = /** @class */ (function () {
    function Address(attrs) {
        if (attrs === void 0) { attrs = {}; }
        this.setAttributes(attrs);
    }
    Object.defineProperty(Address.prototype, "fullAddress", {
        get: function () {
            return [this.address1, this.address2].filter(function (n) { return !!n; }).join(', ');
        },
        enumerable: true,
        configurable: true
    });
    Address.forApiV2 = function (address) {
        var addressAsJson = {};
        each(['id', 'address1', 'address2', 'city', 'zip'], function (attrName) {
            if (address[attrName]) {
                addressAsJson[attrName] = address[attrName];
            }
        });
        addressAsJson['state_id'] = address.stateId;
        addressAsJson['state_code'] = address.state;
        addressAsJson['is_preferred'] = address.preferred;
        return addressAsJson;
    };
    Object.defineProperty(Address, "emptyAddress", {
        get: function () {
            return {
                address1: '',
                address2: '',
                city: '',
                stateId: null,
                state: '',
                zip: '',
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Address, "basicAddressControlsConfig", {
        get: function () {
            return {
                address1: ['', [Validators.required]],
                address2: '',
                city: ['', Validators.required],
                state: ['', Validators.required],
                stateId: [''],
                zip: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
            };
        },
        enumerable: true,
        configurable: true
    });
    Address.parseAddress = function (selectedLocation) {
        return Object.assign(Address.emptyAddress, parseAddress1(selectedLocation), parseCity(selectedLocation), parseState(selectedLocation), parseZip(selectedLocation));
    };
    Address.prototype.setAttributes = function (attrs) {
        var _this = this;
        each(['id', 'address1', 'address2', 'city', 'state', 'state_id', 'zip', 'latitude', 'longitude'], function (attrName) {
            if (attrs[attrName]) {
                _this[camelCase(attrName)] = attrs[attrName];
            }
        });
    };
    return Address;
}());
export { Address };
function parseAddress1(selectedLocation) {
    var streetNumber = findComponentByType(selectedLocation, 'street_number');
    var street = findComponentByType(selectedLocation, 'route');
    if (streetNumber || street) {
        return { address1: selectedLocation.name };
    }
}
function parseCity(selectedLocation) {
    var cityComponent = findComponentByType(selectedLocation, 'locality');
    if (!cityComponent) {
        cityComponent = find(selectedLocation.address_components, function (comp) {
            return comp.types[0].match(/locality/);
        });
    }
    if (cityComponent) {
        return { city: cityComponent.long_name };
    }
}
function parseState(selectedLocation) {
    var stateComponent = findComponentByType(selectedLocation, 'administrative_area_level_1');
    if (stateComponent) {
        return { state: stateComponent.short_name };
    }
}
function parseZip(selectedLocation) {
    var zipComponent = findComponentByType(selectedLocation, 'postal_code');
    if (zipComponent) {
        return { zip: zipComponent.long_name };
    }
}
function findComponentByType(selectedLocation, type) {
    return find(selectedLocation.address_components, function (comp) {
        return flatten(comp.types).indexOf(type) > -1;
    });
}
