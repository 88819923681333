import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { catchError, map } from 'rxjs/operators';
import { combineLatest, Observable, of as observableOf } from 'rxjs';
import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { MembershipService } from './membership.service';
import { TosService } from './tos.service';
import { WindowService } from './window.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private config: ConfigService,
    private membershipService: MembershipService,
    private router: Router,
    private tosService: TosService,
    private windowService: WindowService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    if (this.authService.isTokenBlank()) {
      return this.handleUnauthenticated(state.url);
    }

    const authCheckStream = combineLatest(this.membershipService.getMembershipWithRequest(), this.tosService.tos$);

    this.tosService.getTos();

    return authCheckStream.pipe(
      map(([membership, termsOfService]) => {
        const unsignedDocs = termsOfService.filter(doc => {
          return !doc.signed;
        });

        if (membership.isPending()) {
          this.windowService.redirect(`${this.config.json.myoneServer}/pt/patient/login`);
          return false;
        } else if (unsignedDocs.length) {
          this.authService.setAttemptedPath(state.url);
          this.router.navigate(['terms-of-service'], { replaceUrl: true });
          return false;
        } else {
          return true;
        }
      }),
      catchError(error => {
        return this.handleUnauthenticated(state.url);
      }),
    );
  }

  private handleUnauthenticated(url: string) {
    this.authService.setAttemptedPath(url);
    this.router.navigate(['/login'], { replaceUrl: true });
    return observableOf(false);
  }
}
