import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { Membership } from './membership';

export interface Response {
  membership: Membership;
}

@Injectable()
export class MembershipGraphQL extends Query<Response> {
  document = gql`
    query MembershipResult {
      membership {
        planId
        planType
        plan {
          ... on B2bPlan {
            name
            company {
              name
              includesDependent
            }
          }
          ... on StripePlan {
            amount
            amountInDollars
          }
        }
      }
    }
  `;
}
