import { map, filter } from 'rxjs/operators';
import { ApiService } from '../core/api.service';
import { AppointmentBookingState } from './appointment-booking-state';
import { AppointmentCancellationReason } from './appointment-cancellation-reason';
import { AppointmentType } from './appointment-type';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigService } from '../core/config.service';
import { Injectable } from '@angular/core';
import { Provider } from '../shared/provider';
import { AppointmentInventory } from './provider-inventories';
import { ProviderType, ProviderTypeUtil } from './provider-type';
import { ServiceArea } from '../shared/service-area';
import { UserService } from '../core/user.service';
import { VisitReasonCategory } from './visit-reason-category';
import { WindowService } from '../core/window.service';
import { Appointment } from './appointment';

@Injectable()
export class AppointmentService {
  private _visitReasonCategories = new BehaviorSubject([]);
  readonly visitReasonCategories$ = this._visitReasonCategories.asObservable();

  private _specialtyAppointmentsAvailability = new BehaviorSubject<boolean>(false);
  specialtyAppointmentsAvailability$ = this._specialtyAppointmentsAvailability.asObservable();

  constructor(
    private apiService: ApiService,
    private config: ConfigService,
    private windowService: WindowService,
    private userService: UserService,
  ) {}

  getVisitReasonCategories(): Observable<VisitReasonCategory[]> {
    this.apiService
      .get('/api/v2/patient/visit_reason_categories')
      .pipe(
        map((results: any) => {
          return results.map(visitReasonCategories => VisitReasonCategory.fromApiV2(visitReasonCategories));
        }),
      )
      .subscribe(visitReasonCategories => {
        this._visitReasonCategories.next(visitReasonCategories);
      });
    return this.visitReasonCategories$;
  }

  getCachedBookingState(): Observable<AppointmentBookingState> {
    return this.apiService
      .getCheckIfChanged('/api/v2/patient/appointments/appointment_search_params')
      .pipe(map(results => (results ? AppointmentBookingState.fromApiV2(results) : new AppointmentBookingState())));
  }

  getCancellationReasons(): Observable<AppointmentCancellationReason[]> {
    return this.apiService.get('/api/v2/patient/appointment_cancellation_reasons').pipe(
      map((results: any) => {
        return results.map(appointmentCancellationReasons =>
          AppointmentCancellationReason.fromApiV2(appointmentCancellationReasons),
        );
      }),
    );
  }

  getAppointmentTypes(
    providerType?: ProviderType,
    provider?: Provider,
    serviceArea?: ServiceArea,
  ): Observable<AppointmentType[]> {
    const apiParams = {};
    if (providerType != null) {
      if (provider) {
        apiParams['provider_id'] = provider.id;
      }
      if (providerType !== null && providerType !== ProviderType.specificProvider) {
        apiParams['appointment_category'] = ProviderTypeUtil.getTypeCategory(providerType);
      }
    }
    if (serviceArea != null) {
      apiParams['service_area_id'] = serviceArea.id;
    }
    return this.apiService
      .get('/api/v2/patient/appointment_types', false, apiParams)
      .pipe(
        map((appointmentTypesJson: any) =>
          appointmentTypesJson.map(appointmentType => AppointmentType.fromApiV2(appointmentType)),
        ),
      );
  }

  getSpecialtyAppointmentAvailability(serviceArea: ServiceArea) {
    const apiParams = {
      appointment_category: 'specialty',
      service_area_id: serviceArea.id,
    };
    this.apiService
      .get('/api/v2/patient/appointment_types', false, apiParams)
      .pipe(map((appointmentTypesJson: any) => appointmentTypesJson.length !== 0))
      .subscribe(specialtyAvailability => this._specialtyAppointmentsAvailability.next(specialtyAvailability));
  }

  navigateToMyOneSearch(appointmentBookingState: AppointmentBookingState) {
    this.saveBookingState(appointmentBookingState).subscribe(() => {
      this.windowService.redirect(`${this.config.json.myoneServer}/pt/appointments/filtered_search`);
    });
  }

  saveBookingState(appointmentBookingState: AppointmentBookingState) {
    return this.apiService.post('/api/v2/patient/appointments/search', appointmentBookingState.toApiV2());
  }

  resetBookingState() {
    return this.apiService.post('/api/v2/patient/appointments/reset_cache', {});
  }

  bookAppointment(
    appointmentBookingState: AppointmentBookingState,
    appointmentInventory: AppointmentInventory,
  ): Observable<any> {
    const bookingParams = appointmentBookingState.bookingParams;
    bookingParams.appointment['inventory_id'] = appointmentInventory.id;
    const pathPrefix = '/api/v2/patient/appointments';
    const fullPath = !!appointmentBookingState.getFromAppointmentId() ? `${pathPrefix}/reschedule` : pathPrefix;

    return this.apiService.post(fullPath, bookingParams, false);
  }

  getAppointmentType(id): Observable<AppointmentType> {
    return this.apiService.get(`/api/v2/patient/appointment_types/${id}`).pipe(
      map(resp => {
        return AppointmentType.fromApiV2(resp);
      }),
    );
  }

  getAppointment(id): Observable<Appointment> {
    return this.apiService.get(`/api/v2/patient/appointments/${id}`).pipe(
      map(appointmentResponse => {
        return Appointment.fromApiV2(appointmentResponse);
      }),
    );
  }
}
