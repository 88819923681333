import { Injectable } from '@angular/core';
import { DocumentItem } from '@app/shared/document-item';
import * as S3 from 'aws-sdk/clients/s3';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { combineLatest, filter, take } from 'rxjs/operators';
import { User } from '../shared/user';
import { AnalyticsService } from './analytics.service';
import { ApiService } from './api.service';
import { UploadData } from './upload-data';
import { UserService } from './user.service';

export class ApiStatus {
  static NOT_READY = 'NOT_READY';
  static READY = 'READY';
}

@Injectable()
export class AwsService {
  private static LINK_EXPIRATION_TIME = 60;
  private s3: S3;
  private sessionExpiresAt: Date;
  private bucket: string;
  private currentUser: User;
  private uploadPathRoot: string;
  apiStatusStream: BehaviorSubject<string>;
  viewStream: ReplaySubject<DocumentItem>;
  downloadStream: ReplaySubject<DocumentItem>;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
  ) {
    this.apiStatusStream = new BehaviorSubject(ApiStatus.NOT_READY);
    this.viewStream = new ReplaySubject<DocumentItem>();
    this.downloadStream = new ReplaySubject<DocumentItem>();
    this.userService.user$.pipe(take(1)).subscribe(user => {
      this.currentUser = user;
      this.getToken();
    });
    this.userService.getUser();

    this.initViewAction();
    this.initDownloadAction();
  }

  view(document: DocumentItem) {
    this.checkAndUpdateToken();
    this.viewStream.next(document);
  }

  download(document: DocumentItem) {
    this.checkAndUpdateToken();
    this.downloadStream.next(document);
  }

  private checkAndUpdateToken() {
    if (this.sessionExpiresAt && Date.now() >= this.sessionExpiresAt.getTime()) {
      this.apiStatusStream.next(ApiStatus.NOT_READY);
      this.getToken();
    }
  }

  private getToken() {
    this.apiService.post('/api/v2/aws_session', null).subscribe((response: any) => {
      this.sessionExpiresAt = new Date(response.expires_at);
      this.bucket = response.bucket;
      this.uploadPathRoot = `patients/${this.currentUser.id}`;
      this.s3 = new S3({
        region: 'us-east-1',
        credentials: {
          accessKeyId: response.credentials.access_key_id,
          secretAccessKey: response.credentials.secret_access_key,
          sessionToken: response.credentials.session_token,
        },
      });
      this.apiStatusStream.next(ApiStatus.READY);
    });
  }

  private initViewAction() {
    this.viewStream
      .pipe(
        combineLatest(this.apiStatusStream),
        filter(vals => vals[1] === ApiStatus.READY),
      )
      .subscribe(vals => {
        const document: DocumentItem = vals[0];
        this.getSignedUrl(document, 'inline', (err, url) => {
          if (url) {
            window.open(url);
          }
        });
        this.analyticsService.attachmentViewed(document);
      });
  }

  private initDownloadAction() {
    this.downloadStream
      .pipe(
        combineLatest(this.apiStatusStream),
        filter(vals => vals[1] === ApiStatus.READY),
      )
      .subscribe(vals => {
        const document: DocumentItem = vals[0];
        this.getSignedUrl(document, 'attachment', (err, url) => {
          if (url) {
            window.location.href = url;
          }
        });
        this.analyticsService.attachmentDownloaded(document);
      });
  }

  private getSignedUrl(document: DocumentItem, contentDisposition: string, handler) {
    this.s3.getSignedUrl(
      'getObject',
      {
        Bucket: document.bucket,
        Key: document.key,
        Expires: AwsService.LINK_EXPIRATION_TIME,
        ResponseContentDisposition: contentDisposition,
      },
      handler,
    );
  }

  upload(key: string, file: File): Subject<any> {
    this.checkAndUpdateToken();
    const uploadStream = new Subject();
    const params = {
      Key: `${this.uploadPathRoot}/${key}`,
      Body: file,
      Bucket: this.bucket,
      ContentType: file.type,
      ServerSideEncryption: 'AES256',
    };
    const options = {};
    this.apiStatusStream.pipe(filter(status => status === ApiStatus.READY)).subscribe(() => {
      this.s3
        .upload(params, options, (error, data) => {
          if (error) {
            uploadStream.error(error);
          } else {
            uploadStream.next(new UploadData(true, 100, data.Key, data.Bucket));
          }
        })
        .on('httpUploadProgress', event => {
          uploadStream.next(new UploadData(false, Math.round((100 * event.loaded) / event.total)));
        });
    });

    return uploadStream;
  }
}
