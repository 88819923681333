import each from 'lodash-es/each';
import { Pharmacy, PharmacyResponse } from '@app/shared/pharmacy';

export class PatientPharmacyResponse {
  is_preferred: boolean;
  pharmacy: PharmacyResponse;
}

export class PatientPharmacy {
  static readonly fieldMapping = {
    isPreferred: 'is_preferred',
  };
  isPreferred: boolean;
  pharmacy: Pharmacy;

  static fromApiV2(response: PatientPharmacyResponse): PatientPharmacy {
    const patientPharmacy = new PatientPharmacy();

    each(PatientPharmacy.fieldMapping, (value, key) => {
      patientPharmacy[key] = response[value];
    });

    patientPharmacy.pharmacy = Pharmacy.fromApiV2(response.pharmacy);

    return patientPharmacy;
  }
}
