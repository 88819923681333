import includes from 'lodash-es/includes';

export enum Features {
  APPOINTMENT_REASON_AUTOCOMPLETE = 'appointment_reason_autocomplete',
  APPOINTMENT_REASON_TEXT = 'appointment_reason_text',
  APPOINTMENT_SEARCH_FILTERS = 'appointment_search_filters',
  NEW_PATIENT_HOME_PAGE = 'new_patient_home_page',
  PATIENT_RESCHEDULE = 'patient_reschedule',
  PEDIATRIC_REGISTRATION = 'new_pediatric_registration',
  SEARCH_RESULTS = 'appointment_search_results',
  SHARE_PCP = 'share_pcp',
}
export class ActiveFeatureFlagCollection {
  activeFlags: string[];

  constructor(flags: string[]) {
    this.activeFlags = flags;
  }

  static fromApiV2(res): ActiveFeatureFlagCollection {
    return new ActiveFeatureFlagCollection(res);
  }

  isFeatureActive(feature_name: string): boolean {
    return includes(this.activeFlags, feature_name);
  }
}
